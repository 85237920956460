import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '../AppButton/Button';
import { OnlineCourseFormType } from './form-types';
import CheckboxItem from '../AppCheckboxItem/CheckboxItem';
import LabelValue from '../AppLabelValue/LabelValue';
import { useHistory } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import {LessonType, OnlineCourseType} from '../../services/types';
import { formateNumber, getCookie } from '../../services/utils';

import ErrorBox from '../AppErrorBox/ErrorBox';
import { CoursesTypes, useCoursesState } from '../../contexts/courses';
import ButtonSelect from '../AppButtonSelect/ButtonSelect';
import Checkmark from '../AppCheckmark/Checkmark';
import Tag from '../AppTag/Tag';

const OnlineCourseForm: React.FC<{ course: OnlineCourseType }> = ({ course }) => {
  const { state, dispatch } = useCoursesState();
    const history = useHistory();
    const searchParams = new URLSearchParams(history.location.search);
    const { savedOnlineCourses } = state;
    const alreadySavedCourse = savedOnlineCourses.filter((savedCourse) => savedCourse.id === course.id)[0];
    let pricePackageId: null | number = null;
    
    const availableCourses = course.lessons.filter((lesson) => !lesson.owned_by_user);

    const saveSelectedCourse = (values: OnlineCourseFormType) => {
        dispatch({
            type: CoursesTypes.SaveCourse,
            course: {
                ...values,
                price_package_id: pricePackageId,
                course: {
                    classLesson: course.lesson_class.name,
                    expiration: course.expired_at,
                    name: course.name,
                    subject: course.subject.name,
                    price: onlineCoursePrice(values.lesson_ids),
                },
            },
            courseType: 'online',
        });
    };

    const onlineCoursePrice = (lessons: number[]) => {
        const pricePackage = course.price_packages.filter((pack) => pack.quantity === lessons.length)[0];
        pricePackageId = null;
        if (pricePackage) {
            pricePackageId = pricePackage.id;
            return pricePackage.price;
        } else {
            const freeCourses: number[] = [];
            availableCourses.forEach((e) => {
                if (e.is_free) {
                    freeCourses.push(e.id);
                }
            });
            let paidLessons = lessons.filter((e) => !freeCourses.includes(e));

            if (availableCourses.length === lessons.length && course.price < paidLessons.length * course.lesson_price) {
                return course.price;
            } else {
                return paidLessons.length * course.lesson_price;
            }
        }
    };

    let preselectedLesson: number[] = [];
    if (searchParams.get('preselected-course')) {
        preselectedLesson = [+searchParams.get('preselected-course')!];
    }
    let initialValues = {
        id: course.id,
        price_package_id: null,
        lesson_ids: alreadySavedCourse ? alreadySavedCourse.lesson_ids : [...preselectedLesson],
    };

    const onSubmit = async (values: OnlineCourseFormType) => {
        saveSelectedCourse(values);
        if (getCookie('jid')) {
            history.push(routesURLs.selectMore);
        } else {
            history.push(routesURLs.registration);
        }
    };

    const validationSchema = Yup.object().shape({
        lesson_ids: Yup.array().required('Vyberte alespoň jednu lekci'),
    });

    const buttonRightContent = (lesson: LessonType, isChecked: boolean) => {
        if (lesson.owned_by_user) {
            return (<Tag color="gray">Již zakoupeno</Tag>);
        } else if (lesson.is_free) {
            return (<div className="free-course-message">Po registraci zdarma&nbsp;&nbsp;<Checkmark isChecked={isChecked} /></div>)
        } else {
            return (<Checkmark isChecked={isChecked} />);
        }
    };

    if (!Object.keys(course).length) {
        return null;
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ isValid, isSubmitting, values, setFieldValue, errors, submitCount }) => {
                const selectOtherCourse = (values: OnlineCourseFormType) => {
                    saveSelectedCourse(values);
                    history.push(routesURLs.selectMore);
                };

                const toggleAllLessons = () => {
                    if (values.lesson_ids.length === availableCourses.length) {
                        setFieldValue('lesson_ids', []);
                    } else {
                        setFieldValue(
                            'lesson_ids',
                            availableCourses.map((lesson) => lesson.id)
                        );
                    }
                };

                const toggleSingleLesson = (id: number) => {
                    if (values.lesson_ids.filter((lessonId) => lessonId === id).length) {
                        setFieldValue(
                            'lesson_ids',
                            values.lesson_ids.filter((lessonId) => lessonId !== id)
                        );
                    } else {
                        setFieldValue('lesson_ids', [...values.lesson_ids, id]);
                    }
                };

                return (
                    <Form>
                        <div className="h-mb-15">
                            <label>Vyberte</label>
                            <button type="button" onClick={toggleAllLessons} className="h-pure-button h-mb-05">
                                <CheckboxItem
                                    text="Celý kurz"
                                    note="(všechny lekce)"
                                    isChecked={values.lesson_ids.length === availableCourses.length}
                                />
                            </button>
                        </div>
                        <div>
                            <label>Vlastní kombinace lekcí</label>
                            {course.lessons.map((lesson) => {
                                const isChecked = !!values.lesson_ids.filter((id) => id === lesson.id).length;
                                return (
                                    <ButtonSelect
                                        key={lesson.id}
                                        onClick={() => toggleSingleLesson(lesson.id)}
                                        isChecked={isChecked}
                                        isDisabled={lesson.owned_by_user}
                                        description={lesson.description}
                                        rightPart={buttonRightContent(lesson, isChecked)}
                                    >
                                        {lesson.name}
                                    </ButtonSelect>
                                );
                            })}
                            {errors.lesson_ids && !!submitCount && (
                                <div className="form-input-footnote image-box-error h-mt-1">
                                    <div className="form-error-messages">{errors.lesson_ids}</div>
                                </div>
                            )}
                        </div>
                        <hr className="h-mt-2" />
                        <div className="personal-courses-form-summary">
                            <LabelValue
                                label="Celkem zaplatíte"
                                value={true ? `${formateNumber(onlineCoursePrice(values.lesson_ids), 0)} Kč` : '0 Kč'}
                                className="personal-courses-form-summary-price"
                            />
                            <Button
                                onClick={() => selectOtherCourse(values)}
                                color="transparent"
                                disabled={!isValid}
                                className="h-mr-05"
                            >
                                Vybrat další kurz
                            </Button>
                            <Button type="submit" color="primary" disabled={!isValid} loading={isSubmitting}>
                                Pokračovat
                            </Button>
                        </div>
                        <ErrorBox errors={errors} submitCount={submitCount} />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OnlineCourseForm;
