import React, { useEffect, useState } from 'react';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { checkDiscountCode } from '../../moduls/courses';
import Button from '../AppButton/Button';

const DiscountCode = () => {
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const { dispatch } = useGlobalState();

    useEffect(() => {
        dispatch({ type: GlobalTypes.SetDiscount, discount: null });
        // eslint-disable-next-line
    }, []);

    const checkCode = () => {
        setLoading(true);
        checkDiscountCode(code)
            .then((res) => {
                dispatch({ type: GlobalTypes.SetDiscount, discount: { code, ...res } });
            })
            .catch(() => {
                dispatch({ type: GlobalTypes.SetDiscount, discount: null });
                dispatch({
                    type: GlobalTypes.ToggleNotification,
                    style: 'error',
                    text: `Slevový kód "${code}" není platný`,
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <div onSubmit={checkCode} className="personal-courses-form-summary">
            <input
                id="text-input-discount-code"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Zadejte slevový kód"
                className="h-mr-1 h-mb-sm-1"
            />
            <Button onClick={checkCode} color="outline" disabled={!code} loading={loading}>
                Použít slevový kód
            </Button>
        </div>
    );
};

export default DiscountCode;
