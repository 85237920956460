import React from 'react'
import BackButton from '../../components/AppBackButton/BackButton'
import ProfileEditForm from '../../components/Forms/ProfileEditForm'
import MainLayout from '../../components/TheMainLayout/MainLayout'
import { routesURLs } from '../../Routes'
import './Profile.scss'

const ProfileEdit = () => {
    return (
        <>
            <MainLayout>
                <h1 className="h-text-center">Nastavení</h1>
                <ProfileEditForm />
            </MainLayout>
            <BackButton route={routesURLs.profile} />
        </>
    )
}

export default ProfileEdit
