import React from 'react';
import { formateNumber } from '../../services/utils';
import './ResultBar.scss';

const resultColor = (value: number) => {
    if (value < 25) {
        return '#DD4A4A';
    } else if (value < 50) {
        return '#F79301';
    } else if (value < 75) {
        return '#FACB00';
    } else {
        return '#A8CD01';
    }
};

const ResultBar: React.FC<{ value: number }> = ({ value }) => {
    return (
        <div className="result-bar">
            <span className="resulte-bar-progress">
                <span
                    style={{
                        backgroundColor: resultColor(value),
                        width: value + '%',
                    }}
                ></span>
            </span>
            <div className="result-bar-value">{formateNumber(value, 1)} %</div>
        </div>
    );
};

export default ResultBar;
