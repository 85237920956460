import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Button from '../AppButton/Button';
import FormInputText from './Inputs/FormInputText';
import { transformErrors } from '../../services/utils';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { forgottenPassword } from '../../moduls/auth';
import ErrorBox from '../AppErrorBox/ErrorBox';

const initialValues = {
    username: '',
};

const attributes = {
    username: {
        placeholder: 'Vyplňte..',
        label: 'Uživatelské jméno',
    },
};

const ForgottenPasswordForm: React.FC<{}> = () => {
    const { dispatch } = useGlobalState();
    const onSubmit = async (values: { username: string }, { setErrors, resetForm }: FormikHelpers<{ username: string }>) => {
        return forgottenPassword(values)
            .then((_) => {
                resetForm();
                dispatch({ type: GlobalTypes.ToggleModal, modal: '' })
                dispatch({ type: GlobalTypes.ToggleNotification, style: 'success', text: 'Na e-mail jsme Vám poslali odkaz pro obnovení hesla.'})
            })
            .catch((error) => setErrors(transformErrors(error)));
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Povinné pole')
    });

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ isValid, dirty, isSubmitting, errors, submitCount }) => (
                <Form>
                    <FormInputText name="username" attributes={attributes.username} />
                    <div className="h-text-center h-mt-1">
                        <Button type="submit" color="primary" disabled={!(isValid && dirty)} loading={isSubmitting}>
                            Odeslat
                        </Button>
                    </div>
                    <ErrorBox errors={errors} submitCount={submitCount} />
                </Form>
            )}
        </Formik>
    );
};

export default ForgottenPasswordForm;
