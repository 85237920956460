import React from 'react';
import './Checkmark.scss';
import { classes } from '../../services/utils';

const Checkmark: React.FC<{ isChecked: boolean }> = ({ isChecked }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" min-width="20" height="15.219" className="checkmark-wrapper" viewBox="0 0 20 15.219">
            <defs></defs>
            <g className="a" transform="translate(-224.025 -268.303)">
                <path
                    className={classes('checkmark', { 'is-checked': isChecked })}
                    d="M243.452,268.738a1.717,1.717,0,0,0-2.426.143l-9.39,10.447-4.71-4.538a1.716,1.716,0,0,0-2.369,2.483l5.994,5.766a1.683,1.683,0,0,0,1.2.485h.057a1.712,1.712,0,0,0,1.2-.571l10.561-11.759A1.722,1.722,0,0,0,243.452,268.738Z"
                />
            </g>
        </svg>
    );
};

export default Checkmark;
