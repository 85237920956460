import React from 'react';
import './ImageBox.scss';
import { classes } from '../../services/utils';
import Checkmark from '../AppCheckmark/Checkmark';

import czechLanguageImage from '../../assets/images/icons/ic-czech-language-primary.svg';
import mathImage from '../../assets/images/icons/ic-math-primary.svg';
import englishImage from '../../assets/images/icons/ic-english-primary.svg';

interface ImageBoxType {
    image: string;
    title: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    color?: string;
    subtitle?: string;
    small?: boolean;
    className?: string;
    isChecked?: boolean;
    disabled?: boolean;
    tooltip?: string
}

const baseImages: { [key: string]: string } = {
    matematika: mathImage,
    čeština: czechLanguageImage,
    angličtina: englishImage,
};

const ImageBox: React.FC<ImageBoxType> = (props) => {
    const { color = 'primary', image, onClick, title, subtitle, className, small = false, isChecked = false, disabled = false, tooltip = ''} = props;
    return (

        <button
            type="button"
            title={tooltip}
            onClick={onClick}
            className={classes(`image-box is-${color}`, {
                'is-small': small,
                'is-selected': isChecked,
                'is-disabled': disabled,
                [className || '']: !!className,
            })}
            disabled={disabled}
        >
            {disabled && <div className={"classHasAlreadyBought"}>JIŽ ZAKOUPENO</div>}
            <div className="image-box-image" style={{ backgroundImage: `url('${baseImages[title] || image}')` }}></div>
            {small ? (
                <>
                    <Checkmark isChecked={isChecked} />
                    <h3 className="image-box-title">{title}</h3>
                </>
            ) : (
                <h2 className="image-box-title">{title}</h2>
            )}
            {subtitle && <div className="image-box-subtitle">{subtitle}</div>}
        </button>
    );
};

export default ImageBox;
