import React, { useState } from 'react';
import './UserDropdown.scss';
import { classes } from '../../services/utils';
import ClickOutside from '../ClickOutside/ClickOutside';
import { Link } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { useQuery } from 'react-query';
import { ProfileType } from '../../services/types';
import { logout } from '../../moduls/auth';
import { getProfile } from '../../moduls/profile';

const UserDropdown: React.FC<{ className?: string }> = ({ className }) => {
    const { dispatch } = useGlobalState();
    const [open, setOpen] = useState(false);
    const { data: profile } = useQuery<ProfileType>('profile', getProfile, {
        staleTime: Infinity,
    });
    
    function changePasswordModal() {
        setOpen(false);
        dispatch({ type: GlobalTypes.ToggleModal, modal: 'ModalChangePassword' });
    }

    return (
        <ClickOutside onClick={() => setOpen(false)}>
            <div className={classes('user-dropdown', { [className || '']: !!className, 'is-open': open })}>
                <button className="user-dropdown-button" onClick={() => setOpen(!open)}>
                    {profile?.name}
                </button>
                <div className="user-dropdown-menu">
                    <ul className="is-pure-list">
                        <li>
                            <Link to={routesURLs.homepage}>Moje kurzy</Link>
                        </li>
                        <li>
                            <Link to={routesURLs.overview}>Přehled</Link>
                        </li>
                        <li>
                            <Link to={routesURLs.profile}>Zobrazit profil</Link>
                        </li>
                        <li>
                            <Link to={routesURLs.profileEdit}>Nastavení</Link>
                        </li>
                        <li>
                            <button onClick={changePasswordModal}>Změna hesla</button>
                        </li>
                        <hr className="h-mx-175 h-my-05" />
                        <li>
                            <button onClick={logout} className="user-dropdown-light-button">
                                Odhlásit se
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </ClickOutside>
    );
};

export default UserDropdown;
