import React, { useRef, useEffect } from 'react';
import { useField } from 'formik';
import FormInputWrapper from './FormInputWrapper';

interface InputAttributes {
    label?: string | null;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    autofocus?: boolean;
    note?: string;
    rows?: number;
}

const FormInputTextarea: React.FC<{ attributes: InputAttributes, name: string }> = (props) => {
    const [field, meta] = useField(props);
    const { label, className, placeholder, disabled, autofocus, note, rows } = props.attributes;

    const input = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (autofocus && input.current !== null) {
            input.current.focus();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <FormInputWrapper className={className} meta={meta} note={note}>
            {label !== null && (
                <label htmlFor={`textarea-input-${field.name}`} className="form-label">
                    {label || <br />}
                </label>
            )}
            <textarea
                ref={input}
                id={`textarea-input-${field.name}`}
                rows={rows || 3}
                placeholder={placeholder}
                disabled={disabled}
                {...field}
            ></textarea>
        </FormInputWrapper>
    );
};

export default FormInputTextarea;
