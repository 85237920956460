import React from 'react';
import './Skeleton.scss';
import '../AppButtonSelect/ButtonSelect.scss';

const SkeletonButtonSelect = () => {
    return (
        <div className="button-select is-wrapper skeleton-loader">
            <div className="skeleton-title h-mb-0" style={{ width: '30%'}}></div>
        </div>
    );
};

export default SkeletonButtonSelect;
