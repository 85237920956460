import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import OnlineCourseLesson from './OnlineCourseLesson';
import OnlineCourseLessonDetail from './OnlineCourseLessonDetail';
import OnlineCourseLessonTest from './OnlineCourseLessonTest';

const OnlineCourse = () => {
    return (
        <Switch>
            <Route
                path={`${routesURLs.onlineCourse}/:courseId/:lessonId/test/:testId`}
                exact={true}
                render={(props) => <OnlineCourseLessonTest {...props} />}
            />
            <Route
                path={`${routesURLs.onlineCourse}/:courseId/:lessonId`}
                exact={true}
                render={(props) => <OnlineCourseLessonDetail {...props} />}
            />
            <Route
                path={`${routesURLs.onlineCourse}/:id`}
                exact={true}
                render={(props) => <OnlineCourseLesson {...props} />}
            />
            {/* <Route
                path={`${routesURLs.onlineCourses}/:subjectId/:lessonId`}
                exact={true}
                render={(props) => <OnlineCoursesDetail {...props} />}
            />
            <Route
                path={routesURLs.onlineCourses}
                exact={true}
                render={(props) => <OnlineCoursesSubjects {...props} />}
            /> */}
        </Switch>
    );
};

export default OnlineCourse;
