import React from 'react';
import '../../components/AppButtonSelect/ButtonSelect.scss';
import { useQuery } from 'react-query';
import { RouteComponentProps, useParams } from 'react-router-dom';
import BackButton from '../../components/AppBackButton/BackButton';
import MainLayout from '../../components/TheMainLayout/MainLayout';
import { getPersonalCourseLesson } from '../../moduls/personal-courses';
import { routesURLs } from '../../Routes';
import { format } from 'date-fns';
import { cs } from 'date-fns/locale';
import { PersonalCourseLessonType } from '../../services/types';
import Icon from '../../components/AppIcon/Icon';
import Loader from '../../components/AppLoader/Loader';

const PersonalCourseLesson: React.FC<RouteComponentProps> = (props) => {
    const { termId, lessonId } = useParams<{ termId: string; lessonId: string }>();
    const { data: lesson, isFetched } = useQuery<PersonalCourseLessonType>(
        ['personal-course-lesson', { id: +lessonId }],
        getPersonalCourseLesson
    );

    if (!isFetched) return <Loader />;
    if (!lesson) return null;

    return (
        <>
            <MainLayout>
                <div className="h-text-center">
                    <h1 className="h-mb-05">{lesson?.name}</h1>
                </div>
                <label>{format(new Date(lesson?.date), 'EEEE · d. MMMM yyyy', { locale: cs })}</label>
                {lesson?.lesson_materials.map((material) => (
                    <a
                        key={material.id}
                        className="button-select"
                        href={material.file_url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className="button-select-content">{material.name}</span>
                        <Icon icon="download-primary" width="1.25rem" height="1.25rem" />
                    </a>
                ))}
            </MainLayout>
            <BackButton route={`${routesURLs.personalCourse}/${termId}`} />
        </>
    );
};

export default PersonalCourseLesson;
