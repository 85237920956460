import React from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps, useParams } from 'react-router-dom';
import BackButton from '../../components/AppBackButton/BackButton';
import Loader from '../../components/AppLoader/Loader';
import OnlineCourseForm from '../../components/Forms/OnlineCourseForm';
import PriceList from '../../components/PriceLIst/PriceList';
import { getClasses, getOnlineCourses, getSubjects } from '../../moduls/online-courses';
import { OnlineCourseType, SubjectType, LessonClassType } from '../../services/types';
import './OnlineCourses.scss';

const OnlineCoursesDetail: React.FC<RouteComponentProps> = () => {
    const params = useParams<{ subjectId: string; lessonId: string }>();
    const { lessonId, subjectId } = params;

    const { data: subjects = [] } = useQuery<SubjectType[]>('subjects', getSubjects, {
        staleTime: Infinity,
    });
    const subject = subjects.filter((subject) => subject.id === +subjectId)[0]?.name;

    const { data: classes = [] } = useQuery<LessonClassType[]>(['classes', { subjectId: +subjectId }], getClasses, {
        staleTime: Infinity,
    });
    const lessonClass = classes.filter((lessonClass) => lessonClass.id === +lessonId)[0]?.name;

    const { data: courses = [], isFetched } = useQuery<OnlineCourseType[]>(
        ['online-courses', { subjectId, lessonId }],
        getOnlineCourses
    );
    const course = courses[0] || {};

    if (!isFetched) return <Loader />;

    function createMarkup() {
        return {__html: course.description};
    }

    return (
        <>
            <div className="col-lg-4 col-md-10 left-column">
                <div className="lector-photo">
                    <img src={course.lector?.image_url} alt="Profilová fotografie"  />
                    <span className="lector-name">{course.lector.name}</span>
                </div>

                <div className="left-column-content">
                    <div className="left-column-label">Popis kurzu:</div>
                    <div className="description" dangerouslySetInnerHTML={createMarkup()}></div>
                </div>

                <div className="left-column-content">
                    <div className="left-column-label">Celková délka videí v kurzu:</div>
                    <div>{course.video_desc_count}</div>
                </div>

                <div className="left-column-content">
                    <div className="left-column-label">Celkový počet úloh v kurzu:</div>
                    <div>
                        {course.text_question_count} testových otázek<br />
                        {course.working_sheet_count} pracovních listů
                    </div>
                </div>

                <div className="left-column-content">
                    <div className="left-column-label">Galerie:</div>
                    <div>
                        <img src={course.gallery[0]?.fullPath} alt={course.gallery[0]?.name} />
                        <img src={course.gallery[1]?.fullPath} alt={course.gallery[1]?.name} />
                        <img src={course.gallery[2]?.fullPath} alt={course.gallery[2]?.name} />
                        <img src={course.gallery[3]?.fullPath} alt={course.gallery[3]?.name} />
                    </div>
                </div>

            </div>
            <div className="col-lg-7 col-md-10 right-column">
                <h1 className="h-text-center">
                    On-line {subject} - {lessonClass}
                </h1>
                <PriceList
                    singlePrice={course.lesson_price}
                    totalPrice={course.price}
                    prices={course.price_packages || []}
                />
                <p>Můžete používat až na 3 zařízeních.</p>
                <OnlineCourseForm course={course} />
                <BackButton />
            </div>
        </>
    );
};

export default OnlineCoursesDetail;
