import React, { useEffect, useState } from 'react';
import { classes } from '../../../services/utils';
import './TrueFalseSwitcher.scss';

const TrueFalseButton: React.FC<{
    isActive: boolean;
    onClick: () => void;
    isDisabled: boolean;
    result: boolean;
}> = ({ isActive, isDisabled, result, onClick, children }) => {
    const [resultState, setResultState] = useState<boolean | null>(null);

    useEffect(() => {
        if (isDisabled) {
            setResultState(result);
        } else {
            setResultState(null);
        }
        // eslint-disable-next-line
    }, [isDisabled]);

    useEffect(() => {
    }, [isDisabled]);

    return (
        <button
            onClick={onClick}
            disabled={isDisabled}
            className={classes('true-false-button', {
                'is-active': isActive,
                'is-correct': (isActive && resultState === true) || (!isActive && resultState === false),
                'is-falsy': resultState === false,
            })}
        >
            {children}
        </button>
    );
};

const TrueFalseSwitcher: React.FC<{
    isDisabled: boolean;
    result: boolean;
    onChange: (value: boolean | null) => void;
}> = ({ isDisabled, result, onChange }) => {
    const [value, setValue] = useState<boolean | null>(null);

    useEffect(() => {
        onChange(value);
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        if (!isDisabled) setValue(null);
    }, [isDisabled]);

    return (
        <>
            <TrueFalseButton
                onClick={() => setValue(true)}
                isDisabled={isDisabled}
                result={result}
                isActive={value === true}
            >
                Ano
            </TrueFalseButton>
            <TrueFalseButton
                onClick={() => setValue(false)}
                isDisabled={isDisabled}
                result={result}
                isActive={value === false}
            >
                Ne
            </TrueFalseButton>
        </>
    );
};

export default TrueFalseSwitcher;
