import React, { useEffect, useState } from 'react';
import '../../components/AppButtonSelect/ButtonSelect.scss';
import { format, isBefore } from 'date-fns';
import { cs } from 'date-fns/locale';
import { useQuery } from 'react-query';
import { RouteComponentProps, useParams } from 'react-router-dom';
import BackButton from '../../components/AppBackButton/BackButton';
import LabelValue from '../../components/AppLabelValue/LabelValue';
import TableContainer from '../../components/AppTableContainer/TableContainer';
import MainLayout from '../../components/TheMainLayout/MainLayout';
import { getPersonalCourse } from '../../moduls/personal-courses';
import { routesURLs } from '../../Routes';
import { PersonalTerm } from '../../services/types';
import { classes, courseTermDescription } from '../../services/utils';
import Icon from '../../components/AppIcon/Icon';
import Loader from '../../components/AppLoader/Loader';

const PersonalCourseDetail: React.FC<RouteComponentProps> = (props) => {
    const [activeLesson, setActiveLesson] = useState(0);
    const { id } = useParams<{ id: string }>();
    const { data: course, isFetched } = useQuery<{ name: string; term: PersonalTerm }>(
        ['personal-course', { id: +id }],
        getPersonalCourse
    );

    useEffect(() => {
        if (course) {
            let lessonIsSet = false;
            course.term.lessons.forEach((lesson) => {
                if (isBefore(new Date(lesson.date), new Date()) && !lessonIsSet) {
                    setActiveLesson(lesson.id);
                }
            });
        }
        // eslint-disable-next-line
    }, [course]);

    if (!isFetched) return <Loader />;
    if (!course) return null;

    const term = course?.term;

    return (
        <>
            <MainLayout>
                <div className="h-text-center">
                    <h1>{course.name}</h1>
                    <p className="h-mb-2">Níže naleznete podklady z poslední konané lekce</p>
                </div>
                <label>Parametry kurzu</label>
                <TableContainer className="h-mb-2">
                    <div className="container-row">
                        <div className="col-sm-6">
                            <LabelValue
                                className="h-mb-15"
                                label="Adresa"
                                value={`${term.location.city.name} · ${term.location.street_name} ${term.location.street_number}`}
                            />
                        </div>
                        <div className="col-sm-6">
                            <LabelValue
                                className="h-mb-15"
                                label="Datum konání"
                                value={courseTermDescription({ dateFrom: term.date_from, dateTo: term.date_to })}
                            />
                        </div>
                        <div className="col-sm-6">
                            <LabelValue
                                className="h-mb-15"
                                label="Termín konání"
                                value={
                                    term.lessons[0]
                                        ? `${format(new Date(term.lessons[0].date), 'EEEE · p', {
                                              locale: cs,
                                          })}`
                                        : ''
                                }
                            />
                        </div>
                        <div className="col-sm-6">
                            <LabelValue className="h-mb-15" label="Lektor" value={`${term.lector.name}`} />
                        </div>
                    </div>
                </TableContainer>
                <label>Lekce</label>
                {term.lessons.map((lesson) => {
                    const isActive = lesson.id === activeLesson;
                    return (
                        <button
                            key={lesson.id}
                            className={classes('button-select', {
                                'is-wrapper': !isActive,
                                'is-checked': isActive,
                            })}
                            onClick={
                                isActive
                                    ? () => props.history.push(`${routesURLs.personalCourse}/${id}/${lesson.id}`)
                                    : () => {}
                            }
                        >
                            <span className="button-select-content">{`${lesson.name} · ${format(
                                new Date(lesson.date),
                                'd. MMMM yyyy',
                                { locale: cs }
                            )}${isActive ? ' · podklady ke stažení' : ''}`}</span>
                            {isActive ? <Icon icon="download-primary" width="1.25rem" height="1.25rem" /> : null}
                        </button>
                    );
                })}
            </MainLayout>
            <BackButton route={routesURLs.dashboard} />
        </>
    );
};

export default PersonalCourseDetail;
