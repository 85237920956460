import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Button from '../AppButton/Button';
import FormInputText from './Inputs/FormInputText';
import { ProfileFormType } from './form-types';
import { transformErrors } from '../../services/utils';
import { useHistory } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { useQuery } from 'react-query';
import { getProfile, updateProfile } from '../../moduls/profile';
import { ProfileType } from '../../services/types';
import FormInputCheckbox from './Inputs/FormInputCheckbox';

const attributes = {
    name: {
        placeholder: 'Vyplňte..',
        label: 'Vaše celé Jméno',
    },
    email: {
        placeholder: 'Vyplňte..',
        label: 'E-mail',
    },
    username: {
        placeholder: 'Vyplňte..',
        label: 'Uživatelské jméno',
    },
    phone_number: {
        placeholder: 'Vyplňte..',
        label: 'Telefon',
    },
    school: {
        placeholder: 'Vyplňte..',
        label: 'Základní škola',
    },
    notifications: {
        title: 'Chci dostávat průbězné notifikace e-mailem',
        label: 'E-mail notifikace',
        // note: 'Jednou týdně Vám zašleme e-mailem souhrnný stav vašich kurzů.',
    },
};

const ProfileEditForm: React.FC<{}> = () => {
    const { data: profile } = useQuery<ProfileType>('profile', getProfile, {
        staleTime: Infinity,
    });

    const { dispatch } = useGlobalState();
    const history = useHistory();
    const onSubmit = async (values: ProfileFormType, { setErrors }: FormikHelpers<ProfileFormType>) => {
        return updateProfile(values)
            .then((_) => {
                // dispatch({ type: GlobalTypes.ToggleNotification, style: 'success', text: 'Profil byl úspěšně upraven.'})
                history.push(routesURLs.profile);
            })
            .catch((error) => {
                setErrors(transformErrors(error));
            });
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Povinné pole'),
        phone_number: Yup.string().required('Povinné pole'),
        email: Yup.string().email('Vložte validní e-mail').required('Povinné pole'),
    });

    if (!profile) return null;

    const { id, ...initialValues } = profile;

    return (
        <Formik
            initialValues={{ ...initialValues, notifications: !!profile.notifications }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            {({ isValid, dirty, isSubmitting }) => (
                <Form>
                    <FormInputText name="email" attributes={attributes.email} />
                    <FormInputText name="name" attributes={attributes.name} />
                    <FormInputText name="username" attributes={attributes.username} />
                    <FormInputText name="phone_number" attributes={attributes.phone_number} />
                    <FormInputText name="school" attributes={attributes.school} />
                    <FormInputCheckbox name="notifications" attributes={attributes.notifications} />
                    <hr className="h-my-15" />
                    <div className="profile-edit-controls">
                        <Button
                            color="link"
                            onClick={() => dispatch({ type: GlobalTypes.ToggleModal, modal: 'ModalDeleteAccount' })}
                            className="h-mr-15"
                        >
                            Smazat účet
                        </Button>
                        <Button
                            color="link"
                            onClick={() => dispatch({ type: GlobalTypes.ToggleModal, modal: 'ModalChangePassword' })}
                        >
                            Změnit heslo
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            className="profile-edit-submit"
                            disabled={!(isValid && dirty)}
                            loading={isSubmitting}
                        >
                            Uložit změny
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ProfileEditForm;
