import React from 'react';
import './PersonalCourses.scss';
import MainLayout from '../../components/TheMainLayout/MainLayout';
import { Switch, Route } from 'react-router-dom';
import PersonalCoursesCity from './PersonalCoursesCity';
import PersonalCoursesDetail from './PersonalCoursesDetail';
import { routesURLs } from '../../Routes';

const PersonalCourses = () => {
    return (
        <MainLayout>
            <Switch>
                <Route
                    path={`${routesURLs.personalCourses}/:id`}
                    exact={true}
                    render={(props) => <PersonalCoursesDetail {...props} />}
                />
                <Route path={routesURLs.personalCourses} exact={true} render={(props) => <PersonalCoursesCity {...props} />} />
            </Switch>
        </MainLayout>
    );
};

export default PersonalCourses;
