import React, { useRef, useEffect } from 'react';

function useOutsideAlerter(
    ref: React.RefObject<HTMLDivElement>,
    eventTrigger: any
) {
    function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as any)) {
            eventTrigger();
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
}

const ClickOutside: React.FC<{
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    className?: string;
}> = (props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, props.onClick);

    return (
        <div ref={wrapperRef} className={props.className}>
            {props.children}
        </div>
    );
};

export default ClickOutside;
