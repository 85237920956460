import React from 'react';
import ForgottenPasswordForm from '../Forms/ForgottenPasswordForm';

const ModalForgottenPassword = () => {
    return (
        <div className="modal-content">
            <div className="h-text-center">
                <h1 className="h-mb-05">Zapomenuté heslo</h1>
                <div className="container-row center-md">
                    <div className="col-md-10">
                        <p>Zadejte Vaše přihlašovací jméno a my Vám na e-mail zašleme odkaz pro obnovu hesla.</p>
                    </div>
                </div>
            </div>
            <ForgottenPasswordForm />
        </div>
    );
};

export default ModalForgottenPassword;
