import { format } from 'date-fns';
import { cs } from 'date-fns/locale';

export const classes = (classString: string, classes?: { [key: string]: boolean }) => {
    const classesList = [];
    for (const classKey in classes) {
        if (classes.hasOwnProperty(classKey)) {
            const condition = classes[classKey];
            if (condition) {
                classesList.push(classKey);
            }
        }
    }
    return `${classString}${classesList.length && classString ? ' ' : ''}${classesList.join(' ')}`;
};

type ErrorType = { response: { data: { errors: { [param: string]: string[] }; msg: string } } };

export const transformErrors = (error: ErrorType) => {
    const data = error.response.data.errors;
    const errorList = Object.keys(data);
    const errors: { [key: string]: string } = {};
    if (errorList.length) {
        errorList.forEach((item) => {
            errors[item] = data[item][0];
        });
    }
    return errors;
};

export const pad = function (a: number) {
    return a < 10 ? '0' + a : a;
};

export const formateNumber = (number: number, round: number) => {
    let decimals = Math.pow(10, round);
    const adjNumber = Math.round(number * decimals) / decimals;
    return adjNumber
        .toString()
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const capitalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sessionStore = {
    get: (item: string) => {
        return JSON.parse(sessionStorage.getItem(item) || JSON.stringify(''));
    },
    set: (item: string, values: any) => {
        sessionStorage.setItem(item, JSON.stringify(values));
    },
};

export const courseTermDescription = ({
    dateFrom,
    dateTo,
    price,
    lessonDate,
}: {
    dateFrom: string;
    dateTo: string;
    lessonDate?: string;
    price?: number;
}) => {
    const selectedDateFrom = new Date(dateFrom);
    const selectedDateTo = new Date(dateTo);
    return `${price ? formateNumber(price, 0) + ' Kč · ' : ''}${
        lessonDate
            ? `${format(new Date(lessonDate), 'EEEE · p  · ', {
                  locale: cs,
              })}`
            : ''
    }${format(selectedDateFrom, 'd. MMMM yyyy', {
        locale: cs,
    })} - ${format(selectedDateTo, 'd. MMMM yyyy', {
        locale: cs,
    })}`;
};

export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts: string[] = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()!.split(';').shift();
};

export const setCookie = (name: string, value: string, days: number) => {
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = `${name}=${value};${days ? expires : ''}; path=/`;
};

export const deleteCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const plural = (count: number, singular: string, plural: string, plural5more: string) => {
    if (count === 1) {
        return singular;
    } else if (count > 1 && count < 5) {
        return plural;
    } else {
        return plural5more;
    }
};
