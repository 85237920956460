import React from 'react';
import './MainLayout.scss';
import { classes } from '../../services/utils';

const MainLayout: React.FC<{ className?: string }> = ({ className, children }) => {
    return (
        <div className={classes('main-layout', { [className || '']: !!className })}>
            <div className="container-row center-sm">
                <div className="col-lg-8 col-md-10">{children}</div>
            </div>
        </div>
    );
};

export default MainLayout;
