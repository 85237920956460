import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '../AppButton/Button';
import FormInputText from './Inputs/FormInputText';
import { TestType } from './form-types';
import FormInputSelect from './Inputs/FormInputSelect';
import FormInputCheckbox from './Inputs/FormInputCheckbox';
import FormInputRadio from './Inputs/FormInputRadio';
import CheckboxItem from '../AppCheckboxItem/CheckboxItem';
import FormInputTextarea from './Inputs/FormInputTextarea';

const initialValues = {
    text: '',
    textarea: '',
    password: '',
    icon: '',
    select: '',
    checkbox: false,
    radio: '',
    radioStyled: '',
};

const attributes = {
    text: {
        placeholder: 'Zadejte..',
        label: 'Text field',
        note: 'This is note',
    },
    textarea: {
        placeholder: 'Zadejte..',
        label: 'Textarea field',
        note: 'This is note',
    },
    password: {
        placeholder: 'Zadejte heslo..',
        label: 'Password field',
        note: 'This is note',
        password: true,
    },
    icon: {
        placeholder: 'Zadejte..',
        label: 'Icon field',
        icon: 'shopping-cart-primary',
    },
    select: {
        placeholder: 'Vyberte..',
        label: 'Select field',
        note: 'This is note',
        options: [
            { value: 1, label: 'Option no. 1' },
            { value: 2, label: 'Option no. 2' },
            { value: 3, label: 'Option no. 3' },
        ],
        icon: 'shopping-cart-primary',
    },
    checkbox: {
        title: 'Check if you like',
        label: 'Checkbox label',
        note: 'This is note',
    },
    radio: {
        label: 'Radio field',
        note: 'This is note',
        options: [
            { value: 1, label: 'Option no. 1' },
            { value: 2, label: 'Option no. 2' },
            { value: 3, label: 'Option no. 3' },
        ],
    },
    radioStyled: {
        label: 'Custom radio field',
        note: 'This is note',
        options: [
            { value: 1, label: 'Option no. 1' },
            { value: 2, label: 'Option no. 2' },
            { value: 3, label: 'Option no. 3' },
        ],
    },
};

const TestForm: React.FC<{}> = () => {
    const onSubmit = async (values: TestType) => {
        console.log(values);
    };

    const validationSchema = Yup.object().shape({
        text: Yup.string().required('Povinné pole'),
        select: Yup.string().required('Povinné pole'),
        checkbox: Yup.bool().oneOf([true], 'Field must be checked'),
        radio: Yup.string().required('Povinné pole'),
    });

    return (
        <div>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {({ isValid, dirty, isSubmitting, values }) => (
                    <Form>
                        <FormInputText name="text" attributes={attributes.text} />
                        <FormInputTextarea name="textarea" attributes={attributes.textarea} />
                        <FormInputText name="password" attributes={attributes.password} />
                        <FormInputText name="icon" attributes={attributes.icon} />
                        <FormInputSelect name="select" attributes={attributes.select} />
                        <FormInputCheckbox name="checkbox" attributes={attributes.checkbox} />
                        <FormInputRadio name="radio" attributes={attributes.radio} />
                        <FormInputRadio name="radioStyled" attributes={attributes.radioStyled}>
                            {attributes.radio.options.map((item) => (
                                <CheckboxItem key={item.value} text={item.label} />
                            ))}
                        </FormInputRadio>
                        <Button type="submit" color="primary" disabled={!(isValid && dirty)} loading={isSubmitting}>
                            Odeslat formulář
                        </Button>
                        <pre>{JSON.stringify(values, null, 2)}</pre>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default TestForm;

