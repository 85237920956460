import React from 'react';
import './LabelValue.scss';
import { classes } from '../../services/utils';

interface LabelValueType {
    label: string;
    value?: string;
    className?: string;
}

const LabelValue: React.FC<LabelValueType> = (props) => {
    return (
        <div className={classes('label-value', { [props.className || '']: !!props.className })}>
            <div className="label-value-label">{props.label}</div>
            <div className="label-value-value">{props.value}</div>
        </div>
    );
};

export default LabelValue;
