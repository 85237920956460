import React from 'react';
import ImageBox from '../../components/ImageBox/ImageBox';
import BackButton from '../../components/AppBackButton/BackButton';
import { routesURLs } from '../../Routes';
import {  RouteComponentProps } from 'react-router-dom';
import { getFreeLessons, getSubjects } from '../../moduls/online-courses';
import { useQuery } from 'react-query';
import { FreeOnlineCourse, SubjectType } from '../../services/types';

import czechLanguageImage from '../../assets/images/icons/ic-czech-language-primary.svg';
import SkeletonImageBox from '../../components/Skeletons/SkeletonImageBox';
// import mathImage from '../../assets/images/icons/ic-math-primary.svg';

const OnlineCoursesSubjects: React.FC<RouteComponentProps> = (props) => {
    const { data: freeCourses = [], isFetched: freeCoursesFetched } = useQuery<FreeOnlineCourse[]>(
        'free-courses',
        getFreeLessons
    );
    const { data: subjects = [], isFetched } = useQuery<SubjectType[]>('subjects', getSubjects, {
        staleTime: Infinity,
    });

    return (
        <>
            <div className="page-row">
                <h1 className="h-text-center h-mb-175">Vyberte si on-line kurz</h1>
                <h3 className="h-text-center">Dostupné kurzy</h3>
                <div className="container-row center-sm">
                    {!isFetched && <Loader />}
                    {subjects.map((subject) => (
                        <div key={subject.id} className="col-md-4">
                            <ImageBox
                                image={czechLanguageImage}
                                color="primary"
                                title={subject.name}
                                onClick={() => props.history.push(`${routesURLs.onlineCourses}/${subject.id}`)}
                            />
                        </div>
                    ))}
                    {!!freeCourses.length && freeCoursesFetched && (
                        <div className="free-lesson-message h-text-center h-display-block">
                            V každém kurzu několik lekcí zcela zdarma
                        </div>
                    )}
                </div>
            </div>
            <BackButton route={routesURLs.homepage} />
        </>
    );
};

const Loader = () => (
    <>
        <div className="col-md-4">
            <SkeletonImageBox />
        </div>
        <div className="col-md-4">
            <SkeletonImageBox />
        </div>
        <div className="col-md-4">
            <SkeletonImageBox />
        </div>
    </>
);

export default OnlineCoursesSubjects;
