import axios from 'axios';
import { routesURLs } from '../Routes';
import { deleteCookie, getCookie } from './utils';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('jid')}`,
    }
});

api.interceptors.request.use(function (config) {
    if (!config.headers.Authorization) {
        config.headers = { ...config.headers, ...api.defaults.headers };
    }
    return config;
});

api.interceptors.response.use(
    function (response) {
        // MIDDLEWARE
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            deleteCookie('jid');
            window.router.replace(routesURLs.homepage);
        }

        if (error.response.config.url === '/login') {
            for (const errorKey in error.response.data.errors) {
                if (['username', 'password'].includes(errorKey)) {
                    return Promise.reject(error);  
                }
            }
        }

        window.toggleNotification('error', 'Ups, něco se pokazilo');

        return Promise.reject(error);
    }
);

export default api;
