import React from 'react';
import { useField } from 'formik';
import FormInputWrapper from './FormInputWrapper';

interface InputAttributes {
    label?: string | null;
    title?: string;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    note?: string;
}

const FormInputCheckbox: React.FC<{attributes: InputAttributes, name: string}> = (props) => {
    const [field, meta] = useField(props);
    const { label, className, disabled, title, note } = props.attributes;

    return (
        <FormInputWrapper className={className} meta={meta} note={note}>
            {label !== null && <div className="form-label h-display-block">{label || <br />}</div>}
            <label htmlFor={`checkbox-input-${field.name}`} className="form-checkbox-wrapper is-title">
                <input type="checkbox" id={`checkbox-input-${field.name}`} disabled={disabled} {...field} checked={field.value} />
                <div
                    className={'form-checkbox' + (field.value ? ' is-checked' : '') + (disabled ? ' is-disabled' : '')}
                ></div>
                {props.children || <span className="form-checkbox-title">{title}</span>}
            </label>
        </FormInputWrapper>
    );
};

export default FormInputCheckbox;
