import { PasswordChangeFormType, ProfileFormType } from '../components/Forms/form-types';
import { routesURLs } from '../Routes';
import api from '../services/api';
import { deleteCookie } from '../services/utils';

export const getProfile = () => {
    return api.get('/profile').then((res) => {
        return res.data.data;
    });
};

export const updateProfile = (data: ProfileFormType) => {
    return api.put('/profile', data).then((res) => {
        return res.data.data;
    });
};

export const changePassword = (data: PasswordChangeFormType) => {
    return api.post('/password', data).then((res) => {
        return res.data.data;
    });
};

export const deleteProfile = () => {
    return api.delete('/profile').then(async () => {
        deleteCookie('jid');
        window.router.replace(routesURLs.homepage);
    });
};
