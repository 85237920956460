import React from 'react';
import './BackButton.scss';
import { useHistory } from 'react-router-dom';
import IconButton from '../AppIconButton/IconButton';

const BackButton: React.FC<{ route?: string }> = ({ route }) => {
    const history = useHistory();

    return (
        <IconButton
            icon="chevron-left-gray"
            className="back-button"
            onClick={() => (route ? history.push(route) : history.goBack())}
            title="Zpět"
        />
    );
};
export default BackButton;
