import React, {useEffect, useState} from 'react';
import { useQuery } from 'react-query';
import { Prompt, RouteComponentProps, useParams } from 'react-router-dom';
import Loader from '../../components/AppLoader/Loader';
import TestQuestionOpen from '../../components/TestQuestions/TestQuestionOpen';
import TestQuestionSelect from '../../components/TestQuestions/TestQuestionSelect';
import TestQuestionStatement from '../../components/TestQuestions/TestQuestionStatement';
import MainLayout from '../../components/TheMainLayout/MainLayout';
import TestNotification from '../../components/TheTestNotification/TestNotification';
import Topbar from '../../components/TheTopbar/Topbar';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { completeLessonTest, getOnlineCourseLessonTest } from '../../moduls/online-courses';
import { OnlineCourseTest } from '../../services/types';
import { sessionStore } from '../../services/utils';
import CompletedTest from "../../components/CompletedTest/CompletedTest";
import { routesURLs } from '../../Routes';

const OnlineCourseLessonTest: React.FC<RouteComponentProps> = (props) => {
    const { state, dispatch } = useGlobalState();
    let testQuestion;
    const params = useParams<{ courseId: string; lessonId: string; testId: string }>();
    const savedTests = sessionStore.get('tests') || {};
    const [question, setQuestion] = useState(
        savedTests[params.testId]?.answers ? savedTests[params.testId]?.answers.length : 0
    );
    const [answers, setAnswers] = useState<{ id: number; answer: any }[]>(savedTests[params.testId]?.answers || []);
    const [score, setScore] = useState((savedTests[params.testId]?.score as number) || 0);
    const [dialog, setDialog] = useState<'primary' | 'alert' | 'loading' | ''>('');
    const [isFinishTest, setFinishTest] = useState<true | false>(false);
    const [isTestFinishing, setTestFinishing] = useState<true | false>(false);
    const { data: lessonTest, isFetched } = useQuery<OnlineCourseTest>(
        ['online-course-lesson-test', { id: params.testId }],
        getOnlineCourseLessonTest
    );
    const [canLeave, setCanLeave] = useState(false);

    useEffect(() => {
        if (isFinishTest && !isTestFinishing) {
            setTestFinishing(true);
            const urlParams = new URLSearchParams(window.location.search);
            setTimeout(() => {
                sessionStore.set('tests', {});
                props.history.push(`${routesURLs.onlineCourse}/${params.courseId}/${params.lessonId}#${urlParams.get('part')}`);
                setFinishTest(false);
                setTestFinishing(false);
            }, 7000);
        }
    }, [isFinishTest, params, props.history, setFinishTest, isTestFinishing]);

    const finishCourse = () => {
        const lessonTestId = +params.testId;
        const aswersData = sessionStore.get('tests')[params.testId]?.answers || [];

        dispatch({ type: GlobalTypes.ToggleLoader, loader: 'completeLessonTest' });
        completeLessonTest(lessonTestId, aswersData)
            .then((response) => {
                dispatch({
                    type: GlobalTypes.ToggleNotification,
                    style: 'success',
                    text: `Test byl úspěšně uložen. Vaše skóre je ${response.data.data.score}.`,
                    isTest: true,
                });

                setFinishTest(true);
                setDialog('');
            })
            .finally(() => {
                dispatch({ type: GlobalTypes.ToggleLoader });
            });
    };

    if (!isFetched) return <Loader />;

    async function finishQuestion({ id, value, answer }: { id: number; value: boolean; answer: any }) {
        if (value) setScore((score) => score + 1);
        setDialog('loading');
        setAnswers([...answers, { id, answer }]);
        sessionStore.set('tests', {
            ...savedTests,
            [params.testId]: {
                score,
                answers: [...answers, { id, answer }],
            },
        });
        if (question + 1 < lessonTest!.questions.length) {
            completeLessonTest(+params.testId, [...answers, { id, answer }]).then(() =>
                setDialog(value ? 'primary' : 'alert')
            );
        } else {
            setDialog(value ? 'primary' : 'alert');
        }
    }

    function nextQuestionOrDone() {
        if (question + 1 < lessonTest!.questions.length) {
            setQuestion((quest: number) => quest + 1);
            setDialog('');
        } else {
            setCanLeave(true);
            finishCourse();
        }
    }

    switch (lessonTest?.questions[question]?.type) {
        case 'open':
            testQuestion = <TestQuestionOpen question={lessonTest.questions[question]} onDone={finishQuestion} />;
            break;
        case 'select':
            testQuestion = <TestQuestionSelect question={lessonTest.questions[question]} onDone={finishQuestion} />;
            break;
        case 'statement':
            testQuestion = <TestQuestionStatement question={lessonTest.questions[question]} onDone={finishQuestion} />;
            break;
    }

    return (
        <>
            <Topbar
                type="test"
                title={lessonTest?.name}
                currentQuestion={question + 1}
                questions={lessonTest?.questions.length || 1}
                score={score}
                test={true}
            />

            {!isFinishTest && (
                <MainLayout>{testQuestion}</MainLayout>
            )}

            <Prompt when={!canLeave} message={() => `Určitě chcete test ukončit? Tento výsledek bude uložen.`} />
            {!!dialog && (
                <TestNotification
                    loading={state.loader === 'completeLessonTest'}
                    type={dialog}
                    onClick={nextQuestionOrDone}
                    isLast={lessonTest!.questions.length <= question + 1}
                />
            )}

            {isFinishTest && (
                <CompletedTest
                    score={score}
                    params={params}
                />
            )}
        </>
    );
};

export default OnlineCourseLessonTest;
