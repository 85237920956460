import React, { useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query-devtools';
import Routes, { routesURLs } from './Routes';
import Footer from './components/TheFooter/Footer';
import useScrollMemory from './hooks/useScrollMemory';
import ModalWrapper from './components/TheModal/ModalWrapper';
import Notification from './components/TheNotification/Notification';
import { deleteCookie, getCookie, sessionStore, setCookie } from './services/utils';
import { useCoursesState } from './contexts/courses';
import { GlobalTypes, useGlobalState } from './contexts/global';
import { NotificationStyle } from './services/types';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { logout, switchUser } from './moduls/auth';
import api from './services/api';

const queryCache = new QueryCache({
    defaultConfig: {
        queries: {
            retry: 0,
            staleTime: 0,
            cacheTime: 5 * 60 * 1000,
            refetchOnWindowFocus: false,
        },
    },
});

function App() {
    useScrollMemory();
    const { state } = useCoursesState();
    const { dispatch } = useGlobalState();
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        window.toggleNotification = (style: NotificationStyle, text: string) =>
            dispatch({ type: GlobalTypes.ToggleNotification, style, text });

        if (history.action === 'REPLACE') {
            dispatch({ type: GlobalTypes.SetPrevRoute, route: location.pathname })
        }

        if (searchParams.get('switch-user')) {
            switchUser(searchParams.get('switch-user') ?? '');
            history.push(routesURLs.dashboard);
        }

        if (!getCookie('device-token')) {
            // setting UUID to device-token cookie
            setCookie(
              'device-token',
              (String(1e7) + -1e3 + -4e3 + -8e3 + -1e11).replace(
                /[018]/g,
                (c) =>
                  (
                    Number(c) ^
                    (crypto.getRandomValues(new Uint8Array(1))[0] &
                      (15 >> (Number(c) / 4)))
                  ).toString(16)
              ),
              400
            );
            if (getCookie('jid')) {
                api.post('/remember-device-token', {device_token: getCookie('device-token')}).then((res) => {
                    if (!res.data.data.success) {
                        logout();
                    }
                });
            }
        }

        const deviceToken = getCookie('device-token')!;
        deleteCookie('device-token');
        setCookie('device-token', deviceToken, 400);
        if (getCookie('jid')) {
            const jid = getCookie('jid')!;
            deleteCookie('jid');
            setCookie('jid', jid, 400);
        }

        if (searchParams.get('payment')) {
            let paymentMessage: { text: string; style: NotificationStyle } = {
                text: '',
                style: 'success',
            };
            switch (searchParams.get('payment')) {
                case 'success':
                    paymentMessage.text = 'Platba byla úspěšně provedena.';
                    break;
                case 'cancelled':
                    paymentMessage.text = 'Nastala chyba při provádění platby.';
                    paymentMessage.style = 'error';
                    break;
                case 'pending':
                    paymentMessage.text = 'Čekáme, až se platba provede.';
                    paymentMessage.style = 'info';
                    break;
            }

            dispatch({ type: GlobalTypes.ToggleNotification, style: paymentMessage.style, text: paymentMessage.text });
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        sessionStore.set('onsite_courses', state.savedPersonalCourses);
        sessionStore.set('online_courses', state.savedOnlineCourses);
    }, [state.savedPersonalCourses, state.savedOnlineCourses]);

    return (
        <div className="App">
            <ReactQueryCacheProvider queryCache={queryCache}>
                <Notification />
                <Routes />
                <ModalWrapper />
                <Footer />
            </ReactQueryCacheProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </div>
    );
}

export default App;
