import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';
import { classes } from '../../services/utils';
import IconButton from '../AppIconButton/IconButton';

const Modal: React.FC<{ isOpen: boolean; close: () => void }> = ({ isOpen, close, children }) => {
    const backdrop = useRef(null);
    const modal = useRef(null);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [isOpen]);

    useEffect(() => {
        function closeModalOnEscape(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                closeModal();
            }
        }
        window.addEventListener('keydown', closeModalOnEscape, false);
        return () => {
            window.removeEventListener('keydown', closeModalOnEscape, false);
        };
        // eslint-disable-next-line
    }, []);

    function closeModal() {
        close();
    }

    function closeModalOnBackdropClick(el: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (backdrop.current === el.target) {
            closeModal();
        }
    }

    return ReactDOM.createPortal(
        <div
            ref={backdrop}
            className={classes('modal-backdrop', { 'is-open': isOpen })}
            onClick={closeModalOnBackdropClick}
        >
            <div className="modal" ref={modal}>
                <IconButton
                    icon="close-gray"
                    onClick={closeModal}
                    title="Zavřít modální okno"
                    className="modal-close"
                />
                {children}
            </div>
        </div>,
        document.getElementById('modal-root')!
    );
};

export default Modal;
