import React from 'react';
import './FreeCourseBanner.scss';
import { Link } from 'react-router-dom';
import { classes } from '../../services/utils';
import { routesURLs } from '../../Routes';

const FreeCourseBanner: React.FC<{ className?: string; courses: any }> = ({ className = '', courses }) => {
    let isOnlyOutside = false;

    if (courses && (courses?.onsite_courses?.length && !courses?.online_courses?.length)) {
        isOnlyOutside = true;
    }

    return (
        <div className={classes('free-course-banner', { [className || '']: !!className })}>
            <div className="free-course-banner-content">

                {!isOnlyOutside && (
                    <span>
                        <h2>Vyzkoušejte si další on-line kurz</h2>
                        <span className="someFreeLessons">V každém kurzu pro Vás máme několik lekcí zcela zdarma.</span>
                    </span>
                )}

                {isOnlyOutside && (
                    <h2>Vyzkoušejte naše on-line kurzy zdarma!</h2>
                )}
            </div>
            <Link to={routesURLs.onlineCourses} className="button is-primary">
                Vyzkoušet
            </Link>
        </div>
    );
};

export default FreeCourseBanner;
