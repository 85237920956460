import React from 'react';
import { useQuery } from 'react-query';
import CTACard from '../../components/CTACard/CTACard';
import OverviewItem from '../../components/OverviewItem/OverviewItem';
import ResultBar from '../../components/ResultBar/ResultBar';
import { coursesOverview } from '../../moduls/courses';
import { routesURLs } from '../../Routes';
import { formateNumber } from '../../services/utils';

const baseImages: { [key: string]: string } = {
    matematika: 'icon-math-primary',
    čeština: 'icon-czech-language-primary',
    angličtina: 'icon-english-primary',
};

interface IOverviewItem {
    id: number;
    name: string;
    subject: { id: number; name: 'matematika' | 'čeština' | 'angličtina' };
    lessons: { all: number; completed: number };
    tests: {
        best_score_average: number | null;
        count: number | null;
        first_score_average: number | null;
        lessons: {
            id: number;
            name: string;
            tests: {
                best_score_average: number | null;
                count: number | null;
                first_score_average: number | null;
            };
        }[];
        recommended: { id: number; name: string }[];
    };
    videos: {
        minutes: number;
        lessons: {
            id: number;
            minutes: number;
            name: string;
        }[];
    };
}

const Overview = () => {
    const { data } = useQuery<IOverviewItem[]>('courses-overview', coursesOverview);

    if (data?.length === 0) {
         return (
             <div>
                 <div className="container-row top-sm h-py-275">
                     <div className="col-md-9">
                         <div className="">
                             <div className="overview-item-title">
                                 <div className="overview-item-labels">
                                     <div className="overview-item-label">
                                         <div>Bohužel tu pro Vás nic nemáme :-(. Přehled aktivity slouží pro zákazníky, kteří mají zakoupen některý z našich on-line kurzů. Vyzkoušejte si také zdarma náš on-line kurz!</div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div className="col-md-3 h-mt-25">
                         <CTACard
                             route={routesURLs.onlineCourses}
                             color="primary"
                             title="kupte si snadno a rychle další on-line kurzy"
                             text="S našimi kurzy Vás ve škole nic nepřekvapí."
                         />
                     </div>
                 </div>
             </div>
         );
    }
    else {
        return (
            <div className="container-row top-sm h-py-275">
                <div className="col-md-9">
                    {data &&
                        data.map((item) => (
                            <div key={item.id} className="h-mb-25">
                                <h3 className={`icon-title ${baseImages[item.subject.name]}`}>
                                    {item.subject.name} - {item.name}
                                </h3>
                                <hr />
                                <OverviewItem icon="icon-play-green" videos={`${item.videos.minutes ?? 0} min`}>
                                    <div className="table-wrapper">
                                        <div>
                                            <table style={{ minWidth: '0' }}>
                                                <tbody>
                                                {item.videos.lessons
                                                    .filter((lesson) => !!lesson.minutes)
                                                    .map((lesson) => (
                                                        <tr key={lesson.id}>
                                                            <td>{lesson.name}</td>
                                                            <td>{lesson.minutes} min</td>
                                                        </tr>
                                                    ))}
                                                <tr>
                                                    <td className="h-text-bold">Celkem</td>
                                                    <td className="h-text-bold">{item.videos.minutes} min</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </OverviewItem>
                                <OverviewItem
                                    icon="icon-list-check-green"
                                    totalTests={item.tests.count?.toString() ?? '0'}
                                    firstAttampt={formateNumber(item.tests.first_score_average ?? 0, 1) + ' %'}
                                    bestAttampt={formateNumber(item.tests.best_score_average ?? 0, 1) + ' %'}
                                    recomendation={item.tests.recommended.reduce(
                                        (sum, item) => sum + (sum ? ', ' : '') + item.name,
                                        ''
                                    )}
                                >
                                    <div className="table-wrapper">
                                        <div>
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Lekce</th>
                                                    <th>Počet cvičení</th>
                                                    <th>První pokus</th>
                                                    <th>Nej. výsledek</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {item.tests.lessons
                                                    .filter((lesson) => !!lesson.tests.count)
                                                    .map((lesson) => (
                                                        <tr key={lesson.id}>
                                                            <td>{lesson.name}</td>
                                                            <td>{lesson.tests.count}</td>
                                                            <td>
                                                                <ResultBar
                                                                    value={lesson.tests.first_score_average ?? 0}
                                                                />
                                                            </td>
                                                            <td>
                                                                <ResultBar
                                                                    value={lesson.tests.best_score_average ?? 0}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </OverviewItem>
                                <OverviewItem
                                    icon="icon-flag-green"
                                    totalLessons={`${item.lessons.completed} / ${item.lessons.all}`}
                                ></OverviewItem>
                            </div>
                        ))}
                </div>
                <div className="col-md-3 h-mt-25">
                    <CTACard
                        route={routesURLs.onlineCourses}
                        color="primary"
                        title="kupte si snadno a rychle další on-line kurzy"
                        text="S našimi kurzy Vás ve škole nic nepřekvapí."
                    />
                </div>
            </div>
        );
    }
};

export default Overview;
