import React from 'react';
import './Modal.scss';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import ModalChangePassword from './ModalChangePassword';
import ModalDeleteAccount from './ModalDeleteAccount';
import ModalForgottenPassword from './ModalForgottenPassword';
import ModalChooseCourse from './ModalChooseCourse';
import ModalBasket from './ModalBasket';
import Modal from './Modal';
import ModalPaymentInfo from "./ModalPaymentInfo";

export type ModalsType =
    | ''
    | 'ModalChangePassword'
    | 'ModalDeleteAccount'
    | 'ModalForgottenPassword'
    | 'ModalChooseCourse'
    | 'ModalPaymentInfo'
    | 'ModalBasket';

const ModalWrapper = () => {
    const { dispatch, state } = useGlobalState();

    const components: { [modalName: string]: JSX.Element } = {
        ModalChangePassword: <ModalChangePassword />,
        ModalForgottenPassword: <ModalForgottenPassword />,
        ModalDeleteAccount: <ModalDeleteAccount closeModal={closeModal} />,
        ModalChooseCourse: <ModalChooseCourse closeModal={closeModal} />,
        ModalBasket: <ModalBasket closeModal={closeModal} />,
        ModalPaymentInfo: <ModalPaymentInfo closeModal={closeModal}/>,
    };

    function closeModal() {
        dispatch({ type: GlobalTypes.ToggleModal, modal: '' });
    }

    return (
        <Modal isOpen={!!state.modalComponent} close={closeModal}>
            {components[state.modalComponent]}
        </Modal>
    );
};

export default ModalWrapper;
