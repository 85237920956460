import React from 'react';
import './Homepage.scss';
import MainLayout from '../../components/TheMainLayout/MainLayout';
import ImageBox from '../../components/ImageBox/ImageBox';
import LoginForm from '../../components/Forms/LoginForm';
import { useHistory } from 'react-router-dom';
import { routesURLs } from '../../Routes';

import onlineCoursesImage from '../../assets/images/icons/ic-online-lessons.svg';
import personCoursesImage from '../../assets/images/icons/ic-personal-lessons.svg';

const Homepage = () => {
    const history = useHistory();

    return (
        <MainLayout className="homepage">
            <div className="page-row">
                <h1 className="h-text-center h-mb-175">O jaké kurzy máte zájem?</h1>
                <div className="container-row">
                    <div className="col-md-6">
                        <ImageBox
                            image={onlineCoursesImage}
                            color="primary"
                            title="On-line kurzy"
                            onClick={() => history.push(routesURLs.onlineCourses)}
                        />
                    </div>
                    <div className="col-md-6">
                        <ImageBox
                            image={personCoursesImage}
                            color="secondary"
                            title="Kurzy na místě"
                            onClick={() => history.push(routesURLs.personalCourses)}
                        />
                    </div>
                </div>
            </div>
            <div className="page-row">
                <h1 className="h-text-center">Přihlášení</h1>
                <p className="h-text-center">Již jste zakoupili některý z našich kurzu? Jednoduše se přihlašte.</p>
                <LoginForm />
            </div>
        </MainLayout>
    );
};

export default Homepage;
