import React, { FormEvent, useEffect, useRef, useState } from 'react';
import './TestQuestions.scss';
import { OnlineCourseTestQuestion } from '../../services/types';
import { classes } from '../../services/utils';
import Button from '../AppButton/Button';
import Label from '../AppLabel/Label';
import TestExplanation from './TestExplanation/TestExplanation';

interface QuestionOpenType {
    question: OnlineCourseTestQuestion;
    onDone: ({ id, value, answer }: { id: number; value: boolean; answer: string }) => void;
}

const TestQuestionOpen: React.FC<QuestionOpenType> = ({ question, onDone }) => {
    const { answer, text, id, explanation, image_url } = question;
    const [value, setValue] = useState('');
    const [done, setDone] = useState(false);
    const [init, setInit] = useState(false);
    const [result, setResult] = useState<'correct' | 'falsy' | ''>('');
    const input = useRef<HTMLInputElement>(null);

    function transformTextLowerCaseNormalize(text: string) {
        return text
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .replace(', ', '')
    }

    function isCorrect() {
        return transformTextLowerCaseNormalize(answer as string) === transformTextLowerCaseNormalize(value);
    }

    function handleSubmit(e: FormEvent) {
        e.preventDefault();
        if (value && !done) {
            setDone(true);
            setResult(isCorrect() ? 'correct' : 'falsy');
            onDone({
                id,
                value: isCorrect(),
                answer: value,
            });
        } else {
            setInit(true);
        }
    }

    useEffect(() => {
        setDone(false);
        setResult('');
        setValue('');
        if (input.current !== null) {
            setTimeout(() => {
                input.current!.focus();
            }, 1);
        }
    }, [id]);

    return (
        <div
            className={classes('form-field-wrapper', {
                'is-invalid': !value && init,
            })}
        >
            {!!image_url && (
                <div className="h-text-center h-mb-2">
                    <img src={image_url} alt={text} />
                </div>
            )}
            <h2 className="h-text-center" dangerouslySetInnerHTML={{ __html: text }}></h2>
            <Label htmlFor="question-type-open">Vaše odpověď</Label>
            <form onSubmit={handleSubmit}>
                <div className="h-relative">
                    <input
                        id="question-type-open"
                        className={`test-question-input is-${result}`}
                        ref={input}
                        type="text"
                        placeholder="Napište odpověď.."
                        value={value}
                        disabled={done}
                        onChange={(e) => setValue(e.target.value)}
                    />
                    {done && !!explanation && (
                        <TestExplanation>
                            <div dangerouslySetInnerHTML={{ __html: explanation }}></div>
                        </TestExplanation>
                    )}
                </div>
                <div className="form-input-footnote">
                    {!value && init && <div className="form-error-messages">Odpovězte prosím</div>}
                </div>
                <div className="h-text-center h-mt-2">
                    <Button type="submit" color="primary" disabled={done}>
                        Potvrdit odpověď
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default TestQuestionOpen;
