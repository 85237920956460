import React, { useEffect, useState } from 'react';
import { OnlineCourseTestQuestion } from '../../services/types';
import { classes } from '../../services/utils';
import ButtonSelect from '../AppButtonSelect/ButtonSelect';
import Icon from '../AppIcon/Icon';
import TestExplanation from './TestExplanation/TestExplanation';

const orderLetters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'ch', 'i', 'j', 'k'];

interface QuestionOpenType {
    question: OnlineCourseTestQuestion;
    onDone: ({ id, value, answer }: { id: number; value: boolean; answer: number }) => void;
}

const TestQuestionSelect: React.FC<QuestionOpenType> = ({ question, onDone }) => {
    const { answer, text, id, explanation, options, image_url } = question;
    const [value, setValue] = useState(0);
    const [done, setDone] = useState(false);

    function handleClick(value: number) {
        if (!done) {
            setDone(true);
            setValue(value);
            onDone({
                id,
                value: answer === value,
                answer: value,
            });
        }
    }

    useEffect(() => {
        setDone(false);
        setValue(0);
    }, [id]);

    function icon(index: number) {
        let icon = 'chevron-right-gray';
        if (done) {
            if (answer === index) {
                icon = 'check-primary';
            } else if (value === index) {
                icon = 'close-alert';
            }
        }
        return icon;
    }

    return (
        <div>
            {!!image_url && (
                <div className="h-text-center h-mb-2">
                    <img src={image_url} alt={text} />
                </div>
            )}
            <h2 className="h-text-center" dangerouslySetInnerHTML={{ __html: text }}></h2>
            <span className="form-label">Vyberte správnou odpověď</span>
            {options?.map((option, index) => (
                <div key={index} className="h-relative h-mb-075">
                    <ButtonSelect
                        onClick={() => handleClick(index)}
                        // isChecked={value === index}
                        className={classes('', {
                            'is-correct': answer === index && done,
                            'is-falsy': done && value === index,
                        })}
                        rightPart={<Icon icon={icon(index)} width=".875rem" height=".875rem" />}
                    >
                        <span>{`${orderLetters[index]}) `}</span>
                        <span dangerouslySetInnerHTML={{ __html: option }}></span>
                    </ButtonSelect>
                    {answer === index && done && !!explanation && (
                        <TestExplanation>
                            <div dangerouslySetInnerHTML={{ __html: explanation }}></div>
                        </TestExplanation>
                    )}
                </div>
            ))}
        </div>
    );
};

export default TestQuestionSelect;
