import './FormInput.scss';
import React from 'react';
import { classes } from '../../../services/utils';
import { FieldMetaProps } from 'formik';

interface PropsType {
    className?: string;
    meta: FieldMetaProps<unknown>;
    note?: string | null;
}

const FormInputWrapper: React.FC<PropsType> = (props) => {
    const { className, meta, note } = props;
    let footnote;

    if (meta.touched && meta.error) {
        footnote = <div className="form-error-messages">{meta.error}</div>;
    } else if (note) {
        footnote = <div className="form-input-note">{note}</div>;
    } else if (note === null) {
        footnote = null;
    } else {
        footnote = <div className="form-input-note">&nbsp;</div>;
    }
    return (
        <div
            className={classes('form-field-wrapper', {
                'is-invalid': meta.touched && !!meta.error,
                [className || '']: !!className,
            })}
        >
            {props.children}
            <div className="form-input-footnote">
                {footnote}
            </div>
        </div>
    );
};

export default FormInputWrapper;
