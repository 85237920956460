import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Button from '../AppButton/Button';
import FormInputText from './Inputs/FormInputText';
import { LoginFormType } from './form-types';
import { transformErrors } from '../../services/utils';
import { useHistory } from 'react-router-dom';
import { login } from '../../moduls/auth';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { routesURLs } from '../../Routes';

const initialValues = {
    username: '',
    password: '',
};

const attributes = {
    username: {
        placeholder: 'Vyplňte..',
        label: 'Jméno',
    },
    password: {
        placeholder: 'Vyplňte..',
        label: 'Heslo',
        password: true,
    },
};

const LoginForm: React.FC<{}> = () => {
    const { state, dispatch } = useGlobalState();
    const history = useHistory();
    const onSubmit = async (values: LoginFormType, { setErrors, resetForm }: FormikHelpers<LoginFormType>) => {
        return login(values)
            .then((_) => {
                resetForm();
                if (state.prevRoute && state.prevRoute !== '/') {
                    const prevRoute = state.prevRoute;
                    dispatch({ type: GlobalTypes.SetPrevRoute, route: '/' });
                    history.push(prevRoute);
                } else {
                    history.push(routesURLs.homepage);
                }
            })
            .catch((error) => setErrors(transformErrors(error)));
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Povinné pole'),
        password: Yup.string().required('Povinné pole'),
    });

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ isValid, dirty, isSubmitting }) => (
                <Form>
                    <FormInputText name="username" attributes={attributes.username} />
                    <FormInputText name="password" attributes={attributes.password} />
                    <div className="h-text-right">
                        <button
                            type="button"
                            className="link"
                            onClick={() => dispatch({ type: GlobalTypes.ToggleModal, modal: 'ModalForgottenPassword' })}
                        >
                            Zapomenuté heslo
                        </button>
                    </div>
                    <div className="h-text-center h-mt-1">
                        <Button type="submit" color="primary" disabled={!(isValid && dirty)} loading={isSubmitting}>
                            Přihlásit se
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
