import React from 'react';
import './Label.scss';
import { classes } from '../../services/utils';

interface LabelProps {
    htmlFor?: string;
    className?: string;
}

const Label: React.FC<LabelProps> = (props) => {
    const { htmlFor, className } = props;
    return htmlFor ? (
        <label htmlFor={htmlFor} className={classes('', { [className || '']: !!className })}>
            {props.children}
        </label>
    ) : (
        <div className={classes('label', { [className || '']: !!className })}>{props.children}</div>
    );
};

export default Label;
