import React from 'react';
import './ErrorBox.scss';
import { FormikErrors } from 'formik';
import { classes } from '../../services/utils';

interface ErrorBoxType<T> {
    errors: FormikErrors<T>;
    submitCount?: number;
    className?: string;
}

const ErrorBox = <T extends { [key: string]: any }>(props: ErrorBoxType<T>) => {
    const { errors, submitCount = 1, className } = props;
    const errorKeys = Object.keys(errors);

    if (!errorKeys.length || submitCount < 1) return null;

    return (
        <div className={classes('error-box', { [className || '']: !!className })}>
            <ul className="h-mb-0">
                {errorKeys.map((key) => (
                    <li key={key}>{errors[key]}</li>
                ))}
            </ul>
        </div>
    );
};

export default ErrorBox;
