import React, { useRef, useState } from 'react';
import { classes } from '../../services/utils';
import Button from '../AppButton/Button';
import Label from '../AppLabel/Label';
import './OverviewItem.scss';

interface IOverviewItem {
    icon: string;
    videos?: string;
    totalTests?: string;
    firstAttampt?: string;
    bestAttampt?: string;
    totalLessons?: string;
    recomendation?: string;
}

const OverviewItem: React.FC<IOverviewItem> = ({
    icon,
    videos,
    totalTests,
    firstAttampt,
    bestAttampt,
    totalLessons,
    recomendation,
    children,
}) => {
    const [open, setOpen] = useState(false);
    const wrapper = useRef<HTMLDivElement>(null);

    return (
        <div className={classes('overview-item-wrapper', { 'is-open': open })}>
            <div className="overview-item-title">
                <i className={'overview-item-title-icon ' + icon} />
                <div className="overview-item-labels">
                    {videos && (
                        <div className="overview-item-label">
                            <span>Celkem sledováním výukových videí stráveno</span>
                            <div>{videos}</div>
                        </div>
                    )}
                    {totalTests && (
                        <div className="overview-item-label">
                            <span>Celkem vypracovaných cvičení</span>
                            <div>{totalTests}</div>
                        </div>
                    )}
                    {firstAttampt && (
                        <div className="overview-item-label">
                            <span>První pokus</span>
                            <div>{firstAttampt}</div>
                        </div>
                    )}
                    {bestAttampt && (
                        <div className="overview-item-label">
                            <span>Nejlepší výsledek</span>
                            <div>{bestAttampt}</div>
                        </div>
                    )}
                    {totalLessons && (
                        <div className="overview-item-label">
                            <span>Splněné lekce v kurzu</span>
                            <div>{totalLessons}</div>
                        </div>
                    )}
                </div>
                {children && (
                    <Button onClick={() => setOpen(!open)} color="transparent">
                        Více informací <i className={`icon-chevron-down-primary`}></i>
                    </Button>
                )}
            </div>
            <div className="overview-item-content" style={{ height: open ? wrapper.current!.clientHeight : 0 }}>
                <div ref={wrapper}>{children}</div>
            </div>
            {recomendation && (
                <div className="overview-item-footer">
                    <div className="h-background-lightest-gray h-p-075 h-border-radius">
                        <Label>Doporučujeme si procvičit lekci</Label>
                        <div>{recomendation}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OverviewItem;
