import React from 'react';
import './Dashboard.scss';
import { useQuery } from 'react-query';
import { getMyCourses, getPendingPayments, repeatOnlineCoursePayment, repeatPersonalCoursePayment } from '../../moduls/courses';
import CourseCard from '../../components/CourseCard/CourseCard';

import onlineCoursesImage from '../../assets/images/icons/ic-online-lessons.svg';
import personCoursesImage from '../../assets/images/icons/ic-personal-lessons.svg';
import CTACard from '../../components/CTACard/CTACard';
import { routesURLs } from '../../Routes';
import { FreeOnlineCourse, OnlineCourseType, PersonalCourseType } from '../../services/types';
import CourseCardOnline from '../../components/CourseCard/CourseCardOnline';
import StaticNotification from '../../components/TheNotification/StaticNotification';
import CourseCardPersonal from '../../components/CourseCard/CourseCardPersonal';
import { useHistory } from 'react-router-dom';
import FreeCourseBanner from '../../components/FreeCourseBanner/FreeCourseBanner';
import { getFreeLessons } from '../../moduls/online-courses';
import SkeletonCourseCard from '../../components/Skeletons/SkeletonCourseCard';
import { sessionStore } from '../../services/utils';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { CoursesTypes, useCoursesState } from '../../contexts/courses';

const Dashboard = () => {
    const { data: freeCourses = [], isFetched: freeCoursesFetched } = useQuery<FreeOnlineCourse[]>(
        'free-courses',
        getFreeLessons
    );
    const { data: courses, isFetched: coursesFetched } = useQuery<any>('my-courses', getMyCourses);
    const { data: pendingPayments } = useQuery<boolean>('pending-payments', getPendingPayments);
    const { state, dispatch } = useGlobalState();
    const { state: coursesState, dispatch: dispatchCourses} = useCoursesState();
    const history = useHistory();

    return (
        <>
            {pendingPayments && (
                <StaticNotification
                    color="info"
                    text="Momentálně čekáme až k nám dorazí Vaše platba, po přijetí na náš účet budete mít přístupné zakoupené kurzy."
                />
            )}
            <div className="main-layout h-pt-175">
                <div className="container">
                    {!!freeCourses.length && freeCoursesFetched && <FreeCourseBanner className="h-mb-25" courses={courses} />}
                    {!coursesFetched && (
                        <>
                            <div className="skeleton-title" style={{ width: '10rem' }}></div>
                            <hr />
                            <div className="container-row">
                                <div className="col-md-6 col-lg-3">
                                    <SkeletonCourseCard color="primary" />
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <SkeletonCourseCard color="primary" />
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <SkeletonCourseCard color="primary" />
                                </div>
                            </div>
                        </>
                    )}
                    {!!courses?.online_courses.length && (
                        <>
                            <h3 className="icon-title icon-camera-gray">Kurzy on-line</h3>
                            <hr />
                            <div className="container-row h-mb-25">
                                {!coursesFetched && (
                                    <div className="col-md-6 col-lg-3">
                                        <SkeletonCourseCard />
                                    </div>
                                )}
                                {courses?.online_courses.map((course: OnlineCourseType) => {
                                    const courseCardProps = course.is_paid
                                        ? {
                                              onClick: () => history.push(`${routesURLs.onlineCourse}/${course.id}`),
                                          }
                                        : {
                                            disabled: true,
                                            paymentCanBeRepeated: course.payment_can_be_repeated,
                                            repeatPayment: () => {
                                                repeatOnlineCoursePayment(course.id)
                                                    .then((res) => {
                                                        const redirectUrl = res?.data?.data?.redirect_url;
                                                        if (redirectUrl) {
                                                            sessionStore.set('onsite_courses', []);
                                                            sessionStore.set('online_courses', []);
                                                            window.location.replace(redirectUrl);
                                                        } else {
                                                            window.location.replace(routesURLs.dashboard);
                                                            dispatch({ type: GlobalTypes.ToggleLoader });
                                                            dispatchCourses({ type: CoursesTypes.ResetSavedCourses });
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        dispatch({ type: GlobalTypes.ToggleLoader });
                                                        dispatch({
                                                            type: GlobalTypes.ToggleNotification,
                                                            style: 'error',
                                                            text: error?.response?.data?.message,
                                                        });
                                                    });
                                            },
                                        };

                                    return (
                                        <div key={course.id} className="col-md-6 col-lg-3">
                                            <CourseCard
                                                tag="On-line"
                                                color="primary"
                                                title={course.subject.name}
                                                image={onlineCoursesImage}
                                                removeButton={false}
                                                {...courseCardProps}
                                            >
                                                <CourseCardOnline
                                                    classLesson={course.lesson_class.name}
                                                    expiration={course.expired_at}
                                                    lessonsLength={course.lessons_count}
                                                    name={course.name}
                                                />
                                            </CourseCard>
                                        </div>
                                    );
                                })}

                                <div className="col-md-6 col-lg-3 study-video">
                                    <div className="video-card">
                                        <h2>Video návod</h2>
                                        <p>
                                            jak nejlépe sledovat on-line kurzy, kde co najdu, nastavení, notifikace a mnoho dalšího ...
                                        </p>
                                        <iframe
                                            className="study-video-player"
                                            title="Jak studovat online"
                                            src="https://player.vimeo.com/video/734357714?h=dbd5d16603"
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            allowFullScreen
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <CTACard
                                        route={routesURLs.onlineCourses}
                                        color="primary"
                                        title="kupte si snadno a rychle další on-line kurzy"
                                        text="S našimi kurzy Vás ve škole nic nepřekvapí."
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {!!courses?.onsite_courses.length && (
                        <>
                            <h3 className="icon-title icon-persons-gray">Přípravné kurzy Na místě</h3>
                            <hr />
                            <div className="container-row h-mb-25">
                                {!coursesFetched && (
                                    <div className="col-md-6 col-lg-3">
                                        <SkeletonCourseCard color="secondary" />
                                    </div>
                                )}
                                {courses?.onsite_courses.map((course: PersonalCourseType) => {
                                    const courseCardProps = course.terms[0].is_paid
                                        ? {
                                              onClick: () =>
                                                  history.push(`${routesURLs.personalCourse}/${course.terms[0].id}`),
                                          }
                                        : {
                                            disabled: true,
                                            paymentCanBeRepeated: course.terms[0].payment_can_be_repeated,
                                            repeatPayment: () => {
                                                repeatPersonalCoursePayment(course.terms[0].id)
                                                    .then((res) => {
                                                        const redirectUrl = res?.data?.data?.redirect_url;
                                                        if (redirectUrl) {
                                                            sessionStore.set('onsite_courses', []);
                                                            sessionStore.set('online_courses', []);
                                                            window.location.replace(redirectUrl);
                                                        } else {
                                                            window.location.replace(routesURLs.dashboard);
                                                            dispatch({ type: GlobalTypes.ToggleLoader });
                                                            dispatchCourses({ type: CoursesTypes.ResetSavedCourses });
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        dispatch({ type: GlobalTypes.ToggleLoader });
                                                        dispatch({
                                                            type: GlobalTypes.ToggleNotification,
                                                            style: 'error',
                                                            text: error?.response?.data?.message,
                                                        });
                                                    });
                                            },
                                        };

                                    return (
                                        <div key={course.name} className="col-md-6 col-lg-3">
                                            <CourseCard
                                                tag="Na místě"
                                                color="secondary"
                                                image={personCoursesImage}
                                                removeButton={false}
                                                title={course.name}
                                                {...courseCardProps}
                                            >
                                                <CourseCardPersonal
                                                    lessonCount={course.terms[0].lessons_count}
                                                    city={course.terms[0].location.city.name}
                                                    street={course.terms[0].location.street_name}
                                                    streetNumber={course.terms[0].location.street_number}
                                                    placeName={course.terms[0].location.name}
                                                    course={course.name}
                                                    from={course.terms[0].date_from}
                                                    to={course.terms[0].date_to}
                                                    lector={course.terms[0].lector.name}
                                                    lessonDate={course.terms[0].lessons[0]?.date}
                                                />
                                            </CourseCard>
                                        </div>
                                    );
                                })}
                                <div className="col-md-6 col-lg-3">
                                    <CTACard
                                        route={routesURLs.personalCourses}
                                        color="secondary"
                                        title="Kupte si snadno A rychle další Přípravné kurzy"
                                        text="S našimi kurzy vás u přijímaček nic nepřekvapí"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {/* <pre>{JSON.stringify(courses, null, 2)}</pre> */}
            </div>
        </>
    );
};

export default Dashboard;
