import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useCoursesState } from '../../contexts/courses';
import { useGlobalState } from '../../contexts/global';
import { getPersonalCoursesPackages } from '../../moduls/personal-courses';
import { PersonalCoursePackageType } from '../../services/types';
import { classes, formateNumber } from '../../services/utils';

const FinalPrice: React.FC<{ setPrice: (price: number) => void }> = ({ setPrice }) => {
    const { state: globalState } = useGlobalState();
    const { state } = useCoursesState();
    const { data: coursesPackages = [] } = useQuery<PersonalCoursePackageType[]>(
        'personal-courses-packages',
        getPersonalCoursesPackages,
        {
            staleTime: Infinity,
        }
    );

    const absoluteDiscount = useMemo(() => {
        const totalOnlinePrice = state.savedOnlineCourses.reduce((sum, item) => sum + item.course.price, 0);
        if (globalState.discount.one_time) {
            return (state.savedOnlineCourses?.[0]?.course.price * globalState.discount.discount_amount) / 100;
        } else {
            return (totalOnlinePrice * globalState.discount.discount_amount) / 100;
        }
    }, [globalState.discount, state.savedOnlineCourses]);

    const sumedPrice = useMemo(() => {
        const coursesInPackage: number[] = [];
        const coursesPriceInPackage = coursesPackages
            .filter(
                (packageItem) =>
                    state.savedPersonalCourses.filter((course) => course.term.type.id === packageItem.type.id)
                        .length === packageItem.quantity
            )
            .map((packageItem) => ({
                price: packageItem.price,
                terms: state.savedPersonalCourses
                    .filter((course) => course.term.type.id === packageItem.type.id)
                    .map((course) => {
                        coursesInPackage.push(course.id);
                        return course.id;
                    }),
            }));

        return (
            state.savedPersonalCourses
                .filter((course) => coursesInPackage.indexOf(course.term.id) === -1)
                .reduce((sum, item) => sum + item.term.price, 0) +
            state.savedOnlineCourses.reduce((sum, item) => sum + item.course.price, 0) +
            coursesPriceInPackage.reduce((sum, item) => sum + item.price, 0)
        );
    }, [state.savedPersonalCourses, state.savedOnlineCourses, coursesPackages]);

    useEffect(() => {
        setPrice(sumedPrice - absoluteDiscount);
        // eslint-disable-next-line
    }, [sumedPrice, absoluteDiscount]);

    return (
        <div className={classes('label-value personal-courses-form-summary-price h-text-nowrap')}>
            <div className="label-value-label">Celkem zaplatíte</div>
            <div className="label-value-value">
                {`${formateNumber(sumedPrice - absoluteDiscount, 2)} Kč`}
                {absoluteDiscount > 0 && (
                    <span className="h-text-regular h-color-medium-gray">{` (sleva  ${formateNumber(
                        absoluteDiscount,
                        2
                    )} Kč)`}</span>
                )}
            </div>
        </div>
    );
};

export default FinalPrice;
