import React from 'react';
import './Skeleton.scss';
import '../CourseCard/CourseCard.scss';

const SkeletonCourseCard = ({ color = 'primary' }: { color?: 'primary' | 'secondary' }) => {
    return (
        <div className={`course-card is-${color} skeleton-loader`}>
            <div className="course-card-image"></div>
            <div className="course-card-content">
                <div className="skeleton-title"></div>
                <div className="skeleton-text" style={{ width: '40%' }}></div>
                <div className="skeleton-text"></div>
                <div className="skeleton-text" style={{ width: '50%' }}></div>
            </div>
        </div>
    );
};

export default SkeletonCourseCard;
