import React, { useState } from 'react';
import './Topbar.scss';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from '../AppButton/Button';
import IconButton from '../AppIconButton/IconButton';
import UserDropdown from '../TheUserDropdown/UserDropdown';
import { routesURLs } from '../../Routes';

import logo from '../../assets/images/brands/logo-horizontal.png';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { CoursesTypes, useCoursesState } from '../../contexts/courses';
import TopbarProgress from '../TheTopbarProgress/TopbarProgress';
import { classes, getCookie, sessionStore } from '../../services/utils';
import Modal from '../TheModal/Modal';
import Basket from "../Basket/Basket";

export type TopbarType = 'personal' | 'course' | 'freeCourse' | 'login' | 'test';

interface TopbarPropType {
    type?: TopbarType;
    title?: string;
    score?: number;
    questions?: number;
    currentQuestion?: number;
    test?: boolean;
}

const Topbar: React.FC<TopbarPropType> = (props) => {
    const { type, title, score, questions, currentQuestion, test } = props;
    const { state, dispatch } = useGlobalState();
    const { dispatch: dispatchCourses } = useCoursesState();
    const params = useParams<{ courseId: string; lessonId: string; testId: string }>();
    const [leaveCourseModal, setLeaveCourseModal] = useState(false);
    const history = useHistory();
    let content;

    function closeBuyingProcess() {
        dispatchCourses({ type: CoursesTypes.ResetSavedCourses });
        history.replace(routesURLs.homepage);
    }
    function openClosingModal() {
        if (!!sessionStore.get('tests')?.[params.testId]?.answers) {
            setLeaveCourseModal(true);
        } else {
            closeTest();
        }
    }
    function closeTest() {
        sessionStore.set('tests', {});
        document.body.style.overflow = 'visible';
        setLeaveCourseModal(false);
        history.push(`${routesURLs.onlineCourse}/${params.courseId}/${params.lessonId}`);
    }

    switch (type) {
        case 'personal':
            content = (
                <>
                    <div className="topbar-tagline">{title || ''}</div>
                    <Button
                        onClick={() => dispatch({ type: GlobalTypes.ToggleModal, modal: 'ModalChooseCourse' })}
                        icon="shopping-cart-primary"
                        color="transparent"
                        className="topbar-purchase-button is-small"
                    >
                        Zakoupit kurz
                    </Button>
                    <Basket />
                    <UserDropdown />
                </>
            );
            break;
        case 'course':
            content = (
                <>
                    <div className="topbar-tagline">Zakoupení kurzu</div>
                    <Basket />
                    <IconButton icon="close-gray" onClick={closeBuyingProcess} title="Zrušit nákupní proces" />
                </>
            );
            break;
        case 'freeCourse':
            content = (
                <>
                    <div className="topbar-tagline">Lekce ZDARMA</div>
                    <IconButton icon="close-gray" onClick={closeBuyingProcess} title="Zrušit nákupní proces" />
                </>
            );
            break;
        case 'login':
            content = <div className="topbar-tagline">Přihlášení</div>;
            break;
        case 'test':
            content = (
                <>
                    <div className="topbar-test-wrapper">
                        <div className="topbar-tagline">{title}</div>
                        <TopbarProgress current={currentQuestion || 1} total={questions || 0} />
                        <div className="h-vertical-center">
                            <span className="topbar-score">
                                Skóre: <strong>{score}</strong>
                            </span>
                        </div>
                    </div>
                    <IconButton icon="close-gray" onClick={openClosingModal} title="Zrušit test" />
                    <Modal isOpen={leaveCourseModal} close={() => setLeaveCourseModal(false)}>
                        <div className="modal-content h-text-center h-py-4">
                            <h1 className="h-mb-05">Opravdu končíte?</h1>
                            <p>
                                Chystáte se ukončit test. Všechny nedokončené otázky budou vyhodnoceny jako{' '}
                                <strong>chybné</strong>.
                            </p>
                            <Button
                                color="primary"
                                onClick={() => setLeaveCourseModal(false)}
                                className="h-mt-1 h-mx-05"
                            >
                                Zpět
                            </Button>
                            <Button
                                color="alert"
                                onClick={closeTest}
                                className="h-mt-1 h-mx-05"
                                loading={state.loader === 'completeLessonTest'}
                            >
                                Ukončit test
                            </Button>
                        </div>
                    </Modal>
                </>
            );
            break;
    }

    return (
        <div className={classes('topbar', { 'is-test': !!test })}>
            <div className="topbar-wrapper container expanded">
                {type === 'test' ? (
                    <button onClick={openClosingModal} className="topbar-logo">
                        <img src={logo} alt="Logo" />
                    </button>
                ) : getCookie('jid') ? (
                    <Link to={routesURLs.homepage} className="topbar-logo">
                        <img src={logo} alt="Logo" />
                    </Link>
                ) : (
                    <a href="https://doucovani-dohlavy.cz/" className="topbar-logo" target="_blank" rel="noreferrer">
                        <img src={logo} alt="Logo" />
                    </a>
                )}
                {content}
            </div>
        </div>
    );
};

export default Topbar;
