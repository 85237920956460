import React from 'react';
import './Footer.scss';

import IconButton from '../AppIconButton/IconButton';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-wrapper container expanded">
                <div className="h-text-nowrap h-text-size-small h-color-medium-gray">© 2020 Dohlavy s.r.o.</div>
                <ul className="footer-menu is-pure-list">
                    <li>
                        <a href="/docs/PODMINKY.pdf" rel="noopener noreferrer" target="_blank">
                            Obchodní podmínky
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://doucovani-dohlavy.cz/zasady-ochrany-osobnich-udaju/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Informace o ochraně osobních údajů
                        </a>
                    </li>
                    <li>
                        <a
                            href="/docs/Doprava_a_Platba.pdf"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Doprava a platba
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://doucovani-dohlavy.cz/kontakty-pripravne-kurzy/#contacts"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Kontaktujte nás
                        </a>
                    </li>
                </ul>
                <div className="h-text-nowrap">
                    <IconButton icon="facebook-gray" href="/" title="Facebook" />
                    <IconButton icon="instagram-gray" href="/" title="Instagram" />
                </div>
            </div>
        </div>
    );
};

export default Footer;
