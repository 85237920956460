import { format } from 'date-fns';
import { cs } from 'date-fns/locale';
import React from 'react';
import { formateNumber, courseTermDescription } from '../../services/utils';

interface CourseCardPersonalPropsType {
    course: string;
    lessonCount: number;
    price?: number;
    from: string;
    to: string;
    city: string;
    street: string;
    streetNumber: string;
    placeName?: string;
    lector: string;
    lessonDate: string;
}

const CourseCardPersonal: React.FC<CourseCardPersonalPropsType> = (props) => {
    const { lessonCount, city, course, from, lector, price, street, streetNumber, placeName, to, lessonDate } = props;

    return (
        <>
            <h2 className="h-mb-05">{course}</h2>
            <ul className="is-small h-mb-0">
                <li>{`Počet lekcí: ${lessonCount}`}</li>
                {!!price && <li>{`Cena: ${formateNumber(price, 0)} Kč`}</li>}
                <li>
                    {courseTermDescription({
                        dateFrom: from,
                        dateTo: to,
                    })}
                </li>
                <li>
                    {lessonDate
                        ? `${format(new Date(lessonDate), 'EEEE · p', {
                              locale: cs,
                          })}`
                        : ''}
                </li>
                <li>{`${city} · ${street} ${streetNumber}${placeName ? ' · ' + placeName : ''}`}</li>
                <li>{`Lektor: ${lector}`}</li>
            </ul>
        </>
    );
};

export default CourseCardPersonal;
