import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

function useScrollMemory() {
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        if (history.action !== 'POP') {
            window.scrollTo(0, 0);
        }
        // eslint-disable-next-line
    }, [location.pathname]);
}

export default useScrollMemory;
