import { format } from 'date-fns';
import { cs } from 'date-fns/locale';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CoursesTypes, useCoursesState } from '../../contexts/courses';
import { routesURLs } from '../../Routes';
import { SelectedPackageCourseType, PersonalTerm } from '../../services/types';
import { courseTermDescription, getCookie } from '../../services/utils';
import Button from '../AppButton/Button';
import CheckboxItem from '../AppCheckboxItem/CheckboxItem';
import LabelValue from '../AppLabelValue/LabelValue';

const ModalChoosePackageCourse: React.FC<{ data: SelectedPackageCourseType }> = ({ data }) => {
    const { dispatch } = useCoursesState();
    const [selectedTerms, setSelectedTerms] = useState<PersonalTerm[]>([]);
    const [name, setName] = useState('');
    const history = useHistory();

    const saveSelectedCourse = () => {
        data.courses.forEach((course, index) => {
            dispatch({
                type: CoursesTypes.SaveCourse,
                course: {
                    course: course.name,
                    id: selectedTerms[index].id,
                    student_name: name,
                    term: selectedTerms[index],
                },
                courseType: 'personal',
            });
        });
        if (getCookie('jid')) {
            history.push(routesURLs.selectMore);
        } else {
            history.push(routesURLs.registration);
        }
        document.body.style.overflow = 'visible';
    };

    return (
        <div className="modal-content h-background-body" style={{ width: '50rem' }}>
            <h1 className="h2 h-text-center h-mb-175">Vyberte termíny pro kurzy {data.type.name}</h1>
            <div className="container-row">
                {data.courses.map((course, index) => (
                    <div key={course.name} className="col-sm-6">
                        <LabelValue
                            className="h-mb-15"
                            label={course.name}
                            value={
                                selectedTerms[index]
                                    ? format(new Date(selectedTerms[index].lessons[0].date), 'EEEE · p', {
                                          locale: cs,
                                      })
                                    : 'Nevybráno'
                            }
                        />
                    </div>
                ))}
            </div>
            <hr className="h-mt-0 h-mb-2" />
            <div className="container">
                {selectedTerms.length < data.courses.length ? (
                    <>
                        <h2 className="h-mb-05">{data.courses[selectedTerms.length].name}</h2>
                        {data.courses[selectedTerms.length].terms.map((term) => (
                            <button
                                key={term.id}
                                className="h-pure-button h-mb-05"
                                onClick={() => setSelectedTerms([...selectedTerms, term])}
                            >
                                <CheckboxItem
                                    key={term.id}
                                    text={courseTermDescription({
                                        dateFrom: term.date_from,
                                        dateTo: term.date_to,
                                        lessonDate: term.lessons[0].date,
                                    })}
                                    note={`(Kapacita: ${term.capacity})`}
                                />
                            </button>
                        ))}
                    </>
                ) : (
                    <>
                        <div className="h-relative">
                            <label htmlFor="choose-package-name" className="form-label">
                                Jméno žáka
                            </label>
                            <input
                                id="choose-package-name"
                                type="text"
                                placeholder="Napište odpověď.."
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="form-input-footnote">
                            <div className="form-input-note">Vyplňuje se kvůli docházce</div>
                        </div>
                        <div className="h-text-center h-mt-1">
                            <Button onClick={saveSelectedCourse} color="primary" disabled={!name}>
                                Pokračovat
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ModalChoosePackageCourse;
