import React from 'react';
import { pad } from '../../services/utils';
import './TopbarProgress.scss';

const TopbarProgress: React.FC<{ current: number; total: number }> = ({ current, total }) => {
    return (
        <div className="topbar-progress">
            <span className="topbar-progress-value">{pad(current)}</span>
            <div className="topbar-progress-line">
                <span style={{ width: `${(current / total) * 100}%` }}></span>
            </div>
            <span className="topbar-progress-value">{pad(total)}</span>
        </div>
    );
};

export default TopbarProgress;
