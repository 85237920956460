import React from 'react';
import './Loader.scss';

const Loader = () => {
    return (
        <div className="loader">
            <div className="loader-image"></div>
            <div className="loader-text">Načítám..</div>
        </div>
    );
};

export default Loader;
