import React from 'react';
import './Profile.scss';
import MainLayout from '../../components/TheMainLayout/MainLayout';
import BackButton from '../../components/AppBackButton/BackButton';
import LabelValue from '../../components/AppLabelValue/LabelValue';
import { Link } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import { useQuery } from 'react-query';
import { ProfileType } from '../../services/types';
import { getProfile } from '../../moduls/profile';

const Profile = () => {
    const { data: profile } = useQuery<ProfileType>('profile', getProfile, {
        staleTime: Infinity,
        forceFetchOnMount: true
    });

    return (
        <>
            <MainLayout>
                <div className="page-row">
                    <div className="h-text-center">
                        <div className="profile-avatar"></div>
                        <h1 className="profile-name">{profile?.name}</h1>
                        <p>{profile?.username}</p>
                    </div>
                    <hr className="h-my-2" />
                    <div className="container-row">
                        <div className="col-md-4">
                            <LabelValue label="E-mail" value={profile?.email} />
                        </div>
                        <div className="col-md-4">
                            <LabelValue label="Telefon" value={profile?.phone_number} />
                        </div>
                        <div className="col-md-4">
                            <LabelValue label="Základní škola" value={profile?.school} />
                        </div>
                    </div>
                    <hr className="h-my-2" />
                    <div className="h-text-center">
                        <Link to={routesURLs.profileEdit} className="button is-primary h-display-inline-block">
                            Nastavení profilu
                        </Link>
                    </div>
                </div>
            </MainLayout>
            <BackButton />
        </>
    );
};

export default Profile;
