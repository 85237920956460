import React, { useReducer, Dispatch } from 'react';
import { ModalsType } from '../components/TheModal/ModalWrapper';
import { NotificationStyle } from '../services/types';

interface State {
    modalComponent: string;
    modalProps: any;
    loader: string;
    discount: {
        code: string;
        discount_amount: number;
        one_time: 0 | 1;
    };
    prevRoute: string;
    notification: {
        text: string;
        style?: NotificationStyle;
        isTest?: boolean;
    };
}

enum GlobalTypes {
    ToggleModal = 'TOGGLE_MODAL',
    ToggleLoader = 'TOGGLE_LOADER',
    ToggleNotification = 'TOGGLE_NOTIFICATION',
    SetPrevRoute = 'SET_PREV_ROUTE',
    SetDiscount = 'SET_DISCOUNT',
}

type Actions =
    | { type: GlobalTypes.ToggleModal; modal?: ModalsType, props?: any}
    | { type: GlobalTypes.ToggleLoader; loader?: string }
    | { type: GlobalTypes.ToggleNotification; text?: string; style?: NotificationStyle; isTest?: boolean }
    | { type: GlobalTypes.SetPrevRoute; route: string }
    | { type: GlobalTypes.SetDiscount; discount: State['discount'] | null };

const initialState = {
    modalComponent: '',
    modalProps: {},
    loader: '',
    discount: {
        code: '',
        discount_amount: 0,
        one_time: 0 as 0 | 1,
    },
    prevRoute: '/',
    notification: {
        text: '',
        style: 'info' as NotificationStyle,
    },
};

function reducer(state: State, action: Actions) {
    switch (action.type) {
        case GlobalTypes.ToggleModal:
            return { ...state, modalComponent: action.modal || '', modalProps: action.props || {}};
        case GlobalTypes.ToggleLoader:
            return { ...state, loader: action.loader || '' };
        case GlobalTypes.SetPrevRoute:
            return { ...state, prevRoute: action.route };
        case GlobalTypes.SetDiscount:
            return { ...state, discount: action.discount || initialState.discount };
        case GlobalTypes.ToggleNotification:
            return {
                ...state,
                notification: {
                    text: action.text || '',
                    style: action.style || state.notification.style,
                    isTest: !!action.isTest
                },
            };
        default:
            throw new Error('This action type is not exist in Global context');
    }
}

const GlobalProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

const AppContext = React.createContext<{
    state: State;
    dispatch: Dispatch<Actions>;
}>({
    state: initialState,
    dispatch: () => null,
});

function useGlobalState() {
    const context = React.useContext(AppContext);
    if (context === undefined) {
        throw new Error('useGlobalState must be used within a GlobalProvider');
    }
    return context;
}

export { GlobalProvider, useGlobalState, GlobalTypes };
