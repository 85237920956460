import React from 'react';
import './Notification.scss';
import { classes } from '../../services/utils';
import { NotificationStyle } from '../../services/types';

const StaticNotification: React.FC<{ text: string; color: NotificationStyle }> = ({ text, color }) => {
    return (
        <div
            className={classes('notification-wrapper is-static', {
                'is-open': !!text,
                ['is-' + color]: !!color,
            })}
        >
            <div className="notification">
                <div className="container">
                    <div className={`notification-text icon-${color}-white`}>{text}</div>
                </div>
            </div>
        </div>
    );
};

export default StaticNotification;
