import React, { useEffect } from 'react';
import './Notification.scss';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { classes } from '../../services/utils';
import IconButton from '../AppIconButton/IconButton';

const Notification: React.FC = () => {
    const { state, dispatch } = useGlobalState();
    const { text, style, isTest } = state.notification;

    useEffect(() => {
        if (text) {
            setTimeout(() => {
                closeNotification();
            }, 7000);
        }
        // eslint-disable-next-line
    }, [text]);

    function closeNotification() {
        dispatch({ type: GlobalTypes.ToggleNotification });
    }

    return (
        <div
            className={classes('notification-wrapper', {
                'is-open': !!text,
                ['is-' + style]: !!style,
            })}
            style={{zIndex: 11}}
        >
            <div className="notification">
                <div className="container">
                    <div className={`notification-text icon-${style}-white`}>{text}</div>
                    <IconButton
                        icon="close-white"
                        onClick={closeNotification}
                        title="Zavřít notifikaci"
                        className="notification-close"
                    />
                </div>
                <div className={classes('notification-timeout', { 'is-open': !!text })}></div>
            </div>
        </div>
    );
};

export default Notification;
