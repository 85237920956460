import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './services/polyfills';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App';
import { GlobalProvider } from './contexts/global';
import { CoursesProvider } from './contexts/courses';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <CoursesProvider>
                <GlobalProvider>
                    <App />
                </GlobalProvider>
            </CoursesProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
