import React from 'react'
import { classes } from '../../services/utils';
import './Tag.scss';

interface TagProps {
    className?: string;
    color?: 'primary' | 'secondary' | 'tertiary' | 'gray' | 'alert';
}

const Tag: React.FC<TagProps> = (props) => {
    const { className, color } = props;

    const addClasses = {
        [className || '']: !!className,
        [`is-${color}`]: !!color,
    };

    return <span className={classes('tag', addClasses)}>{props.children}</span>;
}

export default Tag
