import React, { useState, useRef, useEffect, useCallback } from 'react';
import FormInputWrapper from './FormInputWrapper';
import { useField } from 'formik';
import { classes } from '../../../services/utils';
import ClickOutside from '../../ClickOutside/ClickOutside';

type Value = string | number;

interface InputAttributes {
    label?: string | null;
    options: { value: Value; label: string }[];
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    note?: string;
    icon?: string;
}

const FormInputSelect: React.FC<{ attributes: InputAttributes; name: string; onValueChange?: (value: Value) => void }> = (props) => {
    const [, meta, helpers] = useField(props);
    const { label, className, options, placeholder, note, icon } = props.attributes;
    const [open, setOpen] = useState(false);
    const [onTop, setOnTop] = useState(false);
    const [filter, setFilter] = useState('');
    const [activeOption, setActiveOption] = useState(0);
    const { value } = meta;
    const { setValue } = helpers;
    const input = useRef<HTMLInputElement>(null);
    const select = useRef<HTMLDivElement>(null);

    const filteredOptions = filter
        ? options.filter((option) => option.label.toLowerCase().includes(filter.toLowerCase()))
        : options;

    useEffect(() => {
        if (open && input.current !== null) {
            setFilter('');
            input.current.focus();
        }
    }, [open]);

    const handleOpen = useCallback((open: boolean) => {
        if (open && select.current !== null) {
            const inputPosition = (select.current.getBoundingClientRect().top - window.innerHeight) * -1;
            if (inputPosition > 0 && inputPosition < 350) setOnTop(true);
        } else {
            setOnTop(false);
        }
        setOpen(open);
    }, []);

    useEffect(() => {
        setActiveOption(0);
    }, [filter]);

    // function resetValue() {
    //     setValue('');
    //     setFilter('');
    //     setActiveOption(0);
    //     if (open && input.current !== null) input.current.focus();
    // }

    function selectValue(value: Value) {
        if (props.onValueChange) props.onValueChange(value);
        setValue(value);
        handleOpen(false);
    }

    function handleKeypresses(e: React.KeyboardEvent<HTMLInputElement>) {
        e = e || window.event;
        if (e.keyCode === 38) {
            setActiveOption(activeOption === 0 ? filteredOptions.length - 1 : activeOption - 1);
        } else if (e.keyCode === 40) {
            setActiveOption(filteredOptions.length > activeOption + 1 ? activeOption + 1 : 0);
        } else if (e.keyCode === 13) {
            e.preventDefault();
            selectValue(filteredOptions[activeOption].value);
        } else if (e.keyCode === 27) {
            handleOpen(false);
        }
    }

    return (
        <FormInputWrapper className={className} meta={meta} note={note}>
            {label !== null && (
                <label className="form-label" onClick={() => handleOpen(true)}>
                    {label || <br />}
                </label>
            )}
            <ClickOutside
                onClick={() => handleOpen(false)}
                className={classes('form-select-wrapper', { 'is-reversed': onTop })}
            >
                <div className="h-relative" onClick={() => (!open ? handleOpen(true) : null)}>
                    {icon && <i className={`form-input-icon icon-${icon}`}></i>}
                    <div
                        ref={select}
                        className={classes('form-select-value', {
                            'is-placeholder': !value,
                            'is-icon': !!icon,
                            'is-open': open,
                        })}
                    >
                        {value
                            ? options.filter((option) => option.value === value)[0].label
                            : placeholder || 'Vyberte...'}
                    </div>
                    {/* {!!value && (
                        <button type="button" className="form-select-cancel" onClick={resetValue}>
                            &#10005;
                        </button>
                    )} */}
                    <button
                        type="button"
                        className={classes('form-select-toggler', { 'is-open': open })}
                        onClick={() => handleOpen(!open)}
                    >
                        Otevří / Zavřít
                    </button>
                </div>
                {open && (
                    <div className="form-select-dropdown">
                        <input
                            ref={input}
                            placeholder={'Napiš...'}
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            onKeyDown={handleKeypresses}
                        />
                        <ul className="form-select-dropdown-menu is-pure-list">
                            {filteredOptions.map((option, index) => (
                                <li
                                    key={option.value}
                                    onClick={() => selectValue(option.value)}
                                    onMouseEnter={() => setActiveOption(index)}
                                    className={classes('', {
                                        'is-active': index === activeOption,
                                        'is-selected': option.value === value,
                                    })}
                                >
                                    {option.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </ClickOutside>
        </FormInputWrapper>
    );
};

export default FormInputSelect;
