import React from 'react';
import './Skeleton.scss';
import '../ImageBox/ImageBox.scss';

const SkeletonImageBox = ({ color = 'primary' }: { color?: 'primary' | 'secondary' }) => {
    return (
        <div className={`image-box is-${color} skeleton-loader h-text-center`}>
            <div className="image-box-image"></div>
            <div className="skeleton-title"></div>
        </div>
    );
};

export default SkeletonImageBox
