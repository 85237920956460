import React, { useState } from 'react';
import './Video.scss';
import { classes } from '../../services/utils';
import Tag from '../AppTag/Tag';
import ReactPlayer from 'react-player/lazy';

interface VideoType {
    url: string;
    finished?: boolean;
    className?: string;
    onFinish?: () => void;
    onProgress?: (state: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }) => void;
}

const Video: React.FC<VideoType> = (props) => {
    const { url, finished = false, className, onFinish, onProgress } = props;
    const [watched, setWatched] = useState(finished || false);

    function onEnded() {
        setWatched(true);
        if (onFinish) onFinish();
    }

    return (
        <div
            className={classes('video-wrapper', {
                'is-finished': finished,
                [className || '']: !!className,
            })}
        >
            {watched && <Tag color="primary">Dosledováno</Tag>}
            <ReactPlayer
                className="video-player"
                onEnded={onEnded}
                controls={true}
                width="100%"
                height="100%"
                url={url}
                onProgress={onProgress}
            />
        </div>
    );
};

export default Video;
