import React from 'react';
import './CTACard.scss';
import { Link } from 'react-router-dom';
import { classes } from '../../services/utils';
import Button from '../AppButton/Button';

interface CTACardType {
    title: string;
    text: string;
    route: string;
    color: 'primary' | 'secondary';
    className?: string;
}

const CTACard: React.FC<CTACardType> = (props) => {
    const { color, title, text, route, className } = props;

    return (
        <Link
            to={route}
            className={classes(`cta-card is-${color}`, {
                [className || '']: !!className,
            })}
        >
            <h2 className="cta-card-title">{title}</h2>
            <p className="cta-card-text">{text}</p>
            <Button icon="shopping-cart-white" color="transparent">
                Koupit kurz
            </Button>
        </Link>
    );
};

export default CTACard;
