import React from 'react'
import { Switch, Route } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import PersonalCourseDetail from './PersonalCourseDetail';
import PersonalCourseLesson from './PersonalCourseLesson';

const PersonalCourse = () => {
    return (
        <Switch>
            <Route
                path={`${routesURLs.personalCourse}/:termId/:lessonId`}
                exact={true}
                render={(props) => <PersonalCourseLesson {...props} />}
            />
            <Route
                path={`${routesURLs.personalCourse}/:id`}
                exact={true}
                render={(props) => <PersonalCourseDetail {...props} />}
            />
        </Switch>
    );
}

export default PersonalCourse
