import React, { useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Button from '../AppButton/Button';
import FormInputText from './Inputs/FormInputText';
import { RegistrationFormType } from './form-types';
import { transformErrors } from '../../services/utils';
import FormInputTextarea from './Inputs/FormInputTextarea';
import FormInputCheckbox from './Inputs/FormInputCheckbox';
import { login, registration } from '../../moduls/auth';
import ErrorBox from '../AppErrorBox/ErrorBox';
import SelectedCourses from '../CheckoutComponents/SelectedCourses';
import FinalPrice from '../CheckoutComponents/FinalPrice';
import { CoursesTypes, useCoursesState } from '../../contexts/courses';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import ButtonDropdown from '../AppButton/ButtonDropdown';
import { PayByType } from '../../services/types';
import { useHistory } from 'react-router';
import { orderFreeLesson } from '../../moduls/online-courses';
import { routesURLs } from '../../Routes';
import DiscountCode from '../DiscountCode/DiscountCode';

const initialValues = {
    name: '',
    phone_number: '',
    email: '',
    username: '',
    password: '',
    password_confirmation: '',
    school: '',
    note: '',
    agreement: false,
};

const attributes = {
    name: {
        placeholder: 'Vyplňte..',
        label: 'Vaše celé Jméno *',
    },
    phone_number: {
        placeholder: 'Vyplňte..',
        label: 'Telefon *',
        note: 'Ve tvaru 721456789',
    },
    email: {
        placeholder: 'Vyplňte..',
        label: 'E-mail *',
    },
    username: {
        placeholder: 'Vyplňte..',
        label: 'Uživatelské jméno *',
    },
    password: {
        placeholder: 'Vyplňte..',
        label: 'Heslo *',
        note: 'Minimálně 8 znaků',
        password: true,
    },
    password_confirmation: {
        placeholder: 'Vyplňte..',
        label: 'Heslo znovu *',
        note: 'Musí se shodovat s heslo',
        password: true,
    },
    school: {
        placeholder: 'Vyplňte..',
        label: 'Základní škola',
    },
    note: {
        placeholder: 'Vyplňte..',
        label: 'poznámka',
    },
    agreement: {
        label: null,
    },
};

const RegistrationForm: React.FC<{}> = () => {
    const { state, dispatch: dispatchCourses } = useCoursesState();
    const { state: globalState, dispatch } = useGlobalState();
    const history = useHistory();
    const searchParams = new URLSearchParams(history.location.search);
    const openFreeCourse = searchParams.get('open-free-course');
    const [payBy, setPayBy] = useState<PayByType>('BANK_TRANSFER');
    const [price, setPrice] = useState<number | null>(null);

    const coursesInBasket = state.savedOnlineCourses.length + state.savedPersonalCourses.length;

    const onSubmit = async (values: RegistrationFormType, { setErrors }: FormikHelpers<RegistrationFormType>) => {
        dispatch({ type: GlobalTypes.ToggleLoader, loader: 'registration' });

        // In case of ordering free course - no onsite or on-line course. Just registration, than log user in and than order free lesson by ID. Than redirect to dashboard.
        if (openFreeCourse) {
            return registration({ ...values, onsite_courses: [], online_courses: [] })
                .then(async (data) => {
                    await login({ password: data.password, username: data.username });
                    await orderFreeLesson(+openFreeCourse);
                    dispatch({ type: GlobalTypes.ToggleLoader });
                    history.push(routesURLs.dashboard);
                })
                .catch((error) => {
                    dispatch({ type: GlobalTypes.ToggleLoader });
                    setErrors(transformErrors(error));
                });
        }

        const finalData = {
            ...values,
            onsite_courses: state.savedPersonalCourses.map((course) => ({
                id: course.id,
                student_name: course.student_name,
            })),
            online_courses: state.savedOnlineCourses.map((course, index) => ({
                id: course.id,
                price_package_id: course.price_package_id,
                lesson_ids: course.lesson_ids,
                discount_code:
                    index === 0 && globalState.discount.one_time && globalState.discount.code
                        ? globalState.discount.code
                        : undefined,
            })),
            discount_code:
                globalState.discount.one_time === 0 && globalState.discount.code
                    ? globalState.discount.code
                    : undefined,
            pay_by: payBy,
        };

        return registration(finalData)
            .then(async (data) => {
                await login({ password: data.password, username: data.username });
                dispatchCourses({ type: CoursesTypes.ResetSavedCourses });
                if (data.redirect_url && payBy === 'PAYMENT_GATEWAY') {
                    window.location.replace(data.redirect_url);
                } else if (data.payments && payBy === 'BANK_TRANSFER') { 
                    dispatch({ type: GlobalTypes.ToggleModal, modal: "ModalPaymentInfo", props: { payments: data.payments } });
                }
                dispatch({ type: GlobalTypes.ToggleLoader });
            })
            .catch((error) => {
                dispatch({ type: GlobalTypes.ToggleLoader });
                setErrors(transformErrors(error));
            });
    };

    // prettier-ignore
    const checkboxTitle = <span className="form-checkbox-title">Souhlasím s <a href="/docs/PODMINKY.pdf" rel="noopener noreferrer" target="_blank"> všeobecnými obchodními podmínkami</a> a <a href="/docs/PODMINKY.pdf" rel="noopener noreferrer" target="_blank"> zásadami ochrany osobních údajů </a></span>

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Povinné pole'),
        phone_number: Yup.string().required('Povinné pole'),
        email: Yup.string().email('Vložte validní e-mail').required('Povinné pole'),
        // eslint-disable-next-line
        password: Yup.string().min(8, 'Minimální délka je ${min} znaků').required('Povinné pole'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), undefined], 'Hesla se musí shodovat')
            .required('Povinné pole'),
        agreement: Yup.bool().oneOf([true], 'Povinné pole'),
    });

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ isValid, dirty, errors, submitCount, submitForm }) => (
                <Form>
                    <div className="page-row">
                        <FormInputText name="name" attributes={attributes.name} />
                        <FormInputText name="phone_number" attributes={attributes.phone_number} />
                        <FormInputText name="email" attributes={attributes.email} />
                        <FormInputText name="school" attributes={attributes.school} />
                        <FormInputTextarea name="note" attributes={attributes.note} />
                        <FormInputText name="username" attributes={attributes.username} />
                        <FormInputText name="password" attributes={attributes.password} />
                        <FormInputText name="password_confirmation" attributes={attributes.password_confirmation} />
                    </div>
                    {!openFreeCourse && (
                        <div className="page-row">
                            <h4 className="h-mb-05">Vámi vybrané kurzy</h4>
                            <hr className="h-mt-05" />
                            <SelectedCourses />
                        </div>
                    )}
                    <div className="page-row">
                        <hr />
                        <FormInputCheckbox name="agreement" attributes={attributes.agreement}>
                            {checkboxTitle}
                        </FormInputCheckbox>
                        {/* In case of ordering free course */}
                        {openFreeCourse ? (
                            <div className="h-text-right">
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={!(isValid && dirty)}
                                    loading={globalState.loader === 'registration'}
                                >
                                    Vyzkoušet lekci zdarma
                                </Button>
                            </div>
                        ) : (
                            <>
                                {!!state.savedOnlineCourses.length && !globalState.discount.code && (
                                    <>
                                        <hr />
                                        <div className="h-py-05">
                                            <DiscountCode />
                                        </div>
                                        <hr />
                                    </>
                                )}
                                <div className="personal-courses-form-summary h-mt-1">
                                    <div className="personal-courses-form-summary-price-wrapper">
                                        <FinalPrice setPrice={setPrice} />
                                    </div>
                                    {coursesInBasket > 0 && price !== 0 ? (
                                        <ButtonDropdown
                                            loading={globalState.loader === 'registration'}
                                            disabled={!(isValid && dirty)}
                                            options={state.savedOnlineCourses.length > 0 ? [
                                                {
                                                    text: 'Zaplatit kartou on-line',
                                                    action: () => {
                                                        setPayBy('PAYMENT_GATEWAY');
                                                        submitForm();
                                                    },
                                                },
                                                {
                                                    text: 'Zaplatit převodem',
                                                    action: () => {
                                                        setPayBy('BANK_TRANSFER');
                                                        submitForm();
                                                    },
                                                },
                                            ] : [
                                                {
                                                    text: 'Zaplatit převodem',
                                                    action: () => {
                                                        setPayBy('BANK_TRANSFER');
                                                        submitForm();
                                                    },
                                                },
                                            ]}
                                        >
                                            Registrovat se a přejít k platbě
                                        </ButtonDropdown>
                                    ) : (
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={!(isValid && dirty)}
                                            loading={globalState.loader === 'registration'}
                                        >
                                            {price ? 'Registrovat se a přejít k platbě' : 'Registrovat se a pokračovat'}
                                        </Button>
                                    )}
                                </div>
                            </>
                        )}
                        <ErrorBox errors={errors} submitCount={submitCount} />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default RegistrationForm;
