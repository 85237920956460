import React, {useState} from 'react';
import './SelectMore.scss';
import MainLayout from '../../components/TheMainLayout/MainLayout';
import { useHistory } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import ImageBox from '../../components/ImageBox/ImageBox';
import BackButton from '../../components/AppBackButton/BackButton';
import SelectedCourses from '../../components/CheckoutComponents/SelectedCourses';
import onlineCoursesImage from '../../assets/images/icons/ic-online-lessons.svg';
import personCoursesImage from '../../assets/images/icons/ic-personal-lessons.svg';
import Button from "../../components/AppButton/Button";
import {GlobalTypes, useGlobalState} from "../../contexts/global";
import {useCoursesState} from "../../contexts/courses";
import FinalPrice from "../../components/CheckoutComponents/FinalPrice";

const SelectMore: React.FC = () => {
    const history = useHistory();
    const {  dispatch } = useGlobalState();
    const { state: coursesState } = useCoursesState();
    const [price, setPrice] = useState<number | null>(null);

    const coursesInBasket = coursesState.savedOnlineCourses.length + coursesState.savedPersonalCourses.length

    let buyCoursesText = 'Přejete si přidat další kurz?'

    if (coursesInBasket === 0) {
        buyCoursesText = 'Vyberte si kurz';
    }

    return (
        <>
            <MainLayout>
                {coursesInBasket > 0 &&
                    <div className="page-row h-mb-3">
                        <h4 className="h-mb-05">Vámi vybrané kurzy</h4>
                        <hr className="h-mt-05" />
                        <SelectedCourses />
                        <hr />
                        <div className="personal-courses-form-summary">
                            <div className="personal-courses-form-summary-price-wrapper">
                                <FinalPrice setPrice={setPrice} />
                            </div>
                            <Button
                                color="primary"
                                onClick={() => dispatch({ type: GlobalTypes.ToggleModal, modal: 'ModalBasket' })}
                            >
                                Přejít do košíku
                            </Button>
                        </div>

                    </div>
                }

                <div className="page-row">
                    <h1 className="h-text-center h-mb-175">{buyCoursesText}</h1>
                    <div className="container-row">
                        <div className="col-md-6">
                            <ImageBox
                                image={onlineCoursesImage}
                                title="On-line kurzy"
                                onClick={() => history.push(routesURLs.onlineCourses)}
                            />
                        </div>
                        <div className="col-md-6">
                            <ImageBox
                                image={personCoursesImage}
                                color="secondary"
                                title="Kurzy na místě"
                                onClick={() => history.push(routesURLs.personalCourses)}
                            />
                        </div>
                    </div>
                </div>
            </MainLayout>
            <BackButton />
        </>
    );
};

export default SelectMore;
