import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Button from '../AppButton/Button';
import FormInputText from './Inputs/FormInputText';
import { ResetPasswordFormType } from './form-types';
import { transformErrors } from '../../services/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { passwordReset } from '../../moduls/auth';
import { routesURLs } from '../../Routes';
import ErrorBox from '../AppErrorBox/ErrorBox';
import { GlobalTypes, useGlobalState } from '../../contexts/global';

const attributes = {
    password: {
        placeholder: 'Vyplňte..',
        label: 'Heslo',
        note: 'Minimálně 8 znaků',
        password: true,
    },
    password_confirmation: {
        placeholder: 'Vyplňte..',
        label: 'Heslo znovu',
        note: 'Musí se shodovat s heslo',
        password: true,
    },
};

const ResetPasswordForm = () => {
    const { dispatch } = useGlobalState();
    const history = useHistory();
    const location = useLocation();
    const URLQuery = new URLSearchParams(location.search);
    const onSubmit = async (
        values: ResetPasswordFormType,
        { setErrors, resetForm }: FormikHelpers<ResetPasswordFormType>
    ) => {
        return passwordReset(values)
            .then(() => {
                resetForm();
                dispatch({
                    type: GlobalTypes.ToggleNotification,
                    style: 'success',
                    text: 'Heslo bylo úspěšně změněno',
                });
                history.push(routesURLs.dashboard);
            })
            .catch((error) => setErrors(transformErrors(error)));
    };

    const initialValues = {
        username: URLQuery.get('username')!,
        password: '',
        password_confirmation: '',
        token: URLQuery.get('token')!,
    };

    const validationSchema = Yup.object().shape({
        // eslint-disable-next-line
        password: Yup.string().min(8, 'Minimální délka je ${min} znaků').required('Povinné pole'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), undefined], 'Hesla se musí shodovat')
            .required('Povinné pole'),
    });

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ isValid, dirty, isSubmitting, errors, submitCount }) => (
                <Form>
                    <FormInputText name="password" attributes={attributes.password} />
                    <FormInputText name="password_confirmation" attributes={attributes.password_confirmation} />
                    <div className="h-text-center h-mt-1">
                        <Button type="submit" color="primary" disabled={!(isValid && dirty)} loading={isSubmitting}>
                            Obnovit heslo
                        </Button>
                    </div>
                    <ErrorBox errors={errors} submitCount={submitCount} />
                </Form>
            )}
        </Formik>
    );
};

export default ResetPasswordForm;
