import React, { useReducer, Dispatch } from 'react';
import { CourseType, OnlineCourseStoredType, PersonalCourseStoredType } from '../services/types';
import { sessionStore } from '../services/utils';

interface State {
    savedPersonalCourses: PersonalCourseStoredType[];
    savedOnlineCourses: OnlineCourseStoredType[];
}

enum CoursesTypes {
    SaveCourse = 'SAVE_COURSE',
    RemoveSavedCourse = 'REMOVE_SAVED_COURSE',
    ResetSavedCourses = 'RESET_SAVED_COURSES',
}

type Actions =
    | {
          type: CoursesTypes.SaveCourse;
          course: PersonalCourseStoredType | OnlineCourseStoredType;
          courseType: CourseType;
      }
    | { type: CoursesTypes.RemoveSavedCourse; id: number; courseType: CourseType }
    | { type: CoursesTypes.ResetSavedCourses; courseType?: CourseType }

const initialState = {
    savedPersonalCourses: sessionStore.get('onsite_courses') || [],
    savedOnlineCourses: sessionStore.get('online_courses') || [],
};

function reducer(state: State, action: Actions) {
    const courseType = {
        personal: 'savedPersonalCourses',
        online: 'savedOnlineCourses',
    };
    switch (action.type) {
        case CoursesTypes.SaveCourse:
            let finalSavedArray;
            if (action.courseType === 'online') {
                if (state.savedOnlineCourses.filter((course) => course.id === action.course.id)[0]) {
                    finalSavedArray = state.savedOnlineCourses.map((course) => {
                        if (course.id === action.course.id) {
                            return action.course;
                        } else {
                            return course;
                        }
                    });
                } else {
                    finalSavedArray = [...state.savedOnlineCourses, action.course];
                }
            } else if (action.courseType === 'personal') {
                finalSavedArray = [...state.savedPersonalCourses, action.course];
            }
            return {
                ...state,
                [courseType[action.courseType]]: finalSavedArray,
            };
        case CoursesTypes.RemoveSavedCourse:
            let finalArray;
            if (action.courseType === 'online') {
                finalArray = state.savedOnlineCourses.filter((course) => course.id !== action.id);
            } else if (action.courseType === 'personal') {
                finalArray = state.savedPersonalCourses.filter((course) => course.id !== action.id);
            }
            return {
                ...state,
                [courseType[action.courseType]]: finalArray,
            };
        case CoursesTypes.ResetSavedCourses:
            if (action.courseType) {
                return {
                    ...state,
                    [courseType[action.courseType]]: [],
                };
            }
            return { ...state, savedPersonalCourses: [], savedOnlineCourses: [] };
        default:
            throw new Error('This action type is not exist in Courses context');
    }
}

const CoursesProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

const AppContext = React.createContext<{
    state: State;
    dispatch: Dispatch<Actions>;
}>({
    state: initialState,
    dispatch: () => null,
});

function useCoursesState() {
    const context = React.useContext(AppContext);
    if (context === undefined) {
        throw new Error('useCoursesState must be used within a CoursesProvider');
    }
    return context;
}

export { CoursesProvider, useCoursesState, CoursesTypes };
