import React, { useState } from 'react';
import { classes } from '../../services/utils';
import ClickOutside from '../ClickOutside/ClickOutside';
import Button, { ButtonType } from './Button';

import './ButtonDropdown.scss';

interface PropsType {
    options: {
        text: string;
        action: () => void;
    }[];
    loading?: boolean;
    color?: ButtonType['color'];
    disabled?: boolean;
}

const ButtonDropdown: React.FC<PropsType> = ({
    options,
    children,
    disabled = false,
    loading = false,
    color = 'primary',
}) => {
    const [open, setOpen] = useState(false);
    return (
        <ClickOutside onClick={() => setOpen(false)} className="button-dropdown-wrapper">
            <Button
                color={color}
                disabled={disabled}
                loading={loading}
                onClick={() => (disabled ? null : setOpen(!open))}
            >
                {children}
            </Button>
            {!disabled && (
                <ul className={classes('button-dropdown is-pure-list', { 'is-open': open })}>
                    {options.map((option) => {
                        const actionClose = () => {
                            option.action();
                            setOpen(false);
                        };
                        return (
                            <li key={option.text} onClick={actionClose}>
                                {option.text}
                            </li>
                        );
                    })}
                </ul>
            )}
        </ClickOutside>
    );
};

export default ButtonDropdown;
