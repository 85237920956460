import React from 'react';
import AppLabel from '../../components/AppLabel/Label';
import Button from '../../components/AppButton/Button';
import Tag from '../../components/AppTag/Tag';
import TestForm from '../../components/Forms/TestForm';
import ButtonSelect from '../../components/AppButtonSelect/ButtonSelect';
import ImageBox from '../../components/ImageBox/ImageBox';
import CourseCard from '../../components/CourseCard/CourseCard';
import IconButton from '../../components/AppIconButton/IconButton';
import BackButton from '../../components/AppBackButton/BackButton';

import onlineCoursesImage from '../../assets/images/icons/ic-online-lessons.svg';
import personCoursesImage from '../../assets/images/icons/ic-personal-lessons.svg';
import { useGlobalState, GlobalTypes } from '../../contexts/global';
import CTACard from '../../components/CTACard/CTACard';
import { routesURLs } from '../../Routes';
import FreeCourseBanner from '../../components/FreeCourseBanner/FreeCourseBanner';
import ButtonDropdown from '../../components/AppButton/ButtonDropdown';

const ColorBox: React.FC<{ color?: string; background: string }> = ({ color = 'white', background }) => {
    return (
        <div
            className={`h-px-15 h-py-05 h-background-${background} h-color-${color} h-border-radius h-display-inline-block h-mr-05 h-mb-05`}
        >
            {background} color
        </div>
    );
};

const Components: React.FC = () => {
    const { dispatch, state: globalState } = useGlobalState();

    return (
        <div className="container-row center-md">
            <div className="col-md-10 col-lg-8">
                <h3>Colors</h3>
                <ColorBox background="primary" />
                <ColorBox background="secondary" />
                <ColorBox background="tertiary" />
                <ColorBox background="dark-gray" />
                <ColorBox background="medium-gray" />
                <ColorBox background="light-gray" />
                <ColorBox background="alert" />
                <ColorBox background="success" />
                <ColorBox background="black" />
                <ColorBox background="white" color="black" />
                <hr />
                <h3>Typography</h3>
                <h1>h1 title</h1>
                <h2>h2 title</h2>
                <h3>h3 title</h3>
                <h4>h4 title</h4>
                <AppLabel htmlFor="#">Label</AppLabel>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, enim reprehenderit quod unde
                    mollitia nihil consequatur voluptates ullam, debitis dolorem animi, ratione cum inventore ab{' '}
                    <a href="http://www.pixelmate.com">explicabo esse voluptas distinctio</a>! Dolorem natus culpa eius
                    repellendus cumque ipsum unde autem fugiat rerum? Magnam suscipit sed adipisci voluptatem minima
                    tenetur harum, animi ullam porro ducimus minus mollitia sit in fugiat eos. Dolores, voluptates
                    possimus nisi est ad obcaecati mollitia, ex eum illo iure ducimus voluptas iste voluptatibus aliquid
                    commodi fugit eos doloremque placeat.
                </p>
                <ul>
                    <li>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore voluptates cupiditate in.
                    </li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                    <li>Lorem, ipsum dolor.</li>
                </ul>
                <ul className="is-small">
                    <li>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore voluptates cupiditate in.
                    </li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                    <li>Lorem, ipsum dolor.</li>
                </ul>
                <h3>Buttons {globalState.modalComponent}</h3>
                <Button
                    color="primary"
                    className="h-mb-05 h-mr-05"
                    onClick={() => dispatch({ type: GlobalTypes.ToggleModal, modal: 'ModalChangePassword' })}
                >
                    Primary button
                </Button>
                <Button color="outline" className="h-mb-05 h-mr-05">
                    Outline button
                </Button>
                <Button color="alert" className="h-mb-05 h-mr-05">
                    Alert button
                </Button>
                <Button icon="shopping-cart-primary" color="transparent" className="h-mb-05 h-mr-05">
                    Icon button
                </Button>
                <Button color="transparent" className="h-mb-05 h-mr-05">
                    Transparent button
                </Button>
                <Button color="link" className="h-mb-05 h-mr-05">
                    Link button
                </Button>
                <ButtonDropdown
                    options={[
                        { text: 'Zaplatit kartou', action: () => console.log('Kartou') },
                        { text: 'Zaplatit převodem', action: () => console.log('Převodem') },
                    ]}
                >
                    Button dropdown
                </ButtonDropdown>
                <IconButton icon="shopping-cart-primary" onClick={() => {}} />
                <IconButton icon="shopping-cart-primary" href="https://www.google.com" />
                <BackButton route="/" />
                <ButtonSelect onClick={() => {}}>This is button select</ButtonSelect>
                <hr />
                <h3 className="h-mt-2">Tags</h3>
                <Tag color="primary" className="h-mr-05">
                    Primary tag
                </Tag>
                <Tag color="secondary" className="h-mr-05">
                    Secondary tag
                </Tag>
                <Tag color="tertiary" className="h-mr-05">
                    Tertiary tag
                </Tag>
                <hr />
                <h3 className="h-mt-2">Image boxes</h3>
                <div className="container-row">
                    <div className="col-md-6">
                        <ImageBox image={onlineCoursesImage} title="On-line kurzy" />
                    </div>
                    <div className="col-md-6">
                        <ImageBox image={personCoursesImage} color="secondary" title="Kurzy na místě" />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <ImageBox
                            image={personCoursesImage}
                            color="secondary"
                            title="Kurz 2 · Matematika"
                            subtitle="13 lekcí · 3 500 Kč"
                            small={true}
                        />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <ImageBox
                            image={personCoursesImage}
                            color="secondary"
                            title="Kurz 2 · Matematika"
                            subtitle="13 lekcí · 3 500 Kč"
                            isChecked={true}
                            small={true}
                        />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <ImageBox
                            image={personCoursesImage}
                            color="secondary"
                            title="Kurz 2 · Matematika"
                            subtitle="13 lekcí · 3 500 Kč"
                            isChecked={false}
                            small={true}
                        />
                    </div>
                    <div className="col-md-4">
                        <CourseCard
                            tag="Na místě"
                            title="Titel"
                            color="primary"
                            image={onlineCoursesImage}
                            onRemove={() => {}}
                        >
                            <h2 className="h-mb-05">KURZ 1 · Český jazyk</h2>
                            <ul className="is-small h-mb-0">
                                <li>13 lekcí · 3 500 Kč</li>
                                <li>Úterý · 16:30 - 17:30 · 1. 12. 20 - 30. 4. 21</li>
                                <li>Praha 2 · DOHLAVY · Neklanova 42</li>
                            </ul>
                        </CourseCard>
                    </div>
                    <div className="col-md-4">
                        <CourseCard
                            tag="Na místě"
                            title="Titel"
                            color="secondary"
                            image={personCoursesImage}
                            onRemove={() => {}}
                        >
                            <h2 className="h-mb-05">KURZ 1 · Český jazyk</h2>
                            <ul className="is-small h-mb-0">
                                <li>13 lekcí · 3 500 Kč</li>
                                <li>Úterý · 16:30 - 17:30 · 1. 12. 20 - 30. 4. 21</li>
                                <li>Praha 2 · DOHLAVY · Neklanova 42</li>
                            </ul>
                        </CourseCard>
                    </div>
                    <div className="col-md-4">
                        <CourseCard
                            tag="Na místě"
                            title="Titel"
                            color="secondary"
                            image={personCoursesImage}
                            disabled={true}
                        >
                            <h2 className="h-mb-05">KURZ 1 · Český jazyk</h2>
                            <ul className="is-small h-mb-0">
                                <li>13 lekcí · 3 500 Kč</li>
                                <li>Úterý · 16:30 - 17:30 · 1. 12. 20 - 30. 4. 21</li>
                                <li>Praha 2 · DOHLAVY · Neklanova 42</li>
                            </ul>
                        </CourseCard>
                    </div>
                    <div className="col-md-6">
                        <CTACard
                            route={routesURLs.components}
                            color="primary"
                            title="Kupte si snadno A rychle další Přípravné kurzy"
                            text="S našimi kurzy vás u přijímaček nic nepřekvapí"
                        />
                    </div>
                    <div className="col-md-6">
                        <CTACard
                            route={routesURLs.components}
                            color="secondary"
                            title="Kupte si snadno A rychle další Přípravné kurzy"
                            text="S našimi kurzy vás u přijímaček nic nepřekvapí"
                        />
                    </div>
                </div>
                <FreeCourseBanner courses={{}} />
                <hr />
                <div className="container-row center-md">
                    <div className="col-md-10">
                        <TestForm />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Components;
