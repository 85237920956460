import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Button from '../AppButton/Button';
import FormInputText from './Inputs/FormInputText';
import { PasswordChangeFormType } from './form-types';
import { transformErrors } from '../../services/utils';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { changePassword } from '../../moduls/profile';

const initialValues = {
    password: '',
    new_password: '',
    new_password_confirmation: ''
};

const attributes = {
    password: {
        placeholder: 'Vyplňte..',
        label: 'Staré heslo',
        password: true
    },
    new_password: {
        placeholder: 'Vyplňte..',
        label: 'Nové heslo',
        password: true
    },
    new_password_confirmation: {
        placeholder: 'Vyplňte..',
        label: 'Nové heslo znovu',
        password: true
    }
};

const ChangePasswordForm: React.FC<{}> = () => {
    const { dispatch } = useGlobalState();
    const onSubmit = async (values: PasswordChangeFormType, { setErrors, resetForm }: FormikHelpers<PasswordChangeFormType>) => {
        return changePassword(values)
            .then(() => {
                resetForm();
                dispatch({ type: GlobalTypes.ToggleModal, modal: '' });
            })
            .catch((error) => setErrors(transformErrors(error)));
    };

    const validationSchema = Yup.object().shape({
        // eslint-disable-next-line
        password: Yup.string().min(8, 'Minimální délka je ${min} znaků').required('Povinné pole'),
        // eslint-disable-next-line
        new_password: Yup.string().min(8, 'Minimální délka je ${min} znaků').required('Povinné pole'),
        new_password_confirmation: Yup.string()
            .oneOf([Yup.ref('new_password'), undefined], 'Hesla se musí shodovat')
            .required('Povinné pole'),
    });

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ isValid, dirty, isSubmitting }) => (
                <Form>
                    <FormInputText name="password" attributes={attributes.password} />
                    <FormInputText name="new_password" attributes={attributes.new_password} />
                    <FormInputText name="new_password_confirmation" attributes={attributes.new_password_confirmation} />
                    <div className="h-text-center h-mt-1">
                        <Button type="submit" color="primary" disabled={!(isValid && dirty)} loading={isSubmitting}>
                            Odeslat
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ChangePasswordForm;
