import React from 'react'
import ChangePasswordForm from '../Forms/ChangePasswordForm';

const ModalChangePassword = () => {
    return (
        <div className="modal-content">
            <h1 className="h-text-center">Změna hesla</h1>
            <ChangePasswordForm />
        </div>
    );
}

export default ModalChangePassword
