import React, {  } from 'react';
import './Basket.scss';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import IconButton from "../AppIconButton/IconButton";
import {useCoursesState} from "../../contexts/courses";

const Basket: React.FC<{ className?: string }> = ({ className }) => {

    const {  dispatch } = useGlobalState();
    const { state: coursesState} = useCoursesState();

    const coursesInBasket = coursesState.savedOnlineCourses.length + coursesState.savedPersonalCourses.length

    return (
        <>
            <IconButton
                icon="basket"
                onClick={() => dispatch({ type: GlobalTypes.ToggleModal, modal: 'ModalBasket' })}
                title="Nákupní košík"
                className="basket-button"
            />
            <span className="basket-item-counter">
                {coursesInBasket}
            </span>
        </>
    );
};

export default Basket;
