import React from 'react';
import { useGlobalState } from '../../contexts/global';
import Button from "../AppButton/Button";
import {routesURLs} from "../../Routes";


const ModalPaymentInfo: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
    const { state, dispatch } = useGlobalState();
    const payments = state.modalProps.payments;


    return (
        <div className='modal-content h-background-body'>
            <h1 className='h-text-center'>Platební pokyny</h1>
            <div style={{marginBottom: '20px'}}>
                {payments.map((payment: { [key: string]: any }, index: number) => (
                    <div className='payments-instructions' key={index}>
                        <img src={payment.qrCodeString ?? ""} style={{marginRight: '20px'}} alt='QR Code' />
                        <div>
                            Číslo účtu:{" "}
                            {payment.paymentable.invoiceEntity?.account_number ?? "2901809105/2010"}
                            <br />
                            Variabliní symbol: {payment.paymentable.variable_symbol}
                            <br />
                            Částka: {payment.price} Kč
                        </div>
                    </div>
                ))}
            </div>
            <p className="h-text-center">Platební pokyny najdete také ve Vašem emailu.</p>
            <Button style={{width: '100%'}} color="primary" onClick={() => {
                window.location.replace(routesURLs.dashboard);
            }}>Moje kurzy</Button>
        </div>
    );
};

export default ModalPaymentInfo;
