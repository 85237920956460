import React from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps, useParams } from 'react-router-dom';
import BackButton from '../../components/AppBackButton/BackButton';
import ImageBox from '../../components/ImageBox/ImageBox';
import { getSubjects, getClasses } from '../../moduls/online-courses';
import { routesURLs } from '../../Routes';
import { SubjectType, LessonClassType } from '../../services/types';

import firstGradeImage from '../../assets/images/icons/ic-first-grade.svg';
import secondGradeImage from '../../assets/images/icons/ic-second-grade.svg';
import thirdGradeImage from '../../assets/images/icons/ic-third-grade.svg';
import SkeletonImageBox from '../../components/Skeletons/SkeletonImageBox';
import {getMyCourses} from "../../moduls/courses";

const classImage: { [key: string]: string } = {
    '5. třída': firstGradeImage,
    '7. třída': secondGradeImage,
    '9. třída': thirdGradeImage,
};

const OnlineCoursesClasses: React.FC<RouteComponentProps> = (props) => {
    const params = useParams<{ id: string }>();

    const { data: subjects = [] } = useQuery<SubjectType[]>('subjects', getSubjects, {
        staleTime: Infinity,
    });

    const { data: courses } = useQuery<any>('my-courses', getMyCourses);

    const { data: classes = [], isFetched } = useQuery<LessonClassType[]>(['classes', { subjectId: +params.id }], getClasses, {
        staleTime: Infinity,
    });

    let subject = subjects.filter((subject) => subject.id === +params.id)[0];

    function hasAllLessonsInCourse(lessonClassId: any, subject: any) {
        for (var l = 0; l < courses?.online_courses.length; l++) {
            let course = courses?.online_courses[l];

            if (course.lesson_class.id === lessonClassId && subject?.id === course.subject.id) {
                return courses?.online_courses[l]?.hasAllLessons;
            }
        }
    }

    return (
        <>
            <div className="page-row">
                <h1 className="h-text-center h-mb-175">
                    On-line {subject?.name}
                </h1>
                <h3 className="h-text-center">Vyberte třídu</h3>
                <div className="container-row center-md">
                    {!isFetched && <Loader />}
                    {classes.map((classItem) => (
                        <div key={classItem.id} className="col-md-4">
                            <ImageBox
                                image={classImage[classItem.name] || thirdGradeImage}
                                color="primary"
                                title={classItem.name}
                                onClick={() =>
                                    props.history.push(`${routesURLs.onlineCourses}/${params.id}/${classItem.id}`)
                                }
                                disabled={hasAllLessonsInCourse(classItem.id, subject)}
                                tooltip={hasAllLessonsInCourse(classItem.id, subject) ? 'KURZ JE DOSTUPNÝ VE VAŠÍ OSOBNÍ ZÓNĚ' : ''}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <BackButton route={routesURLs.onlineCourses} />
        </>
    );
};

const Loader = () => (
    <>
        <div className="col-md-4">
            <SkeletonImageBox />
        </div>
        <div className="col-md-4">
            <SkeletonImageBox />
        </div>
        <div className="col-md-4">
            <SkeletonImageBox />
        </div>
    </>
);

export default OnlineCoursesClasses;
