import React, { RefObject } from 'react';
import './Button.scss';
import { classes } from '../../services/utils';

export interface ButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    color: 'primary' | 'outline' | 'transparent' | 'link' | 'alert';
    disabled?: boolean;
    icon?: string;
    loading?: boolean;
    className?: string;
    reference?: RefObject<HTMLButtonElement>;
    type?: 'submit' | 'button';
    style?: React.CSSProperties;
}

const Button: React.FC<ButtonType> = (props) => {
    const { className, onClick, color, children, disabled, icon, loading, type = 'button', reference, style } = props;

    const addClasses = {
        [className || '']: !!className,
        'is-loading': !!loading,
        [`is-${color}`]: !!color,
    };

    return (
        <button
            type={type}
            ref={reference}
            onClick={disabled || loading ? () => {} : onClick}
            className={classes('button', addClasses)}
            disabled={disabled}
            title={loading ? 'Načítám..' : ''}
            style={style ?? {}}
        >
            {icon && <i className={`icon-${icon}`}></i>}
            {children}
        </button>
    );
};

export default Button;
