import React from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import BackButton from '../../components/AppBackButton/BackButton';
import { routesURLs } from '../../Routes';
import PersonalCourseForm from '../../components/Forms/PersonalCourseForm';
import { useQuery } from 'react-query';
import { getCities, getPersonalCourses, getPersonalCoursesPackages } from '../../moduls/personal-courses';
import { CityType, PersonalCoursePackageType, PersonalCourseType } from '../../services/types';
import Loader from '../../components/AppLoader/Loader';

const PersonalCoursesDetail: React.FC<RouteComponentProps> = () => {
    const params = useParams<{ id: string }>();
    const { id } = params;
    const { data: courses = [], isFetched } = useQuery<PersonalCourseType[]>(
        ['personal-courses', { id }],
        getPersonalCourses
    );
    const { data: coursesPackages = [] } = useQuery<PersonalCoursePackageType[]>(
        'personal-courses-packages',
        getPersonalCoursesPackages,
        {
            staleTime: Infinity,
        }
    );

    const { data: cities = [] } = useQuery<CityType[]>('cities', getCities);
    const cityName = cities.length ? cities.filter((city) => city.id === +id)[0].name : '';

    if (!isFetched) return <Loader />;

    return (
        <div className="page-row">
            <BackButton route={routesURLs.personalCourses} />
            <div className="page-row">
                <h1 className="h-text-center h-mb-15">Přípravné kurzy {cityName}</h1>
                <PersonalCourseForm courses={courses} packages={coursesPackages} />
            </div>
        </div>
    );
};

export default PersonalCoursesDetail;
