import React from 'react';
import { classes } from '../../services/utils';
import './ButtonSelect.scss';

interface IButtonSelect {
    onClick: () => void;
    rightPart?: any;
    isChecked?: boolean;
    isDisabled?: boolean;
    className?: string;
    description?: string;
}

const ButtonSelect: React.FC<IButtonSelect> = (props) => {
    const { onClick, isChecked, rightPart, children, isDisabled, className, description } = props;
    return (
        <button
            type="button"
            className={classes('button-select', {
                'is-checked': !!isChecked,
                'is-disabled': !!isDisabled,
                [className || '']: !!className,
            })}
            onClick={isDisabled ? () => {} : onClick}
        >
            <div className="button-select-content">
                <span className="right">{rightPart}</span>
                <span className="left">{children}</span>
            </div>

            {description && (
                <div className="button-course-desc">{description}</div>
            )}

        </button>
    );
};

export default ButtonSelect;
