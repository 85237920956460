import api from '../services/api';
import { getCookie } from '../services/utils';

export const getCities = () => {
    return api.get('/cities').then((res) => res.data.data);
};

export const getPersonalCourses = (key: string, { id }: { id: string }) => {
    return api
        .get(getCookie('jid') ? '/available-onsite-courses' : '/onsite-courses', {
            params: {
                'filter[location.city_id]': id,
            },
        })
        .then((response) => response.data.data);
};

export const getPersonalCourse = (_: string, { id }: { id: number }) => {
    return api.get(`/onsite-courses/${id}`).then((res) => res.data.data);
};

export const getPersonalCourseLesson = (_: string, { id }: { id: number }) => {
    return api.get(`/onsite-course-lessons/${id}`).then((res) => res.data.data);
};

export const getPersonalCoursesPackages = () => {
    return api.get('/onsite-course-packages').then((res) => res.data.data);
};
