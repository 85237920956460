import React from 'react';
import { classes } from '../../services/utils';
import './Icon.scss';

interface IconType {
    icon: string;
    width: string;
    height: string;
    className?: string;
}

const Icon: React.FC<IconType> = ({ icon, width, height, className }) => {
    return (
        <i className={classes(`icon icon-${icon}`, { [className || '']: !!className })} style={{ width, height }}></i>
    );
};

export default Icon;
