import React from 'react';
import MainLayout from '../../components/TheMainLayout/MainLayout';
import BackButton from '../../components/AppBackButton/BackButton';
import RegistrationForm from '../../components/Forms/RegistrationForm';

const Registration: React.FC = () => {
    return (
        <>
            <MainLayout>
                <h1 className="h-text-center">Registrace</h1>
                <RegistrationForm />
            </MainLayout>
            <BackButton />
        </>
    );
};

export default Registration;
