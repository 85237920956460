import { OnlineCourseFormType, PersonalCourseFormType } from '../components/Forms/form-types';
import api from '../services/api';
import { PayByType } from '../services/types';
import {getCookie} from "../services/utils";

export const getMyCourses = () => {
    return getCookie('jid') && api.get('/my-courses').then((res) => res.data.data);
};

export const getPendingPayments = () => {
    return api.get('/pending-payments').then((res) => res.data.data.pending_payments);
};

export const orderCourses = (
    courses: {
        onsite_courses: Omit<PersonalCourseFormType, 'course' | 'selectedLocation'>[];
        online_courses: OnlineCourseFormType[];
        discount_code?: string;
    },
    pay_by?: PayByType
) => {
    return api.post('/my-courses', { ...courses, pay_by });
};

export const repeatOnlineCoursePayment = (courseId: number) => {
    return api.post(`/repeat-payment`, { online_course_id: courseId });
}

export const repeatPersonalCoursePayment = (courseId: number) => {
    return api.post(`/repeat-payment`, { onsite_course_id: courseId });
}

export const checkDiscountCode = (code: string) => {
    return api.get(`/discount-codes/${code}`).then((res) => res.data.data);
};

export const coursesOverview = () => {
    return api.get('/progress').then((res) => res.data.data);
};
