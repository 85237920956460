import React from 'react'
import './CheckboxItem.scss'
import { classes } from '../../services/utils';
import Checkmark from '../AppCheckmark/Checkmark';

interface CheckboxItemType {
    text: string;
    isChecked?: boolean;
    isDisabled?: boolean;
    note?: string;
}

const CheckboxItem: React.FC<CheckboxItemType> = ({ text, isChecked, isDisabled, note }) => {
    return (
        <div className={classes('checkbox-item', { 'is-checked': isChecked!, 'is-disabled': isDisabled! })}>
            <div>
                {text} {!!note && <span className="h-display-inline-block h-text-regular">{note}</span>}
            </div>
            <Checkmark isChecked={isChecked! || isDisabled!} />
        </div>
    );
};

export default CheckboxItem
