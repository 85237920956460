import React from 'react';
import CourseCard from '../CourseCard/CourseCard';
import { CourseType } from '../../services/types';

import personCoursesImage from '../../assets/images/icons/ic-personal-lessons.svg';
import onlineCoursesImage from '../../assets/images/icons/ic-online-lessons.svg';
import CourseCardOnline from '../CourseCard/CourseCardOnline';
import CourseCardPersonal from '../CourseCard/CourseCardPersonal';
import { CoursesTypes, useCoursesState } from '../../contexts/courses';

const SelectedCourses = () => {
    const { state, dispatch } = useCoursesState();

    function removeCourse(id: number, courseType: CourseType) {
        dispatch({ type: CoursesTypes.RemoveSavedCourse, id, courseType });
    }

    const courses = [...state.savedOnlineCourses, ...state.savedPersonalCourses];

    return (
        <div className="container-row">
            {state.savedOnlineCourses.map((course) => (
                <div key={course.id} className="col-md-6">
                    <CourseCard
                        tag="On-line"
                        color="primary"
                        price={course.course.price}
                        title={course.course.name}
                        image={onlineCoursesImage}
                        removeButton={courses.length > 1}
                        onRemove={() => removeCourse(course.id, 'online')}
                    >
                        <CourseCardOnline
                            classLesson={course.course.classLesson}
                            expiration={course.course.expiration}
                            lessonsLength={course.lesson_ids.length}
                            name={course.course.name}
                        />
                    </CourseCard>
                </div>
            ))}
            {state.savedPersonalCourses.map((course) => (
                <div key={course.id} className="col-md-6">
                    <CourseCard
                        tag="Na místě"
                        color="secondary"
                        title={course.course}
                        price={course.term.price}
                        image={personCoursesImage}
                        removeButton={courses.length > 1}
                        onRemove={() => removeCourse(course.id, 'personal')}
                    >
                        <CourseCardPersonal
                            lessonCount={course.term.lessons_count}
                            city={course.term.location.city.name}
                            street={course.term.location.street_name}
                            streetNumber={course.term.location.street_number}
                            placeName={course.term.location.name}
                            course={course.course}
                            from={course.term.date_from}
                            to={course.term.date_to}
                            lector={course.term.lector.name}
                            price={course.term.price}
                            lessonDate={course.term.lessons[0]?.date}
                        />
                    </CourseCard>
                </div>
            ))}
        </div>
    );
};

export default SelectedCourses;
