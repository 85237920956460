import React, {  } from 'react';
import './BasketItem.scss';
import {plural} from "../../services/utils";
import {CourseType} from "../../services/types";
import IconButton from "../AppIconButton/IconButton";
import { formateNumber } from '../../services/utils';

const BasketItem: React.FC<{
    course: any;
    title: string;
    price: number;
    lessonCount: number;
    courseType: CourseType;
    removeCourse: any;

}> = (props) => {
    const { course, title, price, lessonCount, courseType, removeCourse } = props;

    return (
        <>
            <div key={course.id} className="col-md-20">
                <div className="curse-name-price">
                    <span className="course-name">
                        <h2>{title}</h2>
                    </span>
                    <span className="course-price">
                        <IconButton
                            icon="bin-gray"
                            onClick={() => removeCourse(course.id, courseType)}
                            title="Odebrat kurz"
                            className="basket-button"
                        />
                    </span>
                    <span className="course-price">{`${formateNumber(price, 0)} ,-`}</span>
                    <span className="course-lessons">{lessonCount} {plural(lessonCount, 'lekce', 'lekce', 'lekcí')}</span>
                </div>
                <br /><br />
                <hr />
            </div>
        </>
    );
};

export default BasketItem;
