import React from 'react';
import './IconButton.scss';
import { classes } from '../../services/utils';

interface IconButtonType {
    icon: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void;
    className?: string;
    href?: string;
    title?: string;
}

const IconButton: React.FC<IconButtonType> = (props) => {
    const { className, onClick, icon, href, title } = props;
    const CustomTag = href ? 'a' : 'button';
    const buttonAttributes = href ? { href, target: '_blank' } : { type: 'button', onClick } as const;

    return (
        <CustomTag
            {...buttonAttributes}
            title={title}
            className={classes(`icon-button icon-${icon}`, {
                [className || '']: !!className,
            })}
        ></CustomTag>
    );
};

export default IconButton;
