import React from 'react'
import { routesURLs } from '../../Routes';
import ImageBox from '../ImageBox/ImageBox';
import { useHistory } from 'react-router-dom';

import onlineCoursesImage from '../../assets/images/icons/ic-online-lessons.svg';
import personCoursesImage from '../../assets/images/icons/ic-personal-lessons.svg';

const ModalChooseCourse: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
    const history = useHistory();

    const selectCategory = (route: string) => {
        history.push(route);
        closeModal();
    }

    return (
        <div className="modal-content h-background-body h-px-1">
            <h1 className="h-text-center h-mb-175">O jaké kurzy máte zájem?</h1>
            <div className="container-row">
                <div className="col-md-6">
                    <ImageBox
                        image={onlineCoursesImage}
                        color="primary"
                        title="On-line kurzy"
                        onClick={() => selectCategory(routesURLs.onlineCourses)}
                    />
                </div>
                <div className="col-md-6">
                    <ImageBox
                        image={personCoursesImage}
                        color="secondary"
                        title="Kurzy na místě"
                        onClick={() => selectCategory(routesURLs.personalCourses)}
                    />
                </div>
            </div>
        </div>
    );
}

export default ModalChooseCourse
