import api from '../services/api';
import { getCookie } from '../services/utils';

export const getSubjects = () => {
    return api.get('/subjects').then((res) => res.data.data);
};

export const getClasses = (_: string, { subjectId }: { subjectId: number }) => {
    return api
        .get('/lesson-classes', {
            params: {
                'filter[subject_id]': subjectId,
            },
        })
        .then((res) => res.data.data);
};

export const getOnlineCourses = (_: string, { subjectId, lessonId }: { subjectId: number; lessonId: number }) => {
    return api
        .get(getCookie('jid') ? '/available-online-courses' : '/online-courses', {
            params: {
                'filter[subject_id]': subjectId,
                'filter[lesson_class_id]': lessonId,
            },
        })
        .then((res) => res.data.data);
};

export const getOnlineCourse = (_: string, { id }: { id: number }) => {
    return api.get(`/online-courses/${id}`).then((res) => res.data.data);
};

export const getOnlineCourseLesson = (_key: string, { id }: { id: string }) => {
    return api.get(`/online-course-lessons/${id}`).then((res) => res.data.data);
};

export const getOnlineCourseLessonTest = (_key: string, { id }: { id: string }) => {
    return api.get(`/lesson-tests/${id}`).then((res) => res.data.data);
};

export const markVideoAsSeen = (id: number) => {
    return api.post(`/finished-videos/${id}`);
};

export const setVideoPartSeen = (interpretationPartId: number, minutes: number) => {
    return api.post(`videos-minutes/${interpretationPartId}`, {
        minutes,
    });
};

export const completeLessonTest = (id: number, answers: { id: number; answer: string | number | boolean[] }[]) => {
    return api.post(`/completed-lesson-tests/${id}`, {
        answers,
    });
};

export const getFreeLessons = () => {
    return api.get('/free-online-course-lessons').then((res) => res.data.data);
};

export const orderFreeLesson = (id: number) => {
    return api.post(`/free-online-course-lessons/${id}`).then((res) => res.data.data);
};

export const getCompletedRandomGif = (_: string, { score }: { score: number }) => {
    return api.get(`/completed-gif/${score}`).then((res) => res.data.data);
};
