import React, { useEffect, useRef } from 'react';
import Button from '../AppButton/Button';
import './TestNotification.scss';

interface TestNotificationType {
    type: 'primary' | 'alert' | 'loading';
    loading: boolean;
    onClick: () => void;
    isLast: boolean;
}

const TestNotification: React.FC<TestNotificationType> = ({ type, onClick, loading, isLast }) => {
    const button = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (button.current !== null) {
            button.current.focus();
        }
    }, []);

    return (
        <div className={`test-notification-wrapper is-${type}`}>
            <div className="container">
                <div className="test-notification">
                    <div className="h-vertical-center">
                        {type !== 'loading' && (
                            <i
                                className={`test-notification-icon icon-${type === 'alert' ? 'close' : 'check'}-white`}
                            ></i>
                        )}
                        <span className="test-notification-title">
                            {type === 'alert' ? 'Špatná odpověď' : type === 'primary' ? 'Správná odpověď' : 'Ověřuji...'}
                        </span>
                    </div>
                    <Button
                        reference={button}
                        color={type === 'loading' ? 'transparent' : 'primary'}
                        onClick={loading || type === 'loading' ? () => {} : onClick}
                        disabled={type === 'loading'}
                        loading={loading || type === 'loading'}
                    >
                        {isLast ? 'Dokončit test' : 'Přejít na další otázku'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TestNotification;
