import React, { useEffect } from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import Components from './pages/Components/Components';
import Homepage from './pages/Homepage/Homepage';
import Topbar, { TopbarType } from './components/TheTopbar/Topbar';
import PersonalCourses from './pages/PesonalCourses/PersonalCourses';
import SelectMore from './pages/SelectMore/SelectMore';
import Registration from './pages/Registration/Registration';
import Profile from './pages/Profile/Profile';
import ProfileEdit from './pages/Profile/ProfileEdit';
import Dashboard from './pages/Dashboard/Dashboard';
import { getCookie } from './services/utils';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import OnlineCourses from './pages/OnlineCourses/OnlineCourses';
import OnlineCourse from './pages/OnlineCourse/OnlineCourse';
import PersonalCourse from './pages/PersonalCourse/PersonalCourse';
// import FreeCourse from './pages/FreeCourse/FreeCourse';
// import OpenFreeCourse from './pages/OpenFreeCourse/OpenFreeCourse';
import Overview from './pages/Overview/Overview';

type RoutesType = {
    path: string;
    title: string;
    component: React.FC<{}>;
    protected: boolean;
    topbarType?: TopbarType;
    exact?: boolean;
    authPage?: boolean;
}[];
interface PageType extends RouteComponentProps {
    component: React.FC<any>;
    title: string;
    topbarType?: TopbarType;
}

declare global {
    interface Window {
        router: any;
        toggleNotification: any;
    }
}

export const routesURLs = {
    dashboard: '/dashboard',
    onlineCourse: '/dashboard/online-kurz',
    personalCourse: '/dashboard/kurz-na-miste',
    freeCourse: '/dashboard/lekce-zdarma',
    openFreeCourse: '/lekce-zdarma',
    personalCourses: '/kurzy-na-miste',
    onlineCourses: '/online-kurzy',
    selectMore: '/dalsi-kurzy',
    registration: '/registrace',
    profile: '/profil',
    overview: '/prehled',
    profileEdit: '/nastaveni-profilu',
    passwordReset: '/obnova-hesla',
    components: '/components',
    homepage: '/',
};

export const routesList: RoutesType = [
    /*
    {
        path: routesURLs.openFreeCourse,
        title: 'Lekce ZDARMA',
        component: OpenFreeCourse,
        protected: false,
        topbarType: 'freeCourse',
    },
    */
    {
        path: routesURLs.personalCourses,
        title: 'Kurzy na místě',
        component: PersonalCourses,
        protected: false,
        topbarType: 'course',
    },
    {
        path: routesURLs.onlineCourses,
        title: 'On-line kurzy',
        component: OnlineCourses,
        protected: false,
        topbarType: 'course',
    },
    {
        path: routesURLs.selectMore,
        title: 'Další kurzy',
        component: SelectMore,
        protected: false,
        topbarType: 'course',
    },
    {
        path: routesURLs.registration,
        title: 'Registrace',
        component: Registration,
        protected: false,
        authPage: true,
        topbarType: 'course',
    },
    {
        path: routesURLs.profile,
        title: 'Profil',
        component: Profile,
        protected: true,
        topbarType: 'personal',
    },
    {
        path: routesURLs.overview,
        title: 'Přehled',
        component: Overview,
        protected: true,
        topbarType: 'personal',
    },
    {
        path: routesURLs.profileEdit,
        title: 'Nastavení profilu',
        component: ProfileEdit,
        protected: true,
        topbarType: 'personal',
    },
    {
        path: routesURLs.passwordReset,
        title: 'Obnova hesla',
        component: PasswordReset,
        protected: false,
        authPage: true,
        topbarType: 'login',
    },
    {
        path: routesURLs.components,
        title: 'Komponenty',
        component: Components,
        protected: false,
        topbarType: 'personal',
    },
    /*
    {
        path: routesURLs.freeCourse,
        title: 'Lekce zdarma',
        component: FreeCourse,
        protected: true,
        topbarType: 'personal',
    },
    */
    {
        path: routesURLs.personalCourse,
        title: 'Kurz na místě',
        component: PersonalCourse,
        protected: true,
        topbarType: 'personal',
    },
    {
        path: routesURLs.onlineCourse,
        title: 'On-line kurz',
        component: OnlineCourse,
        protected: true,
    },
    {
        path: routesURLs.dashboard,
        title: 'Dashboard',
        component: Dashboard,
        protected: true,
        topbarType: 'personal',
    },
    {
        path: routesURLs.homepage,
        title: 'Homepage',
        component: Homepage,
        protected: false,
        authPage: true,
        exact: true,
        topbarType: 'login',
    },
];

const Routes: React.FC = () => {
    return (
        <Switch>
            {routesList.map((route, key) => (
                <Route
                    key={key}
                    path={route.path}
                    exact={route.exact || false}
                    render={(props) => {
                        if (route.authPage && getCookie('jid')) {
                            return <Redirect to="/dashboard" />;
                        } else if (route.protected && !getCookie('jid')) {
                            return <Redirect to="/" />;
                        } else {
                            return (
                                <Page
                                    {...props}
                                    component={route.component}
                                    title={route.title}
                                    topbarType={route.topbarType}
                                />
                            );
                        }
                    }}
                />
            ))}
            <Redirect to="/" />
        </Switch>
    );
};

export const Page: React.FC<PageType> = (props) => {
    const { title, component, topbarType } = props;
    const PageComponent = component;

    if (!window.router) {
        window.router = props.history;
    }

    useEffect(() => {
        document.title = title ? `${title} · DOHLAVY` : 'DOHLAVY';
    }, [title]);

    return (
        <>
            {!!topbarType && <Topbar type={topbarType} />}
            <div className="page-content">
                <PageComponent props={props as PageType} />
            </div>
        </>
    );
};

export default Routes;
