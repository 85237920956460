import React from 'react';
import './OnlineCourses.scss';
import MainLayout from '../../components/TheMainLayout/MainLayout';
import { Switch, Route } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import OnlineCoursesSubjects from './OnlineCoursesSubjects';
import OnlineCoursesClasses from './OnlineCoursesClasses';
import OnlineCoursesDetail from './OnlineCoursesDetail';

const OnlineCourses = () => {
    return (
        <MainLayout>
            <Switch>
                <Route
                    path={`${routesURLs.onlineCourses}/:id`}
                    exact={true}
                    render={(props) => <OnlineCoursesClasses {...props} />}
                />
                <Route
                    path={`${routesURLs.onlineCourses}/:subjectId/:lessonId`}
                    exact={true}
                    render={(props) => <OnlineCoursesDetail {...props} />}
                />
                <Route
                    path={routesURLs.onlineCourses}
                    exact={true}
                    render={(props) => <OnlineCoursesSubjects {...props} />}
                />
            </Switch>
        </MainLayout>
    );
};

export default OnlineCourses;
