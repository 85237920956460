import React, { useEffect, useState } from 'react';
import '../AppButtonSelect/ButtonSelect.scss';
import { OnlineCourseTestQuestion } from '../../services/types';
import Button from '../AppButton/Button';
import '../AppButtonSelect/ButtonSelect.scss';
import TrueFalseSwitcher from './TrueFalseSwitcher/TrueFalseSwitcher';
import { classes } from '../../services/utils';
import TestExplanation from './TestExplanation/TestExplanation';

interface QuestionStatementType {
    question: OnlineCourseTestQuestion;
    onDone: ({ id, value, answer }: { id: number; value: boolean; answer: boolean[] }) => void;
}

const TestQuestionStatement: React.FC<QuestionStatementType> = ({ question, onDone }) => {
    const { text, id, statements, explanation, image_url } = question;
    const [value, setValue] = useState<any[]>(statements!.map((_) => null));
    const [init, setInit] = useState(false);
    const [done, setDone] = useState(false);

    function changeValue(val: boolean | null, index: number) {
        if (val !== null) {
            const newValue = [...value];
            newValue[index] = val;
            setValue(newValue);
        }
    }

    function isValid() {
        return value.filter((val) => typeof val === 'boolean').length === statements?.length;
    }

    function isCorrect(index: number) {
        return statements!.map((statement) => statement.is_correct)[index] === value[index] && done;
    }
    function isFalsy(index: number) {
        return statements!.map((statement) => statement.is_correct)[index] !== value[index] && done;
    }

    function handleClick() {
        if (isValid() && !done) {
            setDone(true);
            onDone({
                id,
                value:
                    statements!.filter((statement, index) => statement.is_correct === value[index]).length ===
                    statements?.length,
                answer: value,
            });
        } else {
            setInit(true);
        }
    }

    useEffect(() => {
        setDone(false);
        setValue(statements!.map((_) => null));
        // eslint-disable-next-line
    }, [id]);

    return (
        <div>
            {!!image_url && (
                <div className="h-text-center h-mb-2">
                    <img src={image_url} alt={text} />
                </div>
            )}
            <h2 className="h-text-center h-mb-1" dangerouslySetInnerHTML={{ __html: text }}></h2>
            {!!explanation && done && (
                <p className="h-text-center" dangerouslySetInnerHTML={{ __html: explanation }}></p>
            )}
            <span className="form-label">Zaškrtněte</span>
            {statements?.map((statement, index) => (
                <div
                    key={statement.text + index}
                    className={classes('button-select is-wrapper h-relative', {
                        'is-correct': isCorrect(index),
                        'is-falsy': isFalsy(index),
                    })}
                >
                    <span className="button-select-content" dangerouslySetInnerHTML={{ __html: statement.text }}></span>
                    <TrueFalseSwitcher
                        isDisabled={done}
                        result={isCorrect(index)}
                        onChange={(value) => changeValue(value, index)}
                    />
                    {done && !!statement.explanation && (
                        <TestExplanation>
                            <div dangerouslySetInnerHTML={{ __html: statement.explanation }}></div>
                        </TestExplanation>
                    )}
                </div>
            ))}
            <div className="form-input-footnote">
                {!isValid() && init && <div className="form-error-messages">Odpovězte prosím</div>}
            </div>
            <div className="h-text-center h-mt-2">
                <Button color="primary" onClick={handleClick} disabled={done}>
                    Potvrdit odpověď
                </Button>
            </div>
        </div>
    );
};

export default TestQuestionStatement;
