import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '../AppButton/Button';
import FormInputText from './Inputs/FormInputText';
import { PersonalCourseFormType } from './form-types';
import FormInputRadio from './Inputs/FormInputRadio';
import CheckboxItem from '../AppCheckboxItem/CheckboxItem';
import ImageBox from '../ImageBox/ImageBox';
import LabelValue from '../AppLabelValue/LabelValue';
import { useHistory } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import { PersonalCoursePackageType, PersonalCourseType, SelectedPackageCourseType } from '../../services/types';
import { courseTermDescription, formateNumber, getCookie } from '../../services/utils';

import personCoursesImage from '../../assets/images/icons/ic-personal-lessons.svg';
import { CoursesTypes, useCoursesState } from '../../contexts/courses';
import FormInputSelect from './Inputs/FormInputSelect';
import Modal from '../TheModal/Modal';
import ModalChoosePackageCourse from '../TheModal/ModalChoosePackageCourse';

let initialValues = {
    selectedLocation: 0,
    course: '',
    id: 0,
    student_name: '',
};

const attributes = {
    selectedLocation: {
        placeholder: 'Vyberte..',
        label: 'Vyberte místo konání',
        icon: 'pin-primary',
        options: [{ value: 0, label: '' }],
    },
    course: {
        label: 'Vyberte kurz',
    },
    id: {
        label: null,
        note: null,
    },
    student_name: {
        placeholder: 'Vyplňte..',
        label: 'Jméno Žáka',
        note: 'Vyplňuje se kvůli docházce',
    },
};

const PersonalCourseForm: React.FC<{ courses: PersonalCourseType[]; packages: PersonalCoursePackageType[] }> = ({
    courses,
    packages,
}) => {
    const { state, dispatch } = useCoursesState();
    const history = useHistory();

    const [selectedPackageCourse, setSelectedPackageCourse] = useState<SelectedPackageCourseType>();

    let selectedCourse: PersonalCourseType;

    const locationsIds: number[] = [];
    const locations = useMemo(
        () =>
            courses
                .map((course) => course.terms[0].location)
                .filter((location) => {
                    if (locationsIds.indexOf(location.id) > -1) {
                        return false;
                    }
                    locationsIds.push(location.id);
                    return true;
                }),
        // eslint-disable-next-line
        [courses]
    );

    attributes.selectedLocation.options = locations.map((location) => ({
        value: location.id,
        label: `${location.city.name} - ${location.street_name} ${location.street_number}${
            location.name ? ' · ' + location.name : ''
        }`,
    }));

    useEffect(() => {
        return () => {
            initialValues.selectedLocation = 0;
        };
        // eslint-disable-next-line
    }, []);

    if (locations.length === 1) {
        initialValues.selectedLocation = locations[0].id;
    }

    const saveSelectedCourse = (values: PersonalCourseFormType) => {
        const selectedTerm = selectedCourse.terms.filter((term) => term.id === values.id)[0];
        dispatch({
            type: CoursesTypes.SaveCourse,
            course: { ...values, term: selectedTerm },
            courseType: 'personal',
        });
    };

    const onSubmit = async (values: PersonalCourseFormType) => {
        saveSelectedCourse(values);
        if (getCookie('jid')) {
            history.push(routesURLs.selectMore);
        } else {
            history.push(routesURLs.registration);
        }
    };

    const validationSchema = Yup.object().shape({
        selectedLocation: Yup.string().required('Povinné pole'),
        course: Yup.string().required('Povinné pole'),
        id: Yup.number().moreThan(0, 'Povinné pole'),
        student_name: Yup.string().required('Zadejte jméno žáka'),
    });

    if (!courses.length) {
        return null;
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ isValid, isSubmitting, values, setFieldValue, errors, submitCount, resetForm }) => {
                const coursesOnLocation = courses.filter(
                    (course) => course.terms[0].location.id === values.selectedLocation
                );
                selectedCourse = coursesOnLocation.filter((course) => course.name === values.course)[0];
                const selectedCourseTerms = selectedCourse ? selectedCourse.terms : [];

                const resetIdAfterCourseSelection = (courseName: string) => {
                    if (courseName !== values.course) setFieldValue('id', 0);
                    setFieldValue('course', courseName);
                };

                const selectOtherCourse = (values: PersonalCourseFormType) => {
                    saveSelectedCourse(values);
                    history.push(routesURLs.selectMore);
                };

                const selectPackageCourse = (course: SelectedPackageCourseType) => {
                    setFieldValue('id', 0);
                    setFieldValue('course', '');
                    setSelectedPackageCourse(course);
                };

                const coursesInPackage = packages
                    .filter(
                        (packageItem) =>
                            coursesOnLocation.filter((course) => course.terms[0].type.id === packageItem.type.id)
                                .length === packageItem.quantity
                    )
                    .map((packageItem) => ({
                        ...packageItem,
                        courses: coursesOnLocation.filter((course) => course.terms[0].type.id === packageItem.type.id),
                    }))
                    .filter((packageItem) =>
                        packageItem.courses.some(
                            (course) =>
                                !state.savedPersonalCourses.filter((savedCourse) => savedCourse.course === course.name)
                                    .length
                        )
                    );

                return (
                    <>
                        <Form>
                            <FormInputSelect
                                name="selectedLocation"
                                attributes={attributes.selectedLocation}
                                onValueChange={() => resetForm()}
                            />
                            {!!values.selectedLocation && (
                                <>
                                    <label>{attributes.course.label}</label>
                                    <div className="container-row">
                                        {coursesOnLocation.map((course) => (
                                            <div key={course.name} className="col-md-4">
                                                <ImageBox
                                                    image={personCoursesImage}
                                                    color="secondary"
                                                    title={course.name}
                                                    subtitle={`Cena: ${formateNumber(course.terms[0].price, 0)} Kč`}
                                                    small={true}
                                                    isChecked={course.name === values.course}
                                                    onClick={() => resetIdAfterCourseSelection(course.name)}
                                                />
                                            </div>
                                        ))}
                                        {coursesInPackage.map((course) => (
                                            <div key={course.id} className="col-md-4">
                                                <ImageBox
                                                    image={personCoursesImage}
                                                    color="secondary"
                                                    title={`${course.courses.reduce(
                                                        (sum, item) => sum + (sum ? ' + ' : '') + item.name,
                                                        ''
                                                    )}`}
                                                    subtitle={`Cena: ${formateNumber(course.price, 0)} Kč`}
                                                    small={true}
                                                    isChecked={course.id === selectedPackageCourse?.id}
                                                    onClick={() => selectPackageCourse(course)}
                                                />
                                            </div>
                                        ))}
                                        {errors.course && !!submitCount && (
                                            <div className="form-input-footnote image-box-error">
                                                <div className="form-error-messages">{errors.course}</div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {!!selectedCourse && !!values.selectedLocation && (
                                <div className="h-mb-175">
                                    <div className="form-label h-display-block">Vyberte Datum a čas</div>
                                    {selectedCourseTerms.map((term) => (
                                        <FormInputRadio
                                            key={term.id}
                                            name="id"
                                            attributes={{
                                                options: [
                                                    {
                                                        label: term.date_from,
                                                        value: term.id,
                                                    },
                                                ],
                                                disabled: !!state.savedPersonalCourses.filter(
                                                    (course) => course.id === term.id
                                                ).length,
                                                ...attributes.id,
                                            }}
                                        >
                                            <CheckboxItem
                                                key={term.id}
                                                text={courseTermDescription({
                                                    dateFrom: term.date_from,
                                                    dateTo: term.date_to,
                                                    price: term.price,
                                                    lessonDate: term.lessons[0].date,
                                                })}
                                                note={`(Kapacita: ${term.capacity})`}
                                            />
                                            <></>
                                        </FormInputRadio>
                                    ))}
                                </div>
                            )}
                            <FormInputText name="student_name" attributes={attributes.student_name} />
                            <hr className="h-mt-2" />
                            <div className="personal-courses-form-summary">
                                <LabelValue
                                    label="Celkem zaplatíte"
                                    value={
                                        selectedPackageCourse
                                            ? formateNumber(selectedPackageCourse.price, 0)
                                            : selectedCourse
                                            ? `${formateNumber(
                                                  selectedCourse.terms.filter((term) => term.id === values.id)[0]
                                                      ?.price || 0,
                                                  0
                                              )} Kč`
                                            : '0 Kč'
                                    }
                                    className="personal-courses-form-summary-price"
                                />
                                <Button
                                    onClick={() => selectOtherCourse(values)}
                                    color="transparent"
                                    disabled={!isValid}
                                    className="h-mr-05"
                                >
                                    Vybrat další kurz
                                </Button>
                                <Button type="submit" color="primary" disabled={!isValid} loading={isSubmitting}>
                                    Pokračovat
                                </Button>
                            </div>
                        </Form>
                        <Modal isOpen={!!selectedPackageCourse} close={() => setSelectedPackageCourse(undefined)}>
                            {!!selectedPackageCourse && <ModalChoosePackageCourse data={selectedPackageCourse!} />}
                        </Modal>
                    </>
                );
            }}
        </Formik>
    );
};

export default PersonalCourseForm;
