import React from 'react';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { deleteProfile } from '../../moduls/profile';
import Button from '../AppButton/Button';

const ModalDeleteAccount: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
    const { state, dispatch } = useGlobalState();
    async function deleteAccount() {
        dispatch({ type: GlobalTypes.ToggleLoader, loader: 'deleteAccount' })
        await deleteProfile();
        dispatch({ type: GlobalTypes.ToggleLoader });
        closeModal();
    }

    return (
        <div className="modal-content h-text-center h-py-4">
            <h1 className="h-mb-05">Smazání účtu</h1>
            <p>Smazáním účtu příjdete o všechny data a zakoupené kurzy.</p>
            <Button color="alert" onClick={deleteAccount} className="h-mt-1" loading={state.loader === 'deleteAccount'}>
                Chci Smazat účet
            </Button>
        </div>
    );
};

export default ModalDeleteAccount;
