import React from 'react';
import { CoursePriceType } from '../../services/types';
import LabelValue from '../AppLabelValue/LabelValue';
import { formateNumber, plural } from '../../services/utils';
import TableContainer from '../AppTableContainer/TableContainer';

const PriceList: React.FC<{ singlePrice: number; prices: CoursePriceType[]; totalPrice: number }> = ({
    singlePrice = 0,
    prices,
    totalPrice = 0,
}) => {
    return (
        <>
            <label>Ceník</label>
            <TableContainer>
                <div className="container-row">
                    <div className="col-sm-6">
                        <LabelValue className="h-mb-15" label="1 lekce" value={`${formateNumber(singlePrice, 0)} Kč`} />
                    </div>
                    {prices.map((price) => (
                        <div key={price.id} className="col-sm-6">
                            <LabelValue
                                className="h-mb-15"
                                label={`${price.quantity} ${plural(
                                    price.quantity,
                                    'lekce',
                                    'lekce',
                                    'lekcí'
                                )} (dle vlastního výběru)`}
                                value={`${formateNumber(price.price, 0)} Kč`}
                            />
                        </div>
                    ))}
                    <div className="col-sm-6">
                        <LabelValue
                            className="h-mb-15"
                            label="Všechny lekce"
                            value={`${formateNumber(totalPrice, 0)} Kč`}
                        />
                    </div>
                </div>
            </TableContainer>
        </>
    );
};

export default PriceList;
