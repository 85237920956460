import React from 'react';
import { format } from 'date-fns';
import { cs } from 'date-fns/locale';
import { plural } from '../../services/utils';

const CourseCardOnline: React.FC<{
    name: string;
    lessonsLength: number;
    classLesson: string;
    expiration: string;
}> = (props) => {
    const { classLesson, expiration, lessonsLength, name } = props;

    return (
        <>
            <h2 className="h-mb-05">{name}</h2>
            <ul className="is-small h-mb-0">
                <li>{`${lessonsLength} ${plural(lessonsLength, 'lekce', 'lekce', 'lekcí')}`}</li>
                <li>{classLesson}</li>
                {expiration && new Date(expiration) > new Date() && (
                    <li>{`Platnost do ${format(new Date(expiration), 'd. MMMM yyyy', { locale: cs })}`}</li>
                )}
            </ul>
        </>
    );
};

export default CourseCardOnline;
