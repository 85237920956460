import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-query';
import ButtonSelect from '../../components/AppButtonSelect/ButtonSelect';
import BackButton from '../../components/AppBackButton/BackButton';
import { routesURLs } from '../../Routes';
import { getCities } from '../../moduls/personal-courses';
import { CityType } from '../../services/types';
import Icon from '../../components/AppIcon/Icon';
import SkeletonButtonSelect from '../../components/Skeletons/SkeletonButtonSelect';

const PersonalCoursesCity: React.FC<RouteComponentProps> = (props) => {
    const { data: cities = [], isFetched } = useQuery<CityType[]>('cities', getCities);

    return (
        <div className="page-row">
            <BackButton route={routesURLs.homepage} />
            <h1 className="h-text-center h-mb-15">V jakém městě?</h1>
            {!isFetched && <Loader />}
            {cities.map((city) => (
                <ButtonSelect
                    key={city.id}
                    onClick={() => props.history.push(`${routesURLs.personalCourses}/${city.id}`)}
                    rightPart={<Icon icon="chevron-right-gray" width=".875rem" height=".875rem" />}
                >
                    {city.name}
                </ButtonSelect>
            ))}
        </div>
    );
};

const Loader = () => (
    <>
        <SkeletonButtonSelect />
        <SkeletonButtonSelect />
        <SkeletonButtonSelect />
    </>
);

export default PersonalCoursesCity;
