import React, {useState} from 'react'
import { useHistory } from 'react-router-dom';
import {CoursesTypes, useCoursesState} from "../../contexts/courses";
import {getCookie, sessionStore} from "../../services/utils";
import DiscountCode from "../DiscountCode/DiscountCode";
import FinalPrice from "../CheckoutComponents/FinalPrice";
import ButtonDropdown from "../AppButton/ButtonDropdown";
import Button from "../AppButton/Button";
import {GlobalTypes, useGlobalState} from "../../contexts/global";
import {CourseType, PayByType} from "../../services/types";
import {orderCourses} from "../../moduls/courses";
import {routesURLs} from "../../Routes";
import BasketItem from "../Basket/BasketItem";
import "./ModalBasket.scss";

const ModalBasket: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
    const [price, setPrice] = useState<number | null>(null);
    const { state, dispatch } = useGlobalState();
    const { state: coursesState, dispatch: dispatchCourses} = useCoursesState();
    const history = useHistory();

    const coursesInBasket = coursesState.savedOnlineCourses.length + coursesState.savedPersonalCourses.length

    function removeCourse(id: number, courseType: CourseType) {
        dispatchCourses({ type: CoursesTypes.RemoveSavedCourse, id, courseType });
    }

    function removeAllItem() {
        coursesState.savedOnlineCourses.map((course) => (
            removeCourse(course.id, 'online')
        ));

        coursesState.savedPersonalCourses.map((course) => (
            removeCourse(course.id, 'personal')
        ));
    }

    const manageOrder = (pay_by: PayByType) => {
        if (getCookie('jid')) {
            dispatch({ type: GlobalTypes.ToggleLoader, loader: 'manageOrder' });
            orderCourses(
                {
                    onsite_courses: coursesState.savedPersonalCourses.map((course) => ({
                        id: course.id,
                        student_name: course.student_name,
                    })),
                    online_courses: coursesState.savedOnlineCourses.map((course, index) => ({
                        id: course.id,
                        price_package_id: course.price_package_id,
                        lesson_ids: course.lesson_ids,
                        discount_code:
                            index === 0 && state.discount.one_time && state.discount.code
                                ? state.discount.code
                                : undefined,
                    })),
                    discount_code:
                        state.discount.one_time === 0 && state.discount.code ? state.discount.code : undefined,
                },
                pay_by
            )
                .then((res) => {
                    const transactionProducts: object[] = [];
                    coursesState.savedPersonalCourses.forEach(course => {
                        transactionProducts.push({
                            'id': 'onsite_' + course.id,
                            'name': course.course,
                            'price': course.term.price.toString(),
                        });
                    });
                    coursesState.savedOnlineCourses.forEach(course => {
                        transactionProducts.push({
                            'id': 'online' + course.id,
                            'name': course.course.name,
                            'price': course.course.price.toString(),
                        });
                    })

                    // @ts-ignore
                    dataLayer.push({
                        'event': 'purchase',
                        'ecommerce': {
                            'purchase': {
                                'actionField': {
                                    'id': Date.now().toString() + '_' + (Math.random() + 1).toString(36).substring(7),
                                    'revenue': price,
                                    'coupon': state.discount.one_time === 0 && state.discount.code ? state.discount.code : ''
                                },
                                'products': transactionProducts
                            }
                        }
                    });

                    const redirectUrl = res?.data?.data?.redirect_url;
                    const payments = res?.data?.data?.payments;
                    if (redirectUrl && pay_by === 'PAYMENT_GATEWAY') {
                        sessionStore.set('onsite_courses', []);
                        sessionStore.set('online_courses', []);
                        window.location.replace(redirectUrl);
                    } else if (payments && pay_by === 'BANK_TRANSFER') {
                        dispatch({ type: GlobalTypes.ToggleLoader });
                        dispatchCourses({ type: CoursesTypes.ResetSavedCourses });

                        dispatch({ type: GlobalTypes.ToggleModal });
                        dispatch({ type: GlobalTypes.ToggleModal, modal: 'ModalPaymentInfo', props: { payments: payments }});
                    } else {
                        window.location.replace(routesURLs.dashboard);
                        dispatch({ type: GlobalTypes.ToggleLoader });
                        dispatchCourses({ type: CoursesTypes.ResetSavedCourses });
                    }
                })
                .catch((error) => {
                    dispatch({ type: GlobalTypes.ToggleLoader });
                    dispatch({
                        type: GlobalTypes.ToggleNotification,
                        style: 'error',
                        text: error?.response?.data?.message,
                    });
                });
        } else {
            history.push(routesURLs.registration);
        }
    };

    const buyButton = () => {
        if (!getCookie('jid')) {
            return (
                <>
                    <Button
                        color="primary"
                        disabled={true}
                    >
                        Dokončete registraci
                    </Button>
                </>
            );
        }

        return (
            <>
                {coursesInBasket > 0 && price !== 0 ? (
                    <ButtonDropdown
                        loading={!!state.loader}
                        options={coursesState.savedOnlineCourses.length > 0 ? [
                            { text: 'Zaplatit kartou on-line', action: () => manageOrder('PAYMENT_GATEWAY') },
                            { text: 'Zaplatit převodem', action: () => manageOrder('BANK_TRANSFER') },
                        ] : [
                            { text: 'Zaplatit převodem', action: () => manageOrder('BANK_TRANSFER') },
                        ]}
                    >
                        Přejít k platbě
                    </ButtonDropdown>
                ) : (
                    <Button
                        color="primary"
                        onClick={() => manageOrder('BANK_TRANSFER')}
                        loading={!!state.loader}
                    >
                        {price ? 'Přejít k platbě' : 'Získat kurz'}
                    </Button>
                )}
            </>
        );
    };

    if (coursesInBasket === 0) {
        return (
            <div className="modal-content h-background-body">
                <h1 className="h-text-center h-mb-175">Nákupní košík</h1>
                <div className="container-row">
                  Košík je prázdný.
                </div>
            </div>

        );
    } else {
        return (
            <div className="modal-content h-background-body">
                <h1 className="h-text-center h-mb-175">Nákupní košík</h1>
                <div className="container-row">
                    {coursesState.savedOnlineCourses.map((course) => (
                        <BasketItem
                            key={course.id}
                            course={course}
                            title={course.course.name}
                            price={course.course.price}
                            lessonCount={course.lesson_ids.length}
                            courseType="online"
                            removeCourse={removeCourse}
                        />
                    ))}

                    {coursesState.savedPersonalCourses.map((course) => (
                        <BasketItem
                            key={course.id}
                            course={course}
                            title={course.course}
                            price={course.term.price}
                            lessonCount={course.term.lessons_count}
                            courseType="personal"
                            removeCourse={removeCourse}
                        />
                    ))}
                </div>

                <p>Můžete používat až na 3 zařízeních.</p>

                <Button
                    color="outline"
                    onClick={() => removeAllItem()}
                    icon="bin-gray"
                    className="remove-all-item"
                >
                    Vysypat košík
                </Button>

                <hr />
                {!!coursesState.savedOnlineCourses.length && (
                    <>
                        <div className="h-py-05">
                            <DiscountCode />
                        </div>
                        <hr />
                    </>
                )}

                <div className="personal-courses-form-summary">
                    <div className="personal-courses-form-summary-price-wrapper">
                        <FinalPrice setPrice={setPrice} />
                    </div>

                    {buyButton()}
                </div>
            </div>
        );
    }
}

export default ModalBasket;
