import React, { } from 'react';
import './CompletedTest.scss';
import MainLayout from "../TheMainLayout/MainLayout";
import { getCompletedRandomGif } from "../../moduls/online-courses";
import {useQuery} from "react-query";
import {CompletedRandomGif} from "../../services/types";
import {sessionStore} from "../../services/utils";

interface CompletedTestType {
    score: any;
    params: any;
}

const CompletedTest: React.FC<CompletedTestType> = ({ score, params }) => {
    const answersData = sessionStore.get('tests')[params.testId].answers || [];
    const testQuestionsNumber = answersData.length;
    let scoreValue = (score * 100) / testQuestionsNumber;

    const { data: image } = useQuery<CompletedRandomGif>(['random-image', { score: +scoreValue }], getCompletedRandomGif, { cacheTime: 0 });

    let congratulationText = 'TAK TOHLE ÚPLNĚ NEVYŠLO, ZKUS TO ZNOVU!';
    if (scoreValue >= 85) {
        congratulationText = 'VÝBORNĚ, JEN TAK DÁL!';
    } else if (scoreValue >= 50 && scoreValue < 85) {
        congratulationText = 'SLUŠNÝ, ALE POŘÁD JE CO ZLEPŠOVAT.';
    }

    return (
        <div>
            <MainLayout>
                <div>
                    <h1 className="h-text-center h-mb-1">{congratulationText}</h1>
                    <div className="h-text-center h-mb-2">{score} / {testQuestionsNumber} správných odpovědí</div>
                    {!!image && (
                        <div className='h-text-center h-mb-2'>
                            <img src={image?.base64} alt={congratulationText} />
                        </div>
                    )}
                </div>
            </MainLayout>
        </div>
    );
};

export default CompletedTest;
