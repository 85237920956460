import api from '../services/api';
import { LoginFormType, RegistrationFormType, ResetPasswordFormType } from '../components/Forms/form-types';
import { queryCache } from 'react-query';
import { getProfile } from './profile';
import { deleteCookie, getCookie, setCookie } from '../services/utils';

function handleAuthResponse(token: string) {
    api.defaults.headers['Authorization'] = `Bearer ${token}`;
    setCookie('jid', token, 400);
}

export const login = (data: LoginFormType) => {
    return api.post('/login', {...data, device_token: getCookie('device-token')}).then(async (res) => {
        const token = res.data.data.token;
        handleAuthResponse(token);
        queryCache.setQueryData('profile', await getProfile(), {
            cacheTime: Infinity,
        });
    });
};

export const logout = () => {
    return api.post('/logout').then(() => {
        deleteCookie('jid');
        queryCache.clear();
        window.location.replace('/');
        // window.router.replace('/');
    });
};

export const registration = (data: RegistrationFormType) => {
    return api
        .post('/register', {...data, device_token: getCookie('device-token')})
        .then((res) => {
            // @ts-ignore
            dataLayer.push({
                'event': 'registration'
                });
            return { ...res.data.data, username: data.username, password: data.password };
        });
};

export const forgottenPassword = (data: { username: string }) => {
    return api.post('/password/email', data);
};

export const passwordReset = (data: ResetPasswordFormType) => {
    return api.post('/password/reset', data).then(async () => {
        await login({ username: data.username, password: data.password });
    });
};

export const switchUser = async (token: string) => {
    handleAuthResponse(token);
    queryCache.setQueryData("profile", await getProfile(), {
        cacheTime: Infinity,
    });
};
