import React from 'react'
import ResetPasswordForm from '../../components/Forms/ResetPasswordForm';
import MainLayout from '../../components/TheMainLayout/MainLayout';

const PasswordReset = () => {
    return (
        <MainLayout>
            <div className="page-row">
                <h1 className="h-text-center">Obnova hesla</h1>
                <p className="h-text-center">Zadejte svoje nové heslo a poté jej raději zopakujte.</p>
                <ResetPasswordForm />
            </div>
        </MainLayout>
    );
};

export default PasswordReset
