import React, {useEffect, useRef} from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getOnlineCourseLesson, markVideoAsSeen, setVideoPartSeen } from '../../moduls/online-courses';
import BackButton from '../../components/AppBackButton/BackButton';
import MainLayout from '../../components/TheMainLayout/MainLayout';
import Topbar from '../../components/TheTopbar/Topbar';
import { routesURLs } from '../../Routes';
import { OnlineCourseLesson } from '../../services/types';
import Video from '../../components/AppVideo/Video';
import ButtonSelect from '../../components/AppButtonSelect/ButtonSelect';
import Icon from '../../components/AppIcon/Icon';
import Tag from '../../components/AppTag/Tag';
import Loader from '../../components/AppLoader/Loader';
import './OnlineCourse.scss';

const TestSelectContent: React.FC<{ score: number | null; questions_count: number }> = ({
    score = null,
    questions_count,
}) => {
    if (score !== null) {
        let tagColor: 'primary' | 'tertiary' | 'alert';
        if (score / questions_count > 0.7) {
            tagColor = 'primary';
        } else if (score / questions_count > 0.3) {
            tagColor = 'tertiary';
        } else {
            tagColor = 'alert';
        }
        return (
            <>
                <Tag color="primary" className="h-mr-05">
                    Dokončeno
                </Tag>
                <Tag color={tagColor} className="h-mr-05">{`Skóre ${score}/${questions_count}`}</Tag>
                <Icon icon="retry-primary" width="1rem" height="1rem" />
            </>
        );
    } else {
        return <Icon icon="chevron-right-primary" width=".875rem" height=".875rem" />;
    }
};

const OnlineCourseLessonDetail: React.FC<RouteComponentProps> = (props) => {
    const { courseId, lessonId } = useParams<{ courseId: string; lessonId: string }>();
    const { data: courseLesson, isFetched } = useQuery<OnlineCourseLesson>(
        ['online-course-lesson', { id: lessonId }],
        getOnlineCourseLesson
    );

    const sendVideoPlayed = (seconds: number, interpretationPartId: number) => {
        if (seconds >= 10 && +seconds.toFixed(0) % 10 === 0) {
            setVideoPartSeen(interpretationPartId, seconds / 60);
        }
    };

    const refH2 = useRef<any>(null);

    useEffect(function (){
        refH2.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    });

    if (!isFetched) return <Loader />;

    const partsIds: number[] = [];

    let scrollIdFromUrl: string = window.location.hash.replace('#', '');

    courseLesson?.interpretation_parts.forEach(part => {
        partsIds.push(part.id);
    });

    let scrollId: number = partsIds[partsIds.indexOf(+scrollIdFromUrl)+1] ?? partsIds[partsIds.indexOf(+scrollIdFromUrl)];

    return (
        <>
            <Topbar type="personal" title={courseLesson?.name} />
            <MainLayout>
                {courseLesson?.interpretation_parts.map((part) => (
                    <div key={part.id} className="online-course-interpretation">
                        <div className="h-text-center">
                            <div ref={part.id === +scrollId ? refH2 : null} style={{display:"hide", height: "5rem"}}></div>
                            <h2 >{part.name}</h2>
                            <Video
                                url={part.video_url}
                                finished={part.finished_by_user}
                                onFinish={() => markVideoAsSeen(part.id)}
                                onProgress={({ playedSeconds }) => sendVideoPlayed(playedSeconds, part.id)}
                                className="h-mb-2"
                            />
                            <h2 className="h-mb-05">Cvičení</h2>
                            {part.lesson_tests.map((lesson) => (
                                <ButtonSelect
                                    key={lesson.id}
                                    onClick={() =>
                                        props.history.push(
                                            `${routesURLs.onlineCourse}/${courseId}/${lessonId}/test/${lesson.id}?part=${part.id}`
                                        )
                                    }
                                    rightPart={
                                        <TestSelectContent
                                            score={lesson.score?.best ?? null}
                                            questions_count={lesson.questions_count}
                                        />
                                    }
                                >
                                    {lesson.name}
                                </ButtonSelect>
                            ))}
                        </div>
                        <hr className="h-my-2" />
                    </div>
                ))}
                {(!!courseLesson?.individual_part?.video_url || !!courseLesson?.lesson_files.length || !!courseLesson?.individual_part?.file_url) && (
                    <div className="h-text-center">
                        <h2 className="h-mb-05">Závěr lekce</h2>
                    </div>
                )}
                {!!courseLesson?.individual_part?.file_url && (
                    <div className="h-mb-2">
                        <h4 className="h-mb-05">Podklady ke stažení</h4>
                        <a
                            href={courseLesson.individual_part.file_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                        >
                            <ButtonSelect
                                key={courseLesson.individual_part.id}
                                onClick={() => {}}
                                rightPart={<Icon icon="download-primary" width="1.25rem" height="1.25rem" />}
                            >
                                {courseLesson.individual_part.name}
                            </ButtonSelect>
                        </a>
                    </div>
                )}
                {!!courseLesson?.individual_part?.video_url && (
                    <div className="h-text-center">
                        <Video url={courseLesson?.individual_part.video_url} />
                    </div>
                )}
                {!!courseLesson?.lesson_files.length && (
                    <div className="h-mt-2">
                        <h4 className="h-mb-05">Doplňující materiály</h4>
                        {courseLesson.lesson_files.map((file) => (
                            <a
                                href={file.file_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                                className="h-display-block h-mb-075"
                            >
                                <ButtonSelect
                                    key={file.id}
                                    onClick={() => {}}
                                    rightPart={<Icon icon="download-primary" width="1.25rem" height="1.25rem" />}
                                >
                                    {file.name}
                                </ButtonSelect>
                            </a>
                        ))}
                    </div>
                )}
            </MainLayout>
            <BackButton route={`${routesURLs.onlineCourse}/${courseId}`} />
        </>
    );
};

export default OnlineCourseLessonDetail;
