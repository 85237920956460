import React from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import BackButton from '../../components/AppBackButton/BackButton';
import ButtonSelect from '../../components/AppButtonSelect/ButtonSelect';
import Icon from '../../components/AppIcon/Icon';
import Loader from '../../components/AppLoader/Loader';
import MainLayout from '../../components/TheMainLayout/MainLayout';
import Topbar from '../../components/TheTopbar/Topbar';
import { getOnlineCourse } from '../../moduls/online-courses';
import { routesURLs } from '../../Routes';
import { OnlineCourseType } from '../../services/types';

const RightPartPurchase = () => {
    return (
        <>
            <div className="button is-transparent h-p-0 h-mr-05 h-border-none">Dokoupit</div>
            <Icon icon="shopping-cart-primary" width="1.25rem" height="1.25rem" className="h-mb-025" />
        </>
    );
};

const OnlineCourseLesson: React.FC<RouteComponentProps> = (props) => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { data: course, isFetched } = useQuery<OnlineCourseType>(['online-course', { id: +id }], getOnlineCourse);

    function buyAnotherLesson(id: number) {
        history.push(
            `${routesURLs.onlineCourses}/${course!.subject.id}/${course!.lesson_class.id}?preselected-course=${id}`
        );
    }

    if (!isFetched) return <Loader />;

    return (
        <>
            <Topbar type="personal" title={course?.name} />
            <MainLayout>
                <h1 className="h-text-center">{course?.name}</h1>
                {course?.lessons.map((lesson) =>
                    lesson.owned_by_user ? (
                        <ButtonSelect
                            key={lesson.id}
                            onClick={() => history.push(`${routesURLs.onlineCourse}/${id}/${lesson.id}`)}
                            isDisabled={!lesson.is_paid}
                            rightPart={
                                <Icon
                                    icon={lesson.is_paid ? 'chevron-right-primary' : 'lock-gray'}
                                    width=".875rem"
                                    height=".875rem"
                                />
                            }
                        >
                            {lesson.name}
                        </ButtonSelect>
                    ) : null
                )}
                {(!course?.expired_at || (new Date(course?.expired_at)) > new Date()) && !!course?.lessons.filter((lesson) => !lesson.owned_by_user).length ? (
                    <>
                        <hr className="h-my-2" />
                        <div className="h-text-center">
                            <h1 className="h-mb-05">Dokupte si další lekce v kurzu</h1>
                        </div>
                        {course?.lessons.map((lesson) =>
                            !lesson.owned_by_user ? (
                                <ButtonSelect
                                    key={lesson.id}
                                    onClick={() => buyAnotherLesson(lesson.id)}
                                    rightPart={<RightPartPurchase />}
                                >
                                    {lesson.name}
                                </ButtonSelect>
                            ) : null
                        )}
                    </>
                ) : null}
            </MainLayout>
            <BackButton route={routesURLs.dashboard} />
        </>
    );
};

export default OnlineCourseLesson;
