import React, { useState } from 'react';
import './TestExplanation.scss';
import { classes } from '../../../services/utils';

const TestExplanation: React.FC = ({ children }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={classes('test-explanation', { 'is-open': open })}>
            <button type="button" onClick={() => setOpen(!open)} className="test-explanation-label">
                Proč?
            </button>
            <div className="test-explanation-text">{children}</div>
        </div>
    );
};

export default TestExplanation;
