import React from 'react';
import { useField } from 'formik';
import FormInputWrapper from './FormInputWrapper';
import { classes } from '../../../services/utils';

interface InputAttributes {
    options: { value: string | number; label: string }[];
    label?: string | null;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    note?: string | null;
}

const FormInputRadio: React.FC<{ attributes: InputAttributes; name: string, children?: JSX.Element[] }> = (props) => {
    const [field, meta, { setValue }] = useField(props);
    const { children } = props;
    const { label, className, disabled, note, options } = props.attributes;

    return (
        <FormInputWrapper className={className} meta={meta} note={note}>
            {label !== null && <div className="form-label h-display-block">{label || <br />}</div>}
            {options.map((option, index: number) => (
                <label
                    key={option.value}
                    htmlFor={`radio-input-${field.name}-${option.value}`}
                    className="form-radio-wrapper is-title"
                >
                    <input
                        {...field}
                        onChange={() => setValue(option.value)}
                        type="radio"
                        id={`radio-input-${field.name}-${option.value}`}
                        value={option.value}
                        disabled={disabled}
                    />
                    {children ? (
                        React.cloneElement(children.length === options.length ? children[index] : children[0], {
                            isChecked: field.value === option.value,
                            isDisabled: !!disabled,
                            index,
                        })
                    ) : (
                        <>
                            <div
                                className={classes('form-radio', {
                                    'is-checked': field.value === option.value,
                                    'is-disabled': !!disabled,
                                })}
                            ></div>
                            <span className="form-checkbox-title">{option.label}</span>
                        </>
                    )}
                </label>
            ))}
        </FormInputWrapper>
    );
};

export default FormInputRadio;
